import React, { useState, useEffect, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Link } from 'react-router-dom';

const ETHETFDashboard = () => {
  const [ethData, setEthData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('/api/data/eth-etf-flows');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const rawData = await response.json();
        
        // Validate data
        if (!Array.isArray(rawData) || rawData.length === 0) {
          throw new Error('No data available');
        }

        // Validate data structure
        if (!rawData[0].hasOwnProperty('Date')) {
          throw new Error('Invalid data structure');
        }

        const formatData = (rawData) => rawData
          .filter(item => item.Date && Object.keys(item).length > 1)
          .map(item => {
            const total = Object.entries(item)
              .filter(([key, value]) => key !== 'Date' && value !== '' && !isNaN(parseFloat(value)))
              .reduce((sum, [_, value]) => sum + parseFloat(value || 0), 0);
            
            return {
              name: item.Date.split('T')[0],
              value: parseFloat(total.toFixed(2)),
              Date: item.Date.split('T')[0],
              ...item
            };
          });

        const formattedData = formatData(rawData);
        
        if (formattedData.length === 0) {
          throw new Error('No valid data after formatting');
        }

        setEthData(formattedData);
      } catch (error) {
        console.error('Error fetching ETH ETF flows data:', error);
        setError(`Failed to load ETH ETF flows data: ${error.message}`);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const sortedEthData = useMemo(() => {
    return [...ethData].sort((a, b) => new Date(a.Date) - new Date(b.Date)).slice(-30);
  }, [ethData]);

  const DataChart = ({ data }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const yAxisDomain = [-100, 400];
    const yAxisTicks = [-100, -50, 0, 50, 100, 150, 200, 250, 300, 350, 400];

    const formatTickValue = (value) => `$${(value)}M`;

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        const formattedValue = `$${payload[0].value.toFixed(2)}M`;
        return (
          <div className="custom-tooltip" style={{ 
            backgroundColor: 'white', 
            padding: '10px', 
            border: '1px solid #ddd',
            fontFamily: 'Roboto Mono, monospace'
          }}>
            <p className="label" style={{ margin: '0 0 5px 0' }}>{`Date: ${label}`}</p>
            <p className="intro" style={{ 
              color: payload[0].value >= 0 ? '#4CAF50' : '#d32f2f', 
              fontWeight: 'bold', 
              margin: '0'
            }}>
              {`Total: ${formattedValue}`}
            </p>
            {Object.entries(payload[0].payload)
              .filter(([key, value]) => key !== 'Date' && key !== 'name' && key !== 'value' && value !== '')
              .map(([key, value]) => (
                <p key={key} style={{ margin: '0' }}>{`${key}: $${parseFloat(value).toFixed(2)}M`}</p>
              ))}
          </div>
        );
      }
      return null;
    };

    return (
      <div className="chart-container" style={{ position: 'relative' }}>
        <div 
        className="chart-watermark" 
        style={{ 
          position: 'absolute',
          top: '30%',
          left: '65%',
          transform: 'translate(-50%, -50%)',
          fontSize: '40px',
          opacity: 0.1,
          pointerEvents: 'none',
          zIndex: 1,
          fontFamily: 'Libre Baskerville, serif',
          color: '#002475',
          userSelect: 'none'
        }}
        >
          ASXN
        </div>
        <ResponsiveContainer width="100%" height={800}>
          <BarChart 
            data={data} 
            margin={{ top: 40, right: 30, left: 100, bottom: 100 }}
            style={{ fontFamily: 'Roboto Mono, monospace' }}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setHoveredIndex(state.activeTooltipIndex);
              } else {
                setHoveredIndex(null);
              }
            }}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <CartesianGrid 
              strokeDasharray="10 10"
              horizontal={true}
              vertical={false} 
              stroke="#e0e0e0" 
            />
            <XAxis 
              dataKey="name" 
              tick={{ fontSize: 14, fontFamily: 'Roboto Mono, monospace' }}
              interval={0}
              angle={-45}
              textAnchor="end"
              height={80}
            />
            <YAxis 
              tickFormatter={formatTickValue} 
              tick={{ 
                fontSize: 12, 
                fontFamily: 'Roboto Mono, monospace' 
              }}
              domain={yAxisDomain}
              ticks={yAxisTicks}
              label={{ 
                value: 'Net Flow (USD)', 
                angle: -90, 
                position: 'insideLeft',
                style: { 
                  textAnchor: 'middle',
                  fontFamily: 'Roboto Mono, monospace',
                  fontSize: 16,
                  fontWeight: 500,
                  fill: '#666666'
                },
                offset: -65
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value">
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.value >= 0 ? "#4CAF50" : "#d32f2f"}
                  fillOpacity={hoveredIndex === null || hoveredIndex === index ? 1 : 0.3}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!ethData || ethData.length === 0) return <div className="no-data">No data available</div>;

  return (
    <div className="dashboard etf-flows">
      <header className="dashboard-header">
        <Link to="/" className="logo-link">
          <h1 className="logo asxn-logo">ASXN Data</h1>
        </Link>
      </header>
      <h2 className="dashboard-title">ETH ETF Flows</h2>
      <div className="dashboard-content">
        <DataChart data={sortedEthData} />
      </div>
      <div className="data-source">Source: Farside Investors</div>
    </div>
  );
};

export default ETHETFDashboard;
