import React, { useState, useEffect } from 'react';
import MonadLstStakedDashboard from './MonadLiquidStakingDashboard';
import MonadDailyProviderStakedTimeseries from "./monadDailyProviderStakedTimeseries"
import MonadLiquidUnstakingChart from "./monadLiquidUnstakingChart";
import MonadNetflowDashboard from "./monadNetflowDashboard";
import MonadLSTStakeDistribution from "./monadLSTStakeDistribution";
import MonadLSTTable from './monadLSTTable';
import MonadStatsCards from './monadStatsCards';
import MonadProviderDailyTotalStakedCharts from "./monadProviderDailyTotalStakedCharts";
import MonadProviderNetflowChart from "./monadProviderNetflowChart";
import "./../../styles/monad_liquid_staking/monad_lst_dashboard.css";


import api_url from "./../../config/api_url.json";



const base_api_url = api_url.backend_api;

const MonadLiquidStakingDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [chartType, setChartType] = useState('staked'); // Common state for both charts

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${base_api_url}/api/monad-liquid-staking/metrics`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                setData(jsonData);
                setError(null);
            } catch (err) {
                console.error('Error fetching validator data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    if (loading) return <div className="monad_lst_metrics_loader_card">Loading data...</div>;
    if (error) return <div className="monad_lst_metrics_error_card">{error}</div>;

    if (!data) {
        return (
            <div>
                <div className="monad_lst_metrics_loader_card">Loading data...</div>
            </div>
        );
    }

    const latestData = data?.latest_total_staked;

    return (
        <>
            <div className="monad_lst_dashBoard_head">
                <h2 className="monad_lst_dashboard-title">Monad Liquid Staking Analytics</h2>

                <MonadStatsCards latestData={latestData} />

                {/* Common toggle buttons for both charts */}
                <div className="monad_lst_common_toggle_container">
                    <button
                        className={`monad_lst_toggle_button ${chartType === 'staked' ? 'active' : ''}`}
                        onClick={() => setChartType('staked')}
                    >
                        Staked
                    </button>
                    <button
                        className={`monad_lst_toggle_button ${chartType === 'supply' ? 'active' : ''}`}
                        onClick={() => setChartType('supply')}
                    >
                        Supply
                    </button>
                </div>

                <div className='monad_lst_charts_container_1'>
                    <div className='monad_lst_chart_left_1'>
                        <MonadLstStakedDashboard data={data} chartType={chartType} />
                    </div>
                    <div className='monad_lst_chart_right_1'>
                        <MonadDailyProviderStakedTimeseries data={data} chartType={chartType} />
                    </div>
                </div>

                <div className='monad_lst_charts_container_1'>
                    <div className='monad_lst_chart_left_1'>
                        <MonadNetflowDashboard data={data} />
                    </div>
                    <div className='monad_lst_chart_right_1'>
                        <MonadLiquidUnstakingChart data={data} />
                    </div>
                </div>

                <div className='monad_lst_charts_container_2'>
                    <div className='monad_lst_chart_left_2'>
                        <MonadLSTTable data={data} />
                    </div>
                    <div className='monad_lst_chart_right_2'>
                        <MonadLSTStakeDistribution data={data} />
                    </div>
                </div>
                <br />
                <br />
                <MonadProviderNetflowChart data={data.all_provider_daily_netflow_Data} />
                <br />
                <br />
                <MonadProviderDailyTotalStakedCharts data={data.all_provider_daily_total_staked} />
                <br />
                <br />

            </div>
        </>
    );
};

export default MonadLiquidStakingDashboard;