import React from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import "./../../styles/monad_liquid_staking/monad_lst_staking_dasboard.css";

const MonadLstStakedDashboard = ({ data, chartType }) => {
    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(1);
    };

    const formatDate = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    const transformData = (rawData) => {
        if (chartType === 'staked') {
            return rawData.daily_total_staked_data.map(item => ({
                date: item.dateTime_utc,
                value: item.total_staked
            }));
        } else {
            return rawData.daily_total_supply_data.map(item => ({
                date: item.dateTime_utc,
                value: item.total_supply
            }));
        }
    };

    const chartData = data ? transformData(data) : [];

    // Get title based on chartType
    const getChartTitle = () => {
        return chartType === 'staked' ? 'Total Liquid Staked Monad' : 'Total LSD Supply Monad';
    };

    return (
        <div className="monad_lst_dashboard_container">
            {/* Chart Section */}
            <div className="monad_lst_chart_section">
                <h3 className="monad_lst_section_title">{getChartTitle()}</h3>
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart data={chartData}>
                        <defs>
                            <linearGradient id="monadStakingGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#002475" stopOpacity={0.3} />
                                <stop offset="100%" stopColor="#002475" stopOpacity={0.1} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke="#002475" opacity={0.1} />
                        <XAxis
                            dataKey="date"
                            tickFormatter={formatDate}
                            angle={-45}
                            textAnchor="end"
                            height={100}
                            tick={{ fontSize: 12 }}
                            tickMargin={20}
                        />
                        <YAxis
                            tickFormatter={formatValue}
                            tick={{ fontSize: 12 }}
                        />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div className="monad_lst_tooltip">
                                            <p className="monad_lst_tooltip_date">{formatDate(label)}</p>
                                            <p className="monad_lst_tooltip_value">
                                                {chartType === 'staked' ? 'Staked: ' : 'Supply: '}
                                                {formatValue(payload[0].payload.value)}
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="value"
                            stroke="#002475"
                            fill="url(#monadStakingGradient)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MonadLstStakedDashboard;