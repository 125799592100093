import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HLSpotEcosystemChart from './HLSpotEcosystemChart';
import TwapData from './twap/tawpData';

const HLSpotEcosystemDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data from API...');
        const response = await fetch('https://api-data.asxn.xyz/api/data/hl-spot-ecosystem');

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log('Received data with lengths:', {
          marketData: jsonData.marketData?.length,
          usdcData: jsonData.usdcData?.length,
          holdersData: jsonData.holdersData?.length,
          hip2Data: jsonData.hip2Data?.length
        });

        // Validate data structure
        if (!jsonData.marketData || !jsonData.usdcData ||
          !jsonData.holdersData || !jsonData.hip2Data) {
          throw new Error('Invalid data structure received');
        }

        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="dashboard">
        <div className="loading">Loading data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard">
        <div className="error">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <h2 className="dashboard-title">Hyperliquid Spot Ecosystem</h2>
      <div className="dashboard-content">
        {data ? (
          <HLSpotEcosystemChart
            marketData={data.marketData || []}
            usdcData={data.usdcData || []}
            holdersData={data.holdersData || []}
            hip2Data={data.hip2Data || []}
          />
        ) : (
          <div>No data available</div>
        )}
        <TwapData token_name={'*'} />
      </div>
      <div className="data-source">Source: Hyperliquid & Hypurrscan</div>
    </div>
  );
};

export default HLSpotEcosystemDashboard;
