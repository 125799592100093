import React from 'react';
import "./../../styles/monad_liquid_staking/monad_lst_staking_dasboard.css";

const MonadStatsCards = ({ latestData }) => {
    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(1);
    };

    return (
        <div className="monad_lst_cards_section">
            {/* Stats Cards */}
            <div className="monad_lst_card">
                <div className="monad_lst_card_label">Total Liquid Staked Monad</div>
                <div className="monad_lst_card_value">
                    {latestData ? formatValue(latestData.total_staked) : 'N/A'}
                </div>
            </div>

            <div className="monad_lst_card">
                <div className="monad_lst_card_label">Liquid Staked %</div>
                <div className="monad_lst_card_value">Coming Soon</div>
            </div>

            <div className="monad_lst_card">
                <div className="monad_lst_card_label">Average APR</div>
                <div className="monad_lst_card_value">Coming Soon</div>
            </div>

            {/* Optional: Uncomment if you want to include the stake card */}
            {/* <a
                href="https://app.hyperliquid.xyz/staking"
                target="_blank"
                rel="noopener noreferrer"
                className="monad_lst_link"
            >
                <div className="monad_lst_card monad_lst_asxn_card">
                    <h4>Stake with ASXN</h4>
                </div>
            </a> */}
        </div>
    );
};

export default MonadStatsCards;