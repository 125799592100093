import React, { useMemo } from 'react';
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { formatChartValue } from './Dashboard';

const HLAuctionsChart = ({ data }) => {
  const processedData = useMemo(() => {
    // Time series data
    const timeData = data
      .map(item => ({
        name: new Date(item.Time).toLocaleDateString(),
        value: parseFloat(item['USDC Cost']),
        fullTime: item.Time,
        tokenName: item.Name
      }))
      .sort((a, b) => new Date(a.fullTime) - new Date(b.fullTime));

    // Bar chart data
    const tokenCosts = {};
    const tokenFirstTime = {};
    data.forEach(item => {
      const cost = parseFloat(item['USDC Cost']);
      const time = new Date(item.Time);
      if (!tokenFirstTime[item.Name] || time < tokenFirstTime[item.Name]) {
        tokenFirstTime[item.Name] = time;
      }
      tokenCosts[item.Name] = (tokenCosts[item.Name] || 0) + cost;
    });

    const barData = Object.entries(tokenCosts)
      .map(([name, value]) => ({
        name,
        value,
        firstTime: tokenFirstTime[name]
      }))
      .sort((a, b) => a.firstTime - b.firstTime);

    const avg = barData.reduce((sum, item) => sum + item.value, 0) / barData.length;

    return {
      timeSeriesData: timeData,
      barChartData: barData,
      averagePrice: avg
    };
  }, [data]);

  const formatValue = value => typeof value === 'number' ? `$${value.toFixed(2)}` : 'N/A';

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.[0]) {
      return (
        <div className="custom-tooltip">
          <p>{payload[0].payload.name}</p>
          <p>Cost: {formatValue(payload[0].value)}</p>
          {payload[0].payload.tokenName && <p>Token: {payload[0].payload.tokenName}</p>}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="charts-container">
        <div className="chart-container" style={{
          position: 'relative',
          marginBottom: '0px',
          margin: '30px auto 0'
        }}>
          <h3 style={{ marginBottom: '30px', marginTop: '20px' }}>Auction History</h3>
          <div className="chart-watermark" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            fontSize: '24px',
            fontFamily: 'Libre Baskerville, serif',
            color: 'rgba(0, 36, 117, 0.1)',
            fontWeight: 'bold',
            pointerEvents: 'none'
          }}>ASXN</div>
          <ResponsiveContainer width="100%" height={600} style={{ margin: '-10 auto 0 4rem' }}>
            <AreaChart data={processedData.timeSeriesData}
              margin={{ left: 50, right: 100, top: 20, bottom: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={80} />
              <YAxis tickFormatter={formatChartValue} />
              <Tooltip content={<CustomTooltip />} />
              <Area type="monotone" dataKey="value" stroke="#002475" fill="#002475" fillOpacity={0.3} />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container" style={{
          position: 'relative',
          margin: '-50px auto 0'
        }}>
          <h3 style={{ marginBottom: '30px', marginTop: '20px' }}>USDC Cost by Name</h3>
          <ResponsiveContainer width="100%" height={600} style={{ margin: '0 auto 0 4rem' }}>
            <BarChart data={processedData.barChartData}
              margin={{ left: 50, right: 100, top: 20, bottom: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={80} />
              <YAxis
                tickFormatter={formatChartValue}
                domain={[0, 'auto']}
              />
              <Tooltip content={<CustomTooltip />} />
              <ReferenceLine y={processedData.averagePrice} stroke="black" strokeDasharray="3 3">
                <label position="right">Average: {formatValue(processedData.averagePrice)}</label>
              </ReferenceLine>
              <Bar dataKey="value" fill="#002475" fillOpacity={0.8} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <table className="data-table" style={{ marginTop: '-50px' }}>
        <thead>
          <tr>
            <th>Time</th>
            <th>Name</th>
            <th>USDC Cost</th>
            <th>Deployer</th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter(item => item['USDC Cost'] && item['USDC Cost'] !== 'N/A')
            .sort((a, b) => new Date(b.Time) - new Date(a.Time))
            .map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.Time).toLocaleString()}</td>
                <td>{item.Name}</td>
                <td>{formatValue(parseFloat(item['USDC Cost']))}</td>
                <td>{item.deployer || 'N/A'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default HLAuctionsChart;
