import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import "./../../../styles/stHype/staking/stakeDistribution.css";

const StakeDistributionChart = ({ stakingData }) => {
    const formatNumber = (value) => {
        if (!value && value !== 0) return '0';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}k`;
        return value.toFixed(1);
    };

    const distributionData = useMemo(() => {
        if (!stakingData?.holders) return [];

        // Convert amounts to array and filter out zeros
        const amounts = Object.values(stakingData.holders).map(v => v || 0.1);

        // Create logarithmic ranges
        const ranges = [0.1];
        let currentValue = 1;
        while (currentValue <= Math.max(...amounts)) {
            ranges.push(currentValue);
            currentValue *= 4;
        }

        // Count wallets in each range
        const distribution = ranges.slice(0, -1).map((rangeStart, index) => {
            const rangeEnd = ranges[index + 1];
            const count = Object.values(stakingData.holders).filter(amount =>
                amount >= rangeStart && amount < rangeEnd
            ).length;

            // Format range display
            let displayRange;
            if (rangeStart < 1) {
                displayRange = '< 1';
            } else if (rangeStart >= 1000000) {
                displayRange = `${(rangeStart / 1000000).toFixed(1)}M`;
            } else if (rangeStart >= 1000) {
                displayRange = `${(rangeStart / 1000).toFixed(1)}k`;
            } else {
                displayRange = rangeStart.toFixed(1);
            }

            return {
                range: `${rangeStart.toLocaleString(undefined, { maximumFractionDigits: 1 })}`,
                count,
                rangeStart,
                rangeEnd,
                displayRange
            };
        });

        return distribution;
    }, [stakingData]);

    // Find max count for y-axis domain
    const maxCount = useMemo(() => {
        if (!distributionData.length) return 0;
        const max = Math.max(...distributionData.map(d => d.count));
        // Round up to nearest thousand
        return Math.ceil(max / 1000) * 1000;
    }, [distributionData]);

    return (
        <div className="stHype_stake_distribution_container">
            <div className="stHype_stake_distribution_header">
                <h2>Distribution of Stakes</h2>
            </div>
            <div className="stHype_stake_distribution_content">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={distributionData}
                        margin={{ top: 20, right: 30, left: 60, bottom: 60 }} // Increased left margin
                    >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis
                            dataKey="displayRange"
                            tick={{ fill: '#002475' }}
                            tickLine={{ stroke: '#002475' }}
                            axisLine={{ stroke: '#e1e5eb' }}
                            angle={-45}
                            textAnchor="end"
                            height={70}
                            label={{
                                value: 'Token Amount',
                                position: 'bottom',
                                offset: 40,
                                fill: '#002475'
                            }}
                        />
                        <YAxis
                            domain={[0, maxCount]}
                            tickFormatter={formatNumber}
                            tick={{ fill: '#002475' }}
                            tickLine={{ stroke: '#002475' }}
                            axisLine={{ stroke: '#e1e5eb' }}
                            width={55} // Fixed width for Y-axis
                            label={{
                                value: 'Number of Wallets',
                                angle: -90,
                                position: 'insideLeft',
                                fill: '#002475',
                                offset: -45, // Adjusted offset
                                style: {
                                    textAnchor: 'middle'
                                }
                            }}
                        />
                        <Tooltip
                            cursor={{ fill: 'rgba(137, 144, 253, 0.1)' }}
                            content={({ active, payload }) => {
                                if (active && payload && payload.length) {
                                    const data = payload[0].payload;
                                    return (
                                        <div className="stHype_stake_distribution_tooltip">
                                            <p className="stHype_stake_distribution_tooltip_range">
                                                {formatNumber(data.rangeStart)} - {formatNumber(data.rangeEnd)} tokens
                                            </p>
                                            <p className="stHype_stake_distribution_tooltip_value">
                                                {formatNumber(data.count)} wallets
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Bar
                            dataKey="count"
                            fill="#8990fd"
                            radius={[2, 2, 0, 0]}
                            onMouseOver={() => {
                                document.querySelectorAll('.recharts-bar-rectangle').forEach(rect => {
                                    rect.style.fill = '#8990fd';
                                });
                            }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StakeDistributionChart;