import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const DistributionChart = ({ data }) => {
    const COLORS = {
        '0-10': '#E6E6FA',
        '10-100': '#B8B8FF',
        '100-1000': '#8990FD',
        '1k-5k': '#5C64F5',
        '5k-10k': '#4147B8',
        '10k+': '#002475'
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    background: 'white',
                    border: '1px solid #002475',
                    borderRadius: '4px',
                    padding: '12px',
                }}>
                    <p style={{ margin: 0, fontFamily: 'var(--app-font-family)' }}>{label}</p>
                    <p style={{ margin: 0, fontFamily: 'var(--app-font-family)' }}>
                        Recipients: {payload[0].value.toLocaleString()}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="airdrop-chart-container">
            <h3>Airdrop Distribution by Recipient Count</h3>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart
                    data={data}
                    margin={{ top: 40, right: 30, left: 60, bottom: 60 }}
                    barSize={60}
                >
                    <XAxis
                        dataKey="range"
                        tickLine={false}
                        axisLine={{ stroke: '#002475' }}
                        interval={0}
                        tick={{ fill: '#002475', fontSize: 12 }}
                        label={{
                            value: 'Token Amount Range',
                            position: 'bottom',
                            offset: 40,
                            fill: '#002475',
                            fontSize: 14
                        }}
                    />
                    <YAxis
                        tickLine={false}
                        axisLine={{ stroke: '#002475' }}
                        tick={{ fill: '#002475', fontSize: 12 }}
                        width={80}
                        label={{
                            value: 'Number of Recipients',
                            angle: -90,
                            position: 'left',
                            offset: 40,
                            fill: '#002475',
                            fontSize: 14
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="count">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[entry.range]} />
                        ))}
                        {data.map((entry, index) => (
                            <text
                                key={`label-${index}`}
                                x={index * (100 / data.length) + (50 / data.length)}
                                y={entry.count}
                                textAnchor="middle"
                                dy={-10}
                                fill="#002475"
                                fontSize={12}
                            >
                                {entry.count.toLocaleString()}
                            </text>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DistributionChart;