import React, { useState, useEffect } from 'react';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import "./../../../styles/stHype/staking/airdrop_metrics.css";
import DistributionChart from './airdropDistributionChart';
import api_url from "./../../../config/api_url.json";

const base_api_url = api_url.backend_api;

const AirdropMetrics = () => {
    const [metricsData, setMetricsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const COLORS = {
        '0-10': '#E6E6FA',
        '10-100': '#B8B8FF',
        '100-1000': '#8990FD',
        '1k-5k': '#5C64F5',
        '5k-10k': '#4147B8',
        '10k+': '#002475'
    };

    const CustomBarLabel = ({ x, y, value }) => (
        <text
            x={x}
            y={y}
            dy={-4}
            fontSize={12}
            textAnchor="middle"
            fill="#002475"
        >
            {value.toLocaleString()}
        </text>
    );

    const CustomPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, range }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius * 1.2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        // Only show label if percentage is greater than 2%
        if (percent < 0.02) return null;

        return (
            <text
                x={x}
                y={y}
                fontSize={12}
                fill="#002475"
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${range} (${(percent * 100).toFixed(1)}%)`}
            </text>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${base_api_url}/api/genesis-metrics`);
                const data = await response.json();
                setMetricsData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="airdrop-metrics">
                <div className="airdrop-loading">Loading airdrop metrics...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="airdrop-metrics">
                <div className="airdrop-error">Error loading metrics: {error}</div>
            </div>
        );
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label}`}</p>
                    <p className="info">{`Users: ${payload[0].value.toLocaleString()}`}</p>
                    <p className="info">{`Percentage: ${payload[0].payload.percentage.toFixed(2)}%`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="airdrop-metrics">
            <div className="airdrop-metrics-header">
                <h2>HYPE Token Airdrop Distribution</h2>
            </div>

            <div className="airdrop-metrics-grid">
                <div className="airdrop-metrics-card">
                    <h3>Total Airdrop</h3>
                    <div className="metric-value">{metricsData.metrics.totalAirdrop.toLocaleString()} HYPE</div>
                </div>
                <div className="airdrop-metrics-card">
                    <h3>Total Recipients</h3>
                    <div className="metric-value">{metricsData.metrics.totalUsers.toLocaleString()}</div>
                </div>
                <div className="airdrop-metrics-card">
                    <h3>Average Drop</h3>
                    <div className="metric-value">{metricsData.metrics.mean.toFixed(2)} HYPE</div>
                </div>
                <div className="airdrop-metrics-card">
                    <h3>Median Drop</h3>
                    <div className="metric-value">{metricsData.metrics.median.toFixed(2)} HYPE</div>
                </div>
            </div>

            <div className="airdrop-metrics-charts">
                <DistributionChart data={metricsData.distribution.byAmount} />

                <div className="airdrop-chart-container">
                    <h3>Airdrop Distribution by Amount Range</h3>
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={metricsData.distribution.byAmount}
                                dataKey="count"
                                nameKey="range"
                                cx="50%"
                                cy="50%"
                                outerRadius={120}
                                label={CustomPieLabel}
                                labelLine={true}
                            >
                                {metricsData.distribution.byTokens.map((entry, index) => (
                                    <Cell key={index} fill={COLORS[entry.range]} />
                                ))}
                            </Pie>
                            <Tooltip
                                formatter={(value) => `${value.toLocaleString()} HYPE`}
                            />
                            <Legend
                                layout="horizontal"
                                align="right"
                            // verticalAlign="middle"
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div className="airdrop-metrics-percentiles">
                <h3>Distribution Percentiles</h3>
                <div className="percentiles-grid">
                    <div className="percentile-item">
                        <div className="percentile-label">10th Percentile</div>
                        <div className="percentile-value">{metricsData.metrics.percentiles.p10.toFixed(2)}</div>
                        <div className="percentile-unit">HYPE</div>
                    </div>
                    <div className="percentile-item">
                        <div className="percentile-label">25th Percentile</div>
                        <div className="percentile-value">{metricsData.metrics.percentiles.p25.toFixed(2)}</div>
                        <div className="percentile-unit">HYPE</div>
                    </div>
                    <div className="percentile-item">
                        <div className="percentile-label">50th Percentile</div>
                        <div className="percentile-value">{metricsData.metrics.percentiles.p50.toFixed(2)}</div>
                        <div className="percentile-unit">HYPE</div>
                    </div>
                    <div className="percentile-item">
                        <div className="percentile-label">75th Percentile</div>
                        <div className="percentile-value">{metricsData.metrics.percentiles.p75.toFixed(2)}</div>
                        <div className="percentile-unit">HYPE</div>
                    </div>
                    <div className="percentile-item">
                        <div className="percentile-label">99th Percentile</div>
                        <div className="percentile-value">{metricsData.metrics.percentiles.p99.toFixed(2)}</div>
                        <div className="percentile-unit">HYPE</div>
                    </div>
                </div>
            </div>

            <div className="airdrop-range">
                <span>Total Range: {metricsData.metrics.minimum.toFixed(2)} HYPE - {metricsData.metrics.maximum.toFixed(2)} HYPE</span>
            </div>
        </div>
    );
};

export default AirdropMetrics;