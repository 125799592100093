import React from 'react';
import { PieChart, Pie, BarChart, Bar, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const COLORS = [
  'rgba(0, 36, 117, 0.9)',      // Dark blue for largest segment
  'rgba(30, 136, 229, 0.9)',    // Lighter blue
  'rgba(66, 165, 245, 0.9)',    // Even lighter blue
  'rgba(144, 202, 249, 0.9)',   // Very light blue
  'rgba(227, 242, 253, 0.9)',   // Palest blue
  'rgba(100, 181, 246, 0.9)',   // Medium light blue
  'rgba(33, 150, 243, 0.9)',    // Medium blue
  'rgba(25, 118, 210, 0.9)',    // Medium dark blue
];

const DataTable = ({ data, columns }) => {
  const [sortConfig, setSortConfig] = React.useState({
    key: null,
    direction: 'asc'
  });

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      if (typeof aValue === 'string') {
        return sortConfig.direction === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      return sortConfig.direction === 'asc' 
        ? aValue - bValue
        : bValue - aValue;
    });
  }, [data, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((current) => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            {columns.map((col, i) => (
              <th 
                key={i}
                onClick={() => requestSort(col.key)}
                className="sortable-header"
              >
                {col.header}
                {sortConfig.key === col.key && (
                  <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, i) => (
            <tr key={i}>
              {columns.map((col, j) => (
                <td key={j}>
                  {col.formatter ? col.formatter(row[col.key]) : row[col.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const HLSpotEcosystemChart = ({ marketData, usdcData, holdersData, hip2Data }) => {
  console.log('Chart component received:', {
    marketData: marketData?.length,
    usdcData: usdcData?.length,
    holdersData: holdersData?.length,
    hip2Data: hip2Data?.length,
    marketDataSample: marketData?.[0],
    usdcDataSample: usdcData?.[0],
    holdersDataSample: holdersData?.[0],
    hip2DataSample: hip2Data?.[0]
  });

  console.log('Raw data samples:', {
    marketData: marketData?.[0],
    usdcData: usdcData?.[0],
    holdersData: holdersData?.[0],
    hip2Data: hip2Data?.[0]
  });

  const formatValue = (value) => {
    if (typeof value !== 'number') return 'N/A';
    if (value >= 1000000000) return `$${(value / 1000000000).toFixed(1)}B`;
    if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
    return `$${(value / 1000).toFixed(0)}K`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.[0]) {
      return (
        <div className="custom-tooltip">
          <p>{label || payload[0].payload.name}</p>
          <p>Value: {formatValue(payload[0].value)}</p>
          {payload[0].payload.percentage && 
            <p>Share: {payload[0].payload.percentage.toFixed(2)}%</p>
          }
        </div>
      );
    }
    return null;
  };

  // Transform market data
  const processedMarketData = marketData?.map(item => {
    try {
      return {
        name: item.Name || 'Unknown',
        marketCap: parseFloat(item['Market Cap']) || 0,
        dailyVolume: parseFloat(item['Daily Volume']) || 0,
        percentage: parseFloat(item['Market Share']) || 0
      };
    } catch (error) {
      console.error('Error processing market data item:', item, error);
      return null;
    }
  }).filter(Boolean) || [];

  console.log('Processed Market Data:', processedMarketData);

  // Generic aggregation function that can handle different value keys
  const aggregateByDay = (data, valueKey) => {
    console.log('Aggregating data:', {
      sampleData: data.slice(0, 2),
      valueKey,
    });

    const dailyData = data.reduce((acc, item) => {
      try {
        // Ensure date is valid
        const dateStr = item.date;
        if (!dateStr) {
          console.warn('Missing date in item:', item);
          return acc;
        }

        // Parse date string - handle both Unix timestamp and ISO string formats
        let date;
        if (typeof dateStr === 'number' || /^\d+$/.test(dateStr)) {
          // Unix timestamp (in seconds or milliseconds)
          const timestamp = parseInt(dateStr);
          date = new Date(timestamp * (timestamp < 10000000000 ? 1000 : 1)).toISOString().split('T')[0];
        } else {
          // ISO string or other date format
          date = new Date(dateStr).toISOString().split('T')[0];
        }

        if (!acc[date]) {
          acc[date] = {
            values: [],
            date: date
          };
        }

        const value = parseFloat(item[valueKey]);
        if (!isNaN(value)) {
          acc[date].values.push(value);
        } else {
          console.warn(`Invalid value for ${valueKey}:`, item[valueKey]);
        }
        
        return acc;
      } catch (error) {
        console.error('Error processing item:', item, error);
        return acc;
      }
    }, {});

    // Calculate daily average for each date
    const result = Object.values(dailyData)
      .map(day => ({
        date: day.date,
        [valueKey]: day.values.length > 0 
          ? day.values.reduce((sum, val) => sum + val, 0) / day.values.length
          : 0
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    console.log('Aggregation result:', {
      inputLength: data.length,
      outputLength: result.length,
      sampleResult: result.slice(0, 2)
    });

    return result;
  };

  // Transform USDC data
  const processedUsdcData = aggregateByDay(usdcData, 'totalSpotUSDC');

  console.log('Processed USDC Data:', processedUsdcData);

  // Transform holders data
  const processedHoldersData = aggregateByDay(holdersData, 'holdersCount');

  console.log('Processed Holders Data:', processedHoldersData);

  // Transform HIP2 data
  const processedHip2Data = aggregateByDay(hip2Data, 'value');

  console.log('Processed HIP2 Data:', processedHip2Data);

  console.log('Processed data:', {
    marketData: processedMarketData.length,
    usdcData: processedUsdcData.length,
    holdersData: processedHoldersData.length,
    hip2Data: processedHip2Data.length,
    marketDataSample: processedMarketData[0],
    usdcDataSample: processedUsdcData[0],
    holdersDataSample: processedHoldersData[0],
    hip2DataSample: processedHip2Data[0]
  });

  // Common formatting helpers at the top
  const formatXAxis = (date) => {
    const d = new Date(date);
    return `${d.toLocaleString('default', { month: 'short' })} ${d.getDate()}`;
  };

  const commonTooltipStyle = {
    backgroundColor: 'white', 
    padding: '10px', 
    border: '1px solid #ccc'
  };

  const commonChartMargins = { top: 10, right: 30, left: 60, bottom: 20 };

  const commonXAxisProps = (dataLength) => ({
    tickFormatter: formatXAxis,
    tick: { 
      fill: '#666',
      fontSize: 11  // Smaller font size
    },
    interval: Math.ceil(dataLength / 6),  // Show fewer ticks (changed from 10 to 6)
    angle: -45,  // Increased angle for better readability
    textAnchor: "end",
    height: 60
  });

  const commonYAxisProps = (formatter, label, width = 60) => ({
    tickFormatter: formatter,
    tick: { 
      fill: '#666',
      fontSize: 11
    },
    width: width,
    interval: 'preserveStartEnd',
    label: { 
      value: label, 
      angle: -90, 
      position: 'insideLeft',
      offset: -20,
      fontSize: 11
    }
  });

  const commonAreaProps = (color) => ({
    type: "monotone",
    stroke: color,
    fill: color,
    fillOpacity: 0.3,
    dot: false,
    activeDot: { r: 4 }
  });

  const chartTitleStyle = {
    fontSize: '16px',  // Smaller title font size
    fontWeight: 500,
    marginBottom: '15px'
  };

  return (
    <div className="charts-container">
      <div style={{ 
        textAlign: 'center', 
        fontSize: '14px', 
        color: '#666', 
        fontStyle: 'italic',
        marginBottom: '15px',
        fontFamily: 'Roboto Mono, monospace'
      }}>
        Data from <a href="https://hypurrscan.io">hypurrscan.io</a>
      </div>

      {/* Market Data Table at the very top */}
      {processedMarketData.length > 0 && (
        <div className="market-data-table-section">
          <h3>Market Data</h3>
          <DataTable 
            data={processedMarketData}
            columns={[
              { header: 'Name', key: 'name' },
              { header: 'Market Cap', key: 'marketCap', formatter: formatValue },
              { header: 'Daily Volume', key: 'dailyVolume', formatter: formatValue },
              { header: 'Market Share', key: 'percentage', formatter: (val) => `${val.toFixed(2)}%` }
            ]}
          />
        </div>
      )}

      {/* First row: Market Share and Daily Volume */}
      <div className="charts-row">
        <div className="chart-column">
          <h3>Hyperliquid: Circulating Market Share</h3>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart margin={{ top: 20, right: 10, bottom: 20, left: 20 }}>
              <Pie
                data={processedMarketData}
                dataKey="marketCap"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={190}
                startAngle={90}
                endAngle={450}
                labelLine={false}
                strokeWidth={0}
                label={({ name, percent, cx, cy, midAngle, innerRadius, outerRadius }) => {
                  if (percent < 0.01) return null;

                  const RADIAN = Math.PI / 180;
                  const radius = innerRadius + (outerRadius - innerRadius) * (percent < 0.13 ? 0.7 : 0.5);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  
                  let rotate = -midAngle;
                  if (midAngle > 90 && midAngle < 270) {
                    rotate += 180;
                  }
                  
                  const fontSize = Math.max(8, Math.min(30, Math.sqrt(percent * 600)));
                  
                  return (
                    <text 
                      x={x}
                      y={y}
                      fill="white"
                      textAnchor="middle"
                      dominantBaseline="central"
                      style={{ fontSize: `${fontSize}px` }}
                      transform={`rotate(${rotate}, ${x}, ${y})`}
                    >
                      {`${name} 
                      ${(percent * 100).toFixed(1)}%`}
                    </text>
                  );
                }}
              >
                {processedMarketData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
        
        <div className="chart-column">
          <h3 style={chartTitleStyle}>Hyperliquid: Daily Volume</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart 
              data={processedMarketData
                .filter(item => item.dailyVolume > 10000)
                .sort((a, b) => b.dailyVolume - a.dailyVolume)
                .slice(0, 10)
              }
              margin={{ top: 10, right: 30, left: 40, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
              <XAxis 
                dataKey="name" 
                tick={{ fill: '#666' }}
                angle={-45}
                textAnchor="end"
                height={50}
                fontSize={10}
              />
              <YAxis 
                {...commonYAxisProps(formatValue, 'Daily Volume', 60)}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload?.[0]) {
                    return (
                      <div style={commonTooltipStyle}>
                        <p style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>Daily Volume</p>
                        <p style={{ margin: '0 0 0 0' }}>{payload[0].payload.name}</p>
                        <p style={{ margin: 0 }}>{formatValue(payload[0].value)}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Bar dataKey="dailyVolume" fill="#002475" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Second row: Holders and Spot USDC */}
      <div className="charts-row">
        <div className="chart-column">
          <h3 style={chartTitleStyle}>Hyperliquid: Spot USDC Holders Over Time</h3>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart 
              data={processedHoldersData}
              margin={commonChartMargins}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
              <XAxis {...commonXAxisProps(processedHoldersData.length)} dataKey="date" />
              <YAxis 
                {...commonYAxisProps(
                  (value) => new Intl.NumberFormat('en-US').format(value),
                  'Number of Holders',
                  80
                )}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload?.[0]) {
                    return (
                      <div style={commonTooltipStyle}>
                        <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>Spot USDC Holders</p>
                        <p style={{ margin: '0 0 5px 0' }}>
                          {new Date(payload[0].payload.date).toLocaleDateString()}
                        </p>
                        <p style={{ margin: 0 }}>
                          {new Intl.NumberFormat('en-US').format(payload[0].value)}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Area {...commonAreaProps('#FF9966')} dataKey="holdersCount" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-column">
          <h3 style={chartTitleStyle}>Hyperliquid: Spot USDC</h3>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart 
              data={processedUsdcData}
              margin={{ 
                top: 10,    // Increased from 10
                right: 10,  // Increased from 10
                left: 40,   // Increased from 30
                bottom: 20  // Increased from 20
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
              <XAxis {...commonXAxisProps(processedUsdcData.length)} dataKey="date" />
              <YAxis 
                {...commonYAxisProps(
                  (value) => new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(value),
                  'Total USDC',
                  80  // Increased width for Y-axis
                )}
              />
              <Tooltip 
                content={({ active, payload }) => {
                  if (active && payload?.[0]) {
                    return (
                      <div style={commonTooltipStyle}>
                        <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>Spot USDC</p>
                        <p style={{ margin: '0 0 5px 0' }}>
                          {new Date(payload[0].payload.date).toLocaleDateString()}
                        </p>
                        <p style={{ margin: 0 }}>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(payload[0].value)}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Area {...commonAreaProps('#1E88E5')} dataKey="totalSpotUSDC" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Last row: HIP-2 */}
      <div className="chart-single">
        <h3 style={chartTitleStyle}>Hyperliquid: HIP-2 Over Time</h3>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart 
            data={processedHip2Data}
            margin={commonChartMargins}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
            <XAxis {...commonXAxisProps(processedHip2Data.length)} dataKey="date" />
            <YAxis {...commonYAxisProps(formatValue, 'HIP-2 Value')} />
            <Tooltip 
              content={({ active, payload }) => {
                if (active && payload?.[0]) {
                  return (
                    <div style={commonTooltipStyle}>
                      <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>HIP-2</p>
                      <p style={{ margin: '0 0 5px 0' }}>
                        {new Date(payload[0].payload.date).toLocaleDateString()}
                      </p>
                      <p style={{ margin: 0 }}>{formatValue(payload[0].value)}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area {...commonAreaProps('#4CAF50')} dataKey="value" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HLSpotEcosystemChart;
