import React, { useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
} from 'recharts';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'var(--app-font-family)',
        color: 'var(--text-color)',
        border: '1px solid var(--border-color)',
        borderRadius: '8px',
        overflow: 'hidden',
        height: '480px'
    },
    chartTitle: {
        fontSize: '16px',
        fontWeight: 'normal',
        color: 'var(--text-color)',
        textAlign: 'center',
        padding: '10px 0 2px 0',
        backgroundColor: 'white'
    },
    chartSubtitle: {
        fontSize: '11px',
        color: 'var(--text-color)',
        opacity: 0.7,
        textAlign: 'center',
        padding: '0 0 5px 0',
        backgroundColor: 'white'
    },
    chartContainer: {
        padding: '15px',
        backgroundColor: 'white',
        height: '330px', // Adjust to fit within the container
        position: 'relative'
    },
    tooltipContainer: {
        backgroundColor: 'white',
        border: '1px solid var(--border-color)',
        borderRadius: '4px',
        padding: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    tooltipTitle: {
        margin: '0 0 8px 0',
        fontWeight: 'bold',
        color: 'var(--primary-color)',
        borderBottom: '1px solid var(--border-color)',
        paddingBottom: '4px'
    },
    tooltipItem: {
        margin: '4px 0',
        fontSize: '12px'
    },
    legendContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '8px',
        padding: '8px 0',
        borderBottom: '1px solid var(--border-color)',
        backgroundColor: 'white'
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 12px',
        cursor: 'pointer',
        borderRadius: '4px',
        fontSize: '12px',
        border: '1px solid var(--border-color)',
        transition: 'all 0.2s',
        fontWeight: 'medium'
    },
    legendColorBox: {
        width: '12px',
        height: '12px',
        marginRight: '6px',
        borderRadius: '2px'
    },
    selectAllButton: {
        padding: '4px 12px',
        cursor: 'pointer',
        backgroundColor: '#0D2C54',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        fontSize: '12px',
        marginRight: '8px',
        fontWeight: 'bold'
    },
    deselectedItem: {
        opacity: 0.5,
        backgroundColor: '#f5f5f5'
    }
};

const MonadDailyProviderStakedTimeseries = ({ data, chartType }) => {
    const [selectedProviders, setSelectedProviders] = useState(new Set());
    const [showAllProviders, setShowAllProviders] = useState(true);

    const COLORS = [
        '#2E8B57',  // Sea Green
        '#FF6B6B',  // Coral Red
        '#4ECDC4',  // Turquoise
        '#FFB347',  // Pastel Orange
        '#9FE2BF',  // Seafoam Green
        '#845EC2'   // Purple
    ];

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(1);
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    // Get all unique provider names across all data entries
    const getAllProviderNames = () => {
        if (!data || !data.daily_provider_data || data.daily_provider_data.length === 0) return [];

        const allProviderNames = new Set();
        data.daily_provider_data.forEach(dayData => {
            Object.values(dayData.providers || {}).forEach(provider => {
                if (provider && provider.name) {
                    allProviderNames.add(provider.name);
                }
            });
        });

        return Array.from(allProviderNames);
    };

    const processTimeSeriesData = () => {
        if (!data || !data.daily_provider_data) return [];

        const allProviderNames = getAllProviderNames();

        return data.daily_provider_data.map(dayData => {
            const processedData = {
                date: dayData.dateTime_utc
            };

            // Initialize all providers with 0 value
            allProviderNames.forEach(name => {
                processedData[name] = 0;
            });

            // Set actual values for providers that exist in this entry
            Object.values(dayData.providers || {}).forEach(provider => {
                if (provider && provider.name) {
                    // Use chartType to determine which data to show
                    if (chartType === 'staked') {
                        processedData[provider.name] = provider.total_staked || 0;
                    } else {
                        processedData[provider.name] = provider.total_supply || 0;
                    }
                }
            });

            return processedData;
        });
    };

    const toggleProvider = (providerName) => {
        setSelectedProviders(prev => {
            const newSelected = new Set(prev);
            if (prev.has(providerName)) {
                newSelected.delete(providerName);
            } else {
                newSelected.add(providerName);
            }
            return newSelected;
        });
        setShowAllProviders(false);
    };

    const toggleAllProviders = () => {
        setSelectedProviders(new Set());
        setShowAllProviders(true);
    };

    const timeseriesData = processTimeSeriesData();
    const providers = getAllProviderNames();

    // Get title based on chartType
    const getChartTitle = () => {
        return chartType === 'staked' ? 'Provider Stakes Over Time' : 'Provider Supply Over Time';
    };

    // Custom legend that allows toggling visibility
    const CustomizedLegend = () => {
        return (
            <div style={styles.legendContainer}>
                <button
                    style={styles.selectAllButton}
                    onClick={toggleAllProviders}
                >
                    Select All
                </button>
                {providers.map((name, index) => (
                    <div
                        key={name}
                        onClick={() => toggleProvider(name)}
                        style={{
                            ...styles.legendItem,
                            ...((!showAllProviders && !selectedProviders.has(name)) ? styles.deselectedItem : {})
                        }}
                    >
                        <span
                            style={{
                                ...styles.legendColorBox,
                                backgroundColor: COLORS[index % COLORS.length]
                            }}
                        />
                        <span>{name}</span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div style={styles.container}>
            <div style={styles.chartTitle}>{getChartTitle()}</div>
            <div style={styles.chartSubtitle}>
                Click on labels below to show/hide individual data
            </div>
            <CustomizedLegend />
            <div style={styles.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={timeseriesData}>
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="rgba(0,0,0,0.1)"
                            horizontal={true}
                            vertical={false}
                        />
                        <XAxis
                            dataKey="date"
                            tickFormatter={formatDate}
                            angle={-35}
                            textAnchor="end"
                            height={60}
                            tick={{ fontSize: 10 }}
                            stroke="rgba(0,0,0,0.3)"
                            interval="preserveStartEnd"
                        />
                        <YAxis
                            tickFormatter={formatValue}
                            tick={{ fontSize: 11 }}
                            stroke="rgba(0,0,0,0.3)"
                        />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div style={styles.tooltipContainer}>
                                            <p style={styles.tooltipTitle}>{formatDate(label)}</p>
                                            {payload.map((entry, index) => (
                                                <p key={index} style={{
                                                    ...styles.tooltipItem,
                                                    color: entry.color
                                                }}>
                                                    {entry.name}: {formatValue(entry.value)} MON
                                                </p>
                                            ))}
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        {providers.map((name, index) => (
                            (showAllProviders || selectedProviders.has(name)) && (
                                <Area
                                    key={name}
                                    type="monotone"
                                    dataKey={name}
                                    stackId="1"
                                    stroke={COLORS[index % COLORS.length]}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            )
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MonadDailyProviderStakedTimeseries;