import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import BTCETFDashboard from './components/BTCETFDashboard';
import ETHETFDashboard from './components/ETHETFDashboard';
import HLSpotEcosystemDashboard from './components/HLSpotEcosystemDashboard';
import HolderTrends from './components/holder_trends/holderTrends';
import StHypeStakingComponent from './components/stHype/staking/index.jsx';
import HypeStakingComponent from './components/hype/staking/index.jsx';
import WalletChecker from './components/hype/staking/walletChecker.jsx';
import HypeBurnDashboard from './components/HypeBurnDashboard';
import './index.css';

const Header = () => {
  const location = useLocation();

  const showHyperliquidLink = [
    '/dashboard/hype',
    '/dashboard/hype-burn',
    '/dashboard/hl-spot-ecosystem',
    '/dashboard/spot-holder-trend',
    '/dashboard/hl-auctions',
  ].includes(location.pathname);

  return (
    <div className="header_box">
      <header className="header">
        <Link to="/" className="logo-link">
          <h1 className="logo asxn-logo">ASXN Data</h1>
        </Link>
        {showHyperliquidLink && (
          <a
            href="https://app.hyperliquid.xyz/join/ASXN"
            target="_blank"
            rel="noopener noreferrer"
            className="hyperliquid-link"
          >
            Trade on Hyperliquid
          </a>
        )}
      </header>
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard/hype-airdrop-checker" element={<WalletChecker />} />
          <Route path="/dashboard/hype" element={<HypeStakingComponent />} />
          <Route path="/dashboard/hl-spot-ecosystem" element={<HLSpotEcosystemDashboard />} />
          <Route path="/dashboard/spot-holder-trend" element={<HolderTrends />} />
          <Route path="/dashboard/stHype-staking" element={<StHypeStakingComponent />} />
          <Route path="/dashboard/btc-etf-flows" element={<BTCETFDashboard />} />
          <Route path="/dashboard/eth-etf-flows" element={<ETHETFDashboard />} />
          <Route path="/dashboard/hype-burn" element={<HypeBurnDashboard />} />
          <Route path="/dashboard/:id" element={<Dashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
