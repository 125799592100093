import React, { useState } from 'react';

const styles = `

.table-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 16px;
  font-family: var(--app-font-family);
}

.table-subtitle {
  color: #666;
  margin-bottom: 24px;
  font-size: 14px;
}

.table-container {
  width: 100%;
  background: var(--card-background-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
}

.data-table thead th {
  background: var(--primary-color);
  color: white;
  padding: 16px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.data-table thead th:hover {
  background: rgba(0, 36, 117, 0.9);
}

.data-table tbody tr {
  border-bottom: 1px solid var(--border-color);
}

.data-table tbody tr:hover {
  background: var(--background-color);
}

.data-table td {
  padding: 12px 16px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--border-color);
}

.pagination-info {
  color: var(--text-color);
}

.pagination-controls {
  display: flex;
  gap: 8px;
  align-items: center;
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: none;
  cursor: pointer;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background: var(--background-color);
}

.sort-indicator {
  margin-left: 8px;
}

.loading-state {
  padding: 20px;
  text-align: center;
  color: var(--text-color);
}

.error-state {
  padding: 20px;
  text-align: center;
  color: #dc2626;
}
  .positive-value {
  color: #22c55e;
  font-weight: 500;
}

.negative-value {
  color: #ef4444;
  font-weight: 500;
}

.cell-with-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
}

.indicator {
  font-size: 12px;
}
  .stats-summary {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.stat-item {
  background: var(--card-background-color);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stat-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.stat-value {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
}
`;

const HypeGenesisAnalysisTopWallet = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(20);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  if (!data?.data || !Array.isArray(data.data)) {
    return <div className="loading-state">No data available</div>;
  }

  const tableData = data.data;

  const parseNumericString = (value) => {
    if (typeof value === 'string') {
      return parseFloat(value.replace(/,/g, ''));
    }
    return value;
  };

  const formatValue = (value, key) => {
    if (typeof value === 'string' && value.startsWith('0x')) {
      return value;
    }

    if (key === 'Balance Change' || key === 'Change Percentage') {
      const numValue = parseFloat(value);
      const isPositive = numValue > 0;
      const className = isPositive ? 'positive-value' : numValue < 0 ? 'negative-value' : '';
      const indicator = isPositive ? '↑' : numValue < 0 ? '↓' : '';

      return (
        <div className="cell-with-indicator">
          <span className={className}>
            {typeof numValue === 'number' ? numValue.toLocaleString() : value}
            {key === 'Change Percentage' ? '%' : ''}
          </span>
          {indicator && <span className={`indicator ${className}`}>{indicator}</span>}
        </div>
      );
    }
    if (key === 'Genesis Balance' || key === 'Current Balance') {
      const numValue = parseFloat(value);
      return numValue.toLocaleString()
    }

    return typeof value === 'number' ? value.toLocaleString() : value;
  };

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
  };

  const sortedData = [...tableData].sort((a, b) => {
    if (!sortField) return 0;

    // Special handling for Address field
    if (sortField === 'Address') {
      return sortDirection === 'asc'
        ? a[sortField].localeCompare(b[sortField])
        : b[sortField].localeCompare(a[sortField]);
    }

    // Convert string numbers to actual numbers for comparison
    const aValue = parseNumericString(a[sortField]);
    const bValue = parseNumericString(b[sortField]);

    if (isNaN(aValue) || isNaN(bValue)) {
      return 0;
    }

    const compareResult = aValue - bValue;
    return sortDirection === 'asc' ? compareResult : -compareResult;
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(tableData.length / entriesPerPage);

  return (
    <>
      <style>{styles}</style>
      <h1 className="table-title">Top HYPE Token Holders Analysis</h1>
      <p className="table-subtitle">Tracking changes in wallet balances and distribution</p>

      {/* <div className="stats-summary">
                <div className="stat-item">
                    <div className="stat-label">Total Wallets</div>
                    <div className="stat-value">{data.stats.totalWallets.toLocaleString()}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-label">Current Total Balance</div>
                    <div className="stat-value">{data.stats.totalCurrentBalance.toLocaleString()}</div>
                </div>
                <div className="stat-item">
                    <div className="stat-label">Genesis Total Balance</div>
                    <div className="stat-value">{data.stats.totalGenesisBalance.toLocaleString()}</div>
                </div>
            </div> */}
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              {Object.keys(tableData[0]).map((header) => (
                <th key={header} onClick={() => handleSort(header)}>
                  {header}
                  {sortField === header && (
                    <span className="sort-indicator">
                      {sortDirection === 'asc' ? '↑' : '↓'}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((row, index) => (
              <tr key={index}>
                {Object.entries(row).map(([key, value], cellIndex) => (
                  <td key={cellIndex}>
                    {formatValue(value, key)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <div className="pagination-info">
            Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, tableData.length)} of {tableData.length} entries
          </div>
          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button
              className="pagination-button"
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HypeGenesisAnalysisTopWallet;