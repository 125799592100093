import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import BTCETFDashboard from './components/BTCETFDashboard';
import ETHETFDashboard from './components/ETHETFDashboard';
import HLSpotEcosystemDashboard from './components/HLSpotEcosystemDashboard';
import HolderTrends from './components/holder_trends/holderTrends';
import StHypeStakingComponent from './components/stHype/staking/index.jsx';
import HypeTokenComponent from './components/hype/staking/index.jsx';
// import HypeStakingComponent from './components/hype_staking/index.js';
import WalletChecker from './components/hype/staking/walletChecker.jsx';
import HypeBurnDashboard from './components/HypeBurnDashboard';
import './index.css';
import HypeStakingComponent from './components/hypeStake/index.js';
import MonadEcosystem from './components/monad_ecosystem';
import HyperliquidEcosystem from './components/hyperliquid_ecosystem';
import MonadLiquidStakingDashboard from './components/monad_liquid_staking/index.js';
import HypeFundingRateTable from './components/funding_rate/hyperliquid_funding_rate.jsx';
import PriceReturnsDashboard from './components/PriceReturnsDashboard';
// import GovernanceDashboard from './components/GovernanceDashboard';
import HyperliquidRiskMetrics from './components/HyperliquidRiskMetrics';


const Header = () => {
  const location = useLocation();

  const showHyperliquidLink = [
    '/dashboard/hype-staking',
    '/dashboard/hype',
    '/dashboard/hype-burn',
    '/dashboard/hl-spot-ecosystem',
    '/dashboard/spot-holder-trend',
    '/dashboard/hl-auctions',
  ].includes(location.pathname);

  return (
    <div className="header_box">
      <header className="header">
        <Link to="/" className="logo-link">
          <h1 className="logo asxn-logo">ASXN Data</h1>
        </Link>
        {showHyperliquidLink && (
          <a
            href="https://app.hyperliquid.xyz/join/ASXNLAB"
            target="_blank"
            rel="noopener noreferrer"
            className="hyperliquid-link"
          >
            Trade on Hyperliquid
          </a>
        )}
      </header>
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard/hype-airdrop-checker" element={<WalletChecker />} />
          <Route path="/dashboard/hl-funding-rate" element={<HypeFundingRateTable />} />
          <Route path="/dashboard/hype" element={<HypeTokenComponent />} />
          <Route path="/dashboard/hype-Staking" element={<HypeStakingComponent />} />
          <Route path="/dashboard/hl-spot-ecosystem" element={<HLSpotEcosystemDashboard />} />
          <Route path="/dashboard/spot-holder-trend" element={<HolderTrends />} />
          <Route path="/dashboard/stHype-staking" element={<StHypeStakingComponent />} />
          <Route path="/dashboard/btc-etf-flows" element={<BTCETFDashboard />} />
          <Route path="/dashboard/eth-etf-flows" element={<ETHETFDashboard />} />
          <Route path="/dashboard/hype-burn" element={<HypeBurnDashboard />} />
          <Route path="/dashboard/monad-ecosystem" element={<MonadEcosystem />}>
            <Route path=":filterType" element={<MonadEcosystem />} />
          </Route>
          <Route path="/dashboard/monad-liquid-staking" element={<MonadLiquidStakingDashboard />} />
          <Route path="/dashboard/price-returns" element={<PriceReturnsDashboard />} />
          <Route path="/dashboard/hl-risk-metrics" element={<HyperliquidRiskMetrics />} />
          <Route path="/dashboard/:id" element={<Dashboard />} />
          <Route path="/dashboard/hyperliquid-ecosystem" element={<HyperliquidEcosystem />} />
          <Route path="/dashboard/hyperliquid-ecosystem/:filterType" element={<HyperliquidEcosystem />} />
          {/* <Route path="/dashboard/governance" element={<GovernanceDashboard />} /> */}
        </Routes>
      </div>
    </Router >
  );
}

export default App;
