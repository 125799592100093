import React from 'react';
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const styles = {
    container: {
        padding: '5px',
        backgroundColor: 'white',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        fontFamily: 'var(--app-font-family)',
        // boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        border: '1px solid var(--border-color)',
        // margin: '10px'
    },
    title: {
        textAlign: 'center',
        fontSize: '20px',
        color: 'var(--text-color)',
        marginBottom: '20px',
        fontWeight: 'normal',
        fontFamily: 'var(--app-font-family)'
    },
    tooltipContainer: {
        backgroundColor: 'var(--card-background-color)',
        border: '1px solid var(--border-color)',
        borderRadius: '4px',
        padding: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    tooltipTitle: {
        margin: '0 0 8px 0',
        fontWeight: 'bold',
        color: 'var(--text-color)',
        borderBottom: '1px solid var(--border-color)',
        paddingBottom: '4px'
    },
    tooltipContent: {
        margin: '4px 0',
        fontSize: '12px',
        color: 'var(--text-color)'
    }
};

// Dynamic color assignment for providers
const getColorForProvider = (provider, index) => {
    const COLORS = [
        '#FF6B6B',  // Coral Red
        '#4ECDC4',  // Turquoise
        '#FFB347',  // Pastel Orange
        '#9FE2BF',  // Seafoam Green
        '#845EC2',  // Purple
        '#2E8B57'   // Sea Green
    ];

    // Special cases for known providers
    if (provider === 'aprMON') return '#FF6B6B';
    if (provider === 'stMON') return '#4ECDC4';
    if (provider === 'gMON') return '#FFB347';

    // Default to color based on index
    return COLORS[index % COLORS.length];
};

const MonadLiquidUnstakingChart = ({ data }) => {
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(3)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(2);
    };

    // Find all unique providers across all data points
    const getAllProviders = () => {
        if (!data || !data.cumulative_unstaking_data) return [];

        const allProviders = new Set();
        data.cumulative_unstaking_data.forEach(entry => {
            if (entry.provider_breakdown) {
                Object.keys(entry.provider_breakdown).forEach(key => {
                    allProviders.add(key);
                });
            }
        });

        return Array.from(allProviders);
    };

    const transformData = (rawData) => {
        if (!rawData || !rawData.cumulative_unstaking_data) return [];

        const allProviders = getAllProviders();

        return rawData.cumulative_unstaking_data.map(day => {
            // Start with date and cumulative value
            const entryData = {
                date: day.dateTime_utc,
                cumulative_unstaked: day.cumulative_unstaked
            };

            // Initialize all providers with 0
            allProviders.forEach(key => {
                entryData[key] = 0;
            });

            // Add actual provider data where available
            if (day.provider_breakdown) {
                Object.entries(day.provider_breakdown).forEach(([key, provider]) => {
                    if (provider && typeof provider.unstaked_amount === 'number') {
                        entryData[key] = provider.unstaked_amount;
                    }
                });
            }

            return entryData;
        });
    };

    const chartData = transformData(data);
    const allProviders = getAllProviders();

    // Get provider display names
    const getProviderDisplayName = (key) => {
        if (!data || !data.cumulative_unstaking_data || !data.cumulative_unstaking_data[0]) return key;

        // Look through all data points to find a non-empty provider name
        for (const day of data.cumulative_unstaking_data) {
            if (day.provider_breakdown &&
                day.provider_breakdown[key] &&
                day.provider_breakdown[key].name) {
                return day.provider_breakdown[key].name;
            }
        }

        return key; // Fallback to key if name not found
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Daily Unstaked (MON)</h2>
            <div style={{ height: '400px', width: '100%' }}>
                <ResponsiveContainer>
                    <ComposedChart
                        data={chartData}
                        margin={{ top: 20, right: 10, left: 30, bottom: 20 }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="rgba(0,0,0,0.1)"
                            horizontal={true}
                            vertical={false}
                        />
                        <XAxis
                            dataKey="date"
                            tickFormatter={formatDate}
                            tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--border-color)' }}
                            angle={-45}
                            textAnchor="end"
                            height={80}
                        />
                        <YAxis
                            yAxisId="left"
                            tickFormatter={formatValue}
                            tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--border-color)' }}
                            label={{
                                value: 'Undelegation Amount',
                                angle: -90,
                                position: 'insideLeft',
                                offset: -10,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tickFormatter={formatValue}
                            tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                            axisLine={{ stroke: 'var(--border-color)' }}
                            label={{
                                value: 'Cumulative Amount',
                                angle: 90,
                                position: 'insideRight',
                                offset: -50,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div style={styles.tooltipContainer}>
                                            <p style={styles.tooltipTitle}>
                                                {formatDate(label)}
                                            </p>
                                            {payload.map((entry) => (
                                                entry.dataKey !== 'cumulative_unstaked' && (
                                                    <p
                                                        key={entry.dataKey}
                                                        style={{
                                                            ...styles.tooltipContent,
                                                            color: entry.color
                                                        }}
                                                    >
                                                        {entry.name}: {formatValue(entry.value)} MON
                                                    </p>
                                                )
                                            ))}
                                            {/* Uncomment to show cumulative in tooltip
                                            <p
                                                style={{
                                                    ...styles.tooltipContent,
                                                    color: '#5c6ac4',
                                                    borderTop: '1px solid var(--border-color)',
                                                    paddingTop: '4px',
                                                    marginTop: '4px'
                                                }}
                                            >
                                                Cumulative: {formatValue(payload.find(p => p.dataKey === 'cumulative_unstaked')?.value)} MON
                                            </p> */}
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Legend
                            verticalAlign="top"
                            height={36}
                            wrapperStyle={{ fontFamily: 'var(--app-font-family)' }}
                        />
                        {/* Stacked bars for each provider */}
                        {allProviders.map((key, index) => (
                            <Bar
                                key={key}
                                yAxisId="left"
                                dataKey={key}
                                name={getProviderDisplayName(key)}
                                stackId="providers"
                                fill={getColorForProvider(key, index)}
                                opacity={0.8}
                            />
                        ))}
                        {/* Uncomment to add the cumulative line
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="cumulative_unstaked"
                            name="Cumulative Amount"
                            stroke="#5c6ac4"
                            strokeWidth={2}
                            dot={{
                                r: 4,
                                fill: '#5c6ac4',
                                stroke: '#5c6ac4'
                            }}
                        /> */}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MonadLiquidUnstakingChart;