// TwapSummary.js
import React from 'react';
import './../../styles/twapSummary.css';

const TwapSummary = ({ activeTwaps }) => {
    const calculateTwapStats = () => {
        return activeTwaps.reduce((stats, twap) => {
            const value = typeof twap.value === 'string' ?
                parseFloat(twap.value.replace(/[$,K,M]/g, '')) : twap.value;

            // Multiply by 1000 if K, by 1000000 if M
            const multiplier = twap.value.toString().includes('K') ? 1000
                : twap.value.toString().includes('M') ? 1000000 : 1;

            const actualValue = value * multiplier;

            if (twap.side === 'BUY') {
                stats.buyTotal += actualValue;
                stats.buyCount++;
            } else {
                stats.sellTotal += actualValue;
                stats.sellCount++;
            }
            return stats;
        }, {
            buyTotal: 0,
            sellTotal: 0,
            buyCount: 0,
            sellCount: 0
        });
    };

    const formatValue = (value) => {
        if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(2)}M`;
        } else if (value >= 1000) {
            return `$${(value / 1000).toFixed(2)}K`;
        }
        return `$${value.toFixed(2)}`;
    };

    const stats = calculateTwapStats();
    const totalTwaps = stats.buyCount + stats.sellCount;

    return (
        <div className="twap-summary">
            {/* <div className="total-twaps">
                <div className="total-label">Total Active TWAPs</div>
                <div className="total-value">{totalTwaps}</div>
            </div> */}
            <div className="summary-grid">
                <div className="summary-item">
                    <div className="summary-header">
                        <div className="summary-label">Total Buy TWAPs</div>
                        <div className="count buy-count">{stats.buyCount}</div>
                    </div>
                    <div className="summary-value buy-value">
                        {formatValue(stats.buyTotal)}
                    </div>
                </div>
                <div className="summary-item">
                    <div className="summary-header">
                        <div className="summary-label">Total Sell TWAPs</div>
                        <div className="count sell-count">{stats.sellCount}</div>
                    </div>
                    <div className="summary-value sell-value">
                        {formatValue(stats.sellTotal)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwapSummary;