import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { ChevronDown, TrendingUp } from 'lucide-react';

const HolderStatsCard = ({ token, historicalData, currentHolderData }) => {
    const [holderData, setHolderData] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState('4h');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectOpen, setSelectOpen] = useState(false);

    const periods = [
        { key: '4h', label: '4 Hours', hours: 4 },
        { key: '12h', label: '12 Hours', hours: 12 },
        { key: '1d', label: '1 Day', hours: 24 },
        { key: '3d', label: '3 Days', hours: 72 },
        { key: '7d', label: '7 Days', hours: 168 }
    ];

    // Generate flat chart data when no historical data is available
    const generateNewTokenChartData = (currentHolders) => {
        const now = Math.floor(Date.now() / 1000);
        const points = [];

        for (let i = 0; i < 15; i++) {
            points.push({
                timestamp: now - (i * 900), // 15-minute intervals
                time: new Date((now - (i * 900)) * 1000).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }),
                holders: currentHolders,
                displayHolders: currentHolders,
                actualHolders: currentHolders
            });
        }
        return points;
    };

    // Helper function to generate chart points
    const generateChartPoints = (startTime, endTime, currentHolders, startHolders) => {
        const points = [];
        const timeStep = (endTime - startTime) / 14;
        const holderStep = (currentHolders - startHolders) / 14;

        // Find the range of values
        let minHolders = Math.min(currentHolders, startHolders);
        let maxHolders = Math.max(currentHolders, startHolders);
        const range = maxHolders - minHolders;

        // Set minimum display value to ensure bars are visible
        const minDisplayValue = minHolders - (range * 0.1); // 10% below minimum

        for (let i = 0; i < 15; i++) {
            const timestamp = Math.floor(startTime + (i * timeStep));
            const baseHolders = Math.floor(startHolders + (i * holderStep));
            // Add small random variations while maintaining minimum visibility
            const variation = i === 0 || i === 14 ? 0 : (Math.random() - 0.5) * (range * 0.02);
            const holders = Math.floor(baseHolders + variation);

            points.push({
                timestamp,
                time: new Date(timestamp * 1000).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }),
                holders,
                // Ensure displayed value is never below minDisplayValue
                displayHolders: Math.max(holders, minDisplayValue),
                actualHolders: holders // Keep actual value for tooltip
            });
        }
        return points;
    };


    useEffect(() => {
        const processData = () => {
            try {
                const now = Math.floor(Date.now() / 1000);
                setLoading(true);
                setError(null);

                if (!currentHolderData) {
                    throw new Error('Missing current holder data');
                }

                const currentHolders = currentHolderData.holdersCount;
                const isNewToken = !historicalData || !historicalData[token] || historicalData[token].length === 0;

                const changes = {};
                const chartData = {};

                // Handle new token case
                if (isNewToken) {
                    // Set neutral changes for all periods
                    periods.forEach(period => {
                        changes[period.key] = { change: currentHolders, percentage: '100.00' };
                        chartData[period.key] = generateNewTokenChartData(currentHolders);
                    });
                } else {
                    // Process historical data
                    const tokenHistory = historicalData[token] || [];
                    const sortedHistory = tokenHistory.sort((a, b) => b.lastUpdate - a.lastUpdate);
                    for (const period of periods) {
                        const hoursAgo = period.hours;
                        const targetTime = now - (hoursAgo * 3600);

                        // Find closest historical data point
                        const historicalPoint = sortedHistory.find(point =>
                            point.lastUpdate <= targetTime
                        ) || sortedHistory[sortedHistory.length - 1];

                        if (historicalPoint) {
                            const change = currentHolders - historicalPoint.holdersCount;
                            const percentage = ((change / historicalPoint.holdersCount) * 100).toFixed(2);
                            changes[period.key] = { change, percentage };

                            // Generate chart points
                            chartData[period.key] = generateChartPoints(
                                historicalPoint.lastUpdate,
                                now,
                                currentHolders,
                                historicalPoint.holdersCount
                            );
                        }
                    }
                }

                setHolderData({
                    currentHolders,
                    changes,
                    chartData,
                    isNewToken
                });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        processData();
    }, [token, historicalData, currentHolderData]);

    // Render loading state
    if (loading) {
        return (
            <div className="stHype_holder_stats_loading">
                <div className="stHype_holder_stats_token_name">{token}</div>
                <div className="stHype_holder_stats_loading_text">Gathering information...</div>
                <div className="stHype_holder_stats_loading_subtext">This may take up to 3 minutes</div>
                <div className="stHype_holder_stats_progress_bar">
                    <div className="stHype_holder_stats_progress_fill" style={{ width: "30%" }}></div>
                </div>
            </div>
        );
    }

    // Render error state
    if (error) {
        return (
            <div className="stHype_holder_stats_error">
                <div className="stHype_holder_stats_token_name">{token}</div>
                <div className="stHype_holder_stats_error_text">Failed to load holder data</div>
                <div className="stHype_holder_stats_error_subtext">{error}</div>
            </div>
        );
    }

    return (
        <div className="stHype_holder_stats_card">
            <div className="stHype_holder_stats_token_name">{token}</div>
            <div className="stHype_holder_stats_header">
                <div className="stHype_holder_stats_title">Holders</div>
                <div className="stHype_holder_stats_count">
                    {holderData?.currentHolders.toLocaleString()}
                </div>
            </div>

            <div className="stHype_holder_stats_periods">
                {periods.map(period => {
                    if (holderData?.isNewToken) {
                        return (
                            <div key={period.key} className="stHype_holder_stats_period">
                                <span className="stHype_holder_stats_period_label">{period.label}</span>
                                <span className="stHype_holder_stats_period_value new-token">
                                    <TrendingUp className="w-4 h-4 inline-block mr-1" />
                                    New Token
                                </span>
                            </div>
                        );
                    }

                    const stats = holderData?.changes[period.key];
                    const isZero = stats?.change === 0;
                    const isPositive = stats?.change > 0;
                    return (
                        <div key={period.key} className="stHype_holder_stats_period">
                            <span className="stHype_holder_stats_period_label">{period.label}</span>
                            <span className={`stHype_holder_stats_period_value ${isZero ? 'zero' : isPositive ? 'positive' : 'negative'}`}>
                                {isZero ? '0' : `${isPositive ? '+' : ''}${stats?.change}`}/
                                {isZero ? '0.00' : `${stats?.percentage}`}%
                            </span>
                        </div>
                    );
                })}
            </div>

            <div className="stHype_holder_stats_chart">
                <div className="stHype_holder_stats_period_select_container" onClick={() => setSelectOpen(!selectOpen)}>
                    <div className="stHype_holder_stats_period_selected">
                        {periods.find(p => p.key === selectedPeriod)?.label}
                        <ChevronDown className="stHype_holder_stats_select_arrow" />
                    </div>
                    {selectOpen && (
                        <div className="stHype_holder_stats_period_options">
                            {periods.map(period => (
                                <div
                                    key={period.key}
                                    className={`stHype_holder_stats_period_option ${selectedPeriod === period.key ? 'selected' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedPeriod(period.key);
                                        setSelectOpen(false);
                                    }}
                                >
                                    {period.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="stHype_holder_stats_chart_container">
                    {holderData?.isNewToken ? (
                        <div className="flex items-center justify-center h-full">
                            <div className="text-center text-gray-500">
                                <TrendingUp className="w-8 h-8 mx-auto mb-2" />
                                <div className="text-sm">Historical data not yet available</div>
                            </div>
                        </div>
                    ) : (
                        <ResponsiveContainer width="100%" height={120}>
                            <BarChart
                                data={holderData?.chartData[selectedPeriod] || []}
                                margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                            >
                                <XAxis dataKey="time" tick={false} axisLine={false} />
                                <YAxis domain={['auto', 'auto']} hide={true} />
                                <Bar
                                    dataKey="displayHolders"
                                    fill="#8990fd"
                                    radius={[2, 2, 0, 0]}
                                    minPointSize={2}
                                />
                                <Tooltip
                                    cursor={{ fill: 'rgba(137, 144, 253, 0.1)' }}
                                    content={({ active, payload }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <div className="stHype_holder_stats_chart_tooltip">
                                                    <div>{payload[0].payload.time}</div>
                                                    <div>
                                                        {payload[0].payload.actualHolders.toLocaleString()} holders
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HolderStatsCard;