import React, { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import "./../../../styles/stHype/staking/uniqueStakerOverTime.css";

const HolderHistoryChart = ({ historicalData, token }) => {
    const chartData = useMemo(() => {
        if (!historicalData || !historicalData[token]) return [];

        return historicalData[token]
            .sort((a, b) => a.lastUpdate - b.lastUpdate)
            .map(item => ({
                timestamp: item.lastUpdate,
                holders: item.holdersCount,
                date: new Date(item.lastUpdate * 1000).toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric'
                })
            }));
    }, [historicalData, token]);

    const formatYAxis = (value) => {
        if (value >= 1000) {
            return `${(value / 1000).toFixed(0)}k`;
        }
        return value;
    };

    return (
        <div className="stHype_holder_history_chart_container">
            <div className="stHype_holder_history_chart_header">
                <h2>{token} Holders</h2>
            </div>
            <div className="stHype_holder_history_chart_content">
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                    >
                        <defs>
                            <linearGradient id="holderColorGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8990fd" stopOpacity={0.3} />
                                <stop offset="95%" stopColor="#8990fd" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="date"
                            tick={{ fill: '#002475' }}
                            tickLine={{ stroke: '#002475' }}
                            axisLine={{ stroke: '#e1e5eb' }}
                        />
                        <YAxis
                            tickFormatter={formatYAxis}
                            tick={{ fill: '#002475' }}
                            tickLine={{ stroke: '#002475' }}
                            axisLine={{ stroke: '#e1e5eb' }}
                        />
                        <Tooltip
                            content={({ active, payload }) => {
                                if (active && payload && payload.length) {
                                    return (
                                        <div className="stHype_holder_history_chart_tooltip">
                                            <p className="stHype_holder_history_chart_tooltip_date">
                                                {new Date(payload[0].payload.timestamp * 1000).toLocaleDateString('en-US', {
                                                    month: 'long',
                                                    day: 'numeric',
                                                    year: 'numeric'
                                                })}
                                            </p>
                                            <p className="stHype_holder_history_chart_tooltip_value">
                                                {payload[0].value.toLocaleString()} holders
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="holders"
                            stroke="#8990fd"
                            strokeWidth={2}
                            fill="url(#holderColorGradient)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default HolderHistoryChart;