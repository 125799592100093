import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const MonadNetflowDashboard = ({ data }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const processedData = useMemo(() => {
        return data.daily_netflow_data.map(day => ({
            name: day.dateTime_utc.split(' ')[0],
            value: day.netflow,
            total_assets: day.total_assets
        }));
    }, [data]);

    const yAxisDomain = useMemo(() => {
        const maxAbs = Math.max(
            ...processedData.map(item => Math.abs(item.value))
        );
        const roundedMax = Math.ceil(maxAbs / 10000) * 10000;
        return [-roundedMax, roundedMax];
    }, [processedData]);

    const yAxisTicks = useMemo(() => {
        const max = yAxisDomain[1];
        const step = max / 4;
        return Array.from({ length: 9 }, (_, i) => -max + step * i);
    }, [yAxisDomain]);

    const formatValue = (value) => {
        return `${(value / 1000).toFixed(1)}K`;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const netflowValue = `${formatValue(data.value)} MON`;

            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    border: '1px solid #ddd',
                    fontFamily: 'Roboto Mono, monospace'
                }}>
                    <p style={{ margin: '0 0 5px 0' }}>{`Date: ${label}`}</p>
                    <p style={{
                        color: data.value >= 0 ? '#4CAF50' : '#d32f2f',
                        fontWeight: 'bold',
                        margin: '0'
                    }}>
                        {`Net Flow: ${netflowValue}`}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div style={{
            backgroundColor: 'white',
            padding: '5px',
            borderRadius: '8px',
            // boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
            border: '1px solid var(--border-color)'
        }}>
            <h2 style={{
                textAlign: 'center',
                fontFamily: 'Roboto Mono, monospace',
                marginBottom: '20px',
                fontSize: '16px',
            }}>
                Monad LST Daily Net Flows
            </h2>

            <div style={{ position: 'relative' }}>
                <ResponsiveContainer width="100%" height={390}>
                    <BarChart
                        data={processedData}
                        margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
                        style={{ fontFamily: 'Roboto Mono, monospace' }}
                        onMouseMove={(state) => {
                            if (state.isTooltipActive) {
                                setHoveredIndex(state.activeTooltipIndex);
                            } else {
                                setHoveredIndex(null);
                            }
                        }}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <CartesianGrid
                            strokeDasharray="10 10"
                            horizontal={true}
                            vertical={false}
                            stroke="#e0e0e0"
                        />
                        <XAxis
                            dataKey="name"
                            tick={{ fontSize: 10, fontFamily: 'Roboto Mono, monospace' }}
                            interval={0}
                            angle={-45}
                            textAnchor="end"
                            height={5}
                        />
                        <YAxis
                            tickFormatter={formatValue}
                            tick={{
                                fontSize: 10,
                                fontFamily: 'Roboto Mono, monospace'
                            }}
                            domain={yAxisDomain}
                            ticks={yAxisTicks}
                            label={{
                                value: 'Net Flow (MON)',
                                angle: -90,
                                position: 'insideLeft',
                                style: {
                                    textAnchor: 'middle',
                                    fontFamily: 'Roboto Mono, monospace',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    fill: '#666666'
                                },
                                offset: -25
                            }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="value">
                            {processedData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.value >= 0 ? "#4CAF50" : "#d32f2f"}
                                    fillOpacity={hoveredIndex === null || hoveredIndex === index ? 1 : 0.3}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div >
    );
};

export default MonadNetflowDashboard;