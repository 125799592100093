import React, { useState } from 'react';
import HolderStatsCard from './holderStatsCard';
import "./../../../styles/stHype/staking/tokenStakingPage.css";

const TokenStakingPage = ({ stakingData, historicalData, currentHolderData, token_name }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const totalPages = stakingData ? Math.ceil(stakingData.sortedHolders.length / itemsPerPage) : 0;
    const currentHolders = stakingData?.sortedHolders?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) || [];

    return (
        <div className="stHype_staking_container">
            <div className="stHype_staking_content">
                <div className="stHype_staking_card_section">
                    <HolderStatsCard
                        token={token_name}
                        historicalData={historicalData}
                        currentHolderData={currentHolderData}
                    />
                </div>

                <div className="stHype_staking_table_section">
                    <div className="stHype_staking_table_header">
                        <h2>Top 100 Stakers</h2>
                    </div>

                    <div className="stHype_staking_table_container">
                        <table className="stHype_staking_table">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Address</th>
                                    <th>Amount Staked</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentHolders.map((holder, index) => (
                                    <tr key={holder.address}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td className="stHype_staking_address">{holder.address}</td>
                                        <td>{holder.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="stHype_staking_pagination">
                            <button
                                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                                disabled={currentPage === 1}
                                className="stHype_staking_pagination_button"
                            >
                                Previous
                            </button>
                            <span className="stHype_staking_page_info">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                                disabled={currentPage === totalPages}
                                className="stHype_staking_pagination_button"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokenStakingPage;