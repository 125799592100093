import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts';

const MonadProviderNetflowChart = ({ data }) => {
  const [activeProviders, setActiveProviders] = useState(new Set());
  const [showAll, setShowAll] = useState(true);

  // Get unique providers from the data
  const getUniqueProviders = () => {
    if (!data) return [];

    const providers = new Map();

    Object.values(data).forEach(dayData => {
      dayData.forEach(provider => {
        if (provider.token_symbol && !providers.has(provider.token_symbol)) {
          providers.set(provider.token_symbol, {
            name: provider.name,
            token_symbol: provider.token_symbol
          });
        }
      });
    });

    return Array.from(providers.values());
  };

  // Process data for the chart
  const processData = () => {
    if (!data) return [];

    return Object.entries(data).map(([date, providers]) => {
      const entry = {
        date: formatDateShort(date)
      };

      // Add each provider's data
      providers.forEach(provider => {
        // Store the netflow value directly
        entry[provider.token_symbol] = provider.netflow;
      });

      // Add full date for tooltip
      entry.fullDate = date;

      return entry;
    });
  };

  const formatDateShort = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  };

  const formatFullDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const formatValue = (value) => {
    if (!value || value === 0) return '0';
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
    return value.toFixed(1);
  };

  // Provider colors
  const providerColors = {
    aprMON: '#FF6B6B', // Red
    stMON: '#4ECDC4',  // Teal
    gMON: '#FFB347',   // Orange
    shMON: '#9FE2BF'   // Light Green
  };

  // Toggle provider visibility
  const toggleProvider = (token_symbol) => {
    setActiveProviders(prev => {
      const newSet = new Set(prev);
      if (newSet.has(token_symbol)) {
        newSet.delete(token_symbol);
      } else {
        newSet.add(token_symbol);
      }
      return newSet;
    });
    setShowAll(false);
  };

  // Toggle all providers
  const toggleAllProviders = () => {
    setShowAll(true);
    setActiveProviders(new Set());
  };

  // Custom tooltip component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      const fullDate = payload[0]?.payload?.fullDate;
      const uniqueProviders = getUniqueProviders();

      return (
        <div className="netflow-tooltip">
          <div className="tooltip-header">
            {fullDate ? formatFullDate(fullDate) : label}
          </div>
          <div className="tooltip-content">
            {uniqueProviders.map((provider, index) => {
              // Find this provider in the payload
              const entry = payload.find(p => p.dataKey === provider.token_symbol);
              const value = entry ? entry.value : 0;
              const isPositive = value > 0;
              const color = providerColors[provider.token_symbol] || '#ccc';

              // Show all providers, even with zero values
              return (
                <div key={index} className="tooltip-item">
                  <span className="color-dot" style={{ backgroundColor: color }}></span>
                  <span className="provider-name">{provider.token_symbol}:</span>
                  <span className={`value ${isPositive ? 'positive' : value < 0 ? 'negative' : 'zero'}`}>
                    {isPositive ? '+' : value < 0 ? '' : ''}{formatValue(value)} MON
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  const uniqueProviders = getUniqueProviders();
  const chartData = processData();

  return (
    <div className="monad-netflow-chart">
      <div className="chart-header">
        <h3 className="chart-title">Daily Inflow & Outflow by Provider</h3>
      </div>

      <div className="legend-container">
        <button
          className={`select-all-btn ${showAll ? 'active' : ''}`}
          onClick={toggleAllProviders}
        >
          Select All
        </button>

        <div className="provider-list">
          {uniqueProviders.map(provider => (
            <div
              key={provider.token_symbol}
              className={`provider-item ${(!showAll && !activeProviders.has(provider.token_symbol)) ? 'inactive' : ''}`}
              onClick={() => toggleProvider(provider.token_symbol)}
            >
              <span
                className="color-indicator"
                style={{ backgroundColor: providerColors[provider.token_symbol] || '#ccc' }}
              ></span>
              <span className="provider-name">{provider.name}</span>
              <span className="provider-symbol">{provider.token_symbol}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="chart-area">
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 30, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 12 }}
            />
            <YAxis
              tickFormatter={formatValue}
              tick={{ fontSize: 12 }}
            />
            <ReferenceLine y={0} stroke="#000" />
            <Tooltip content={<CustomTooltip />} />

            {uniqueProviders.map(provider => (
              (showAll || activeProviders.has(provider.token_symbol)) && (
                <Bar
                  key={provider.token_symbol}
                  dataKey={provider.token_symbol}
                  name={provider.name}
                  fill={providerColors[provider.token_symbol] || '#ccc'}
                  stackId="stack"
                />
              )
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <style jsx>{`
        .monad-netflow-chart {
          font-family: var(--app-font-family);
          color: var(--text-color);
          background-color: var(--card-background-color);
          border: 1px solid var(--border-color);
          border-radius: 8px;
          overflow: hidden;
        }
        
        .chart-header {
          padding: 15px;
          border-bottom: 1px solid var(--border-color);
        }
        
        .chart-title {
          font-size: 16px;
          color: var(--text-color);
          margin: 0;
          text-align: center;
        }
        
        .legend-container {
          padding: 15px;
          border-bottom: 1px solid var(--border-color);
        }
        
        .select-all-btn {
          background-color: var(--primary-color);
          color: white;
          border: none;
          border-radius: 4px;
          padding: 6px 16px;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 10px;
          font-weight: bold;
        }
        
        .select-all-btn.active {
          opacity: 1;
        }
        
        .select-all-btn:not(.active) {
          opacity: 0.7;
        }
        
        .provider-list {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: flex-start;
        }
        
        .provider-item {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 4px 8px;
          border: 1px solid var(--border-color);
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
          transition: all 0.2s ease;
        }
        
        .provider-item:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        .provider-item.inactive {
          opacity: 0.5;
          background-color: #f5f5f5;
        }
        
        .color-indicator {
          width: 12px;
          height: 12px;
          border-radius: 2px;
        }
        
        .provider-name {
          font-weight: 500;
        }
        
        .provider-symbol {
          background-color: rgba(0, 36, 117, 0.1);
          padding: 2px 4px;
          border-radius: 3px;
          font-size: 10px;
          color: var(--primary-color);
        }
        
        .chart-area {
          padding: 20px;
        }
        
        .netflow-tooltip {
          background-color: white;
          border: 1px solid var(--border-color);
          border-radius: 4px;
          padding: 10px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
          min-width: 180px;
        }
        
        .tooltip-header {
          font-weight: bold;
          margin-bottom: 6px;
          padding-bottom: 6px;
          border-bottom: 1px solid #eee;
        }
        
        .tooltip-content {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
        
        .tooltip-item {
          display: flex;
          align-items: center;
          font-size: 12px;
        }
        
        .color-dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 6px;
        }
        
        .provider-name {
          margin-right: 4px;
        }
        
        .value {
          font-weight: bold;
          margin-left: auto;
        }
        
        .value.positive {
          color: #2E8B57;
        }
        
        .value.negative {
          color: #FF6B6B;
        }
        
        .value.zero {
          color: #888;
        }
        
        @media (max-width: 768px) {
          .provider-list {
            justify-content: center;
          }
          
          .chart-area {
            padding: 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default MonadProviderNetflowChart;