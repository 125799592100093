// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stHype_staking_dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    font-family: 'Roboto Mono', monospace;
}

.stHype_staking_chart_section {
    margin-top: 24px;
    padding: 0 20px;
}

.stHype_staking_charts_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal columns */
    gap: 24px;
    /* margin-top: 24px; */
    padding: 0 20px;
}

.stHype_staking_chart_left,
.stHype_staking_chart_right {
    width: 100%;
    min-width: 0;
    /* Prevents grid item overflow */
}

/* Responsive adjustment for mobile */
@media (max-width: 1024px) {
    .stHype_staking_charts_grid {
        grid-template-columns: 1fr;
        /* Stack charts on smaller screens */
        gap: 16px;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dashboard {
        padding: 10px;
    }

    .stHype_staking_chart_section {
        padding: 0 10px;
        margin-top: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/stHype/staking/stHypeStakingComponent.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,qCAAqC;AACzC;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,SAAS;IACT,sBAAsB;IACtB,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,gCAAgC;AACpC;;AAEA,qCAAqC;AACrC;IACI;QACI,0BAA0B;QAC1B,oCAAoC;QACpC,SAAS;IACb;AACJ;;AAEA,2BAA2B;AAC3B;IACI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".stHype_staking_dashboard {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: white;\n    font-family: 'Roboto Mono', monospace;\n}\n\n.stHype_staking_chart_section {\n    margin-top: 24px;\n    padding: 0 20px;\n}\n\n.stHype_staking_charts_grid {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    /* Two equal columns */\n    gap: 24px;\n    /* margin-top: 24px; */\n    padding: 0 20px;\n}\n\n.stHype_staking_chart_left,\n.stHype_staking_chart_right {\n    width: 100%;\n    min-width: 0;\n    /* Prevents grid item overflow */\n}\n\n/* Responsive adjustment for mobile */\n@media (max-width: 1024px) {\n    .stHype_staking_charts_grid {\n        grid-template-columns: 1fr;\n        /* Stack charts on smaller screens */\n        gap: 16px;\n    }\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n    .dashboard {\n        padding: 10px;\n    }\n\n    .stHype_staking_chart_section {\n        padding: 0 10px;\n        margin-top: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
