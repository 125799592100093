import React, { useState, useEffect } from 'react';
import { Plus, X } from 'lucide-react';
import HolderStatsCard from './holderStatsCard';
import "./../../styles/tokenCardSection.css";

const TokenSelector = ({ onSelect, onClose, tokens }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTokens, setSelectedTokens] = useState([]);

    const filteredTokens = tokens.filter(token =>
        token.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="token-selector">
            <div className="token-selector-header">
                <h3>Add Token</h3>
                <button onClick={onClose} className="close-button">
                    <X size={20} />
                </button>
            </div>
            <div className="search-container_card">
                <input
                    type="text"
                    placeholder="Search tokens..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input_card"
                />
            </div>
            <div className="token-list">
                {filteredTokens.map(token => (
                    <div
                        key={token}
                        className="token-list-item"
                        onClick={() => {
                            onSelect(token);
                            onClose();
                        }}
                    >
                        {token}
                    </div>
                ))}
            </div>
        </div>
    );
};

const TokenCardsSection = ({ holderStats }) => {
    // const [selectedTokens, setSelectedTokens] = useState(['PURR', 'USDC']);
    const [selectedTokens, setSelectedTokens] = useState(() => {
        const saved = localStorage.getItem('selectedTokens');
        return saved ? JSON.parse(saved) : ['PURR', 'USDC'];
    });

    const [isAddingCard, setIsAddingCard] = useState(false);
    const [historicalData, setHistoricalData] = useState({});
    const [availableTokens, setAvailableTokens] = useState([]);

    // Set up available tokens from holderStats
    useEffect(() => {
        if (holderStats) {
            const tokens = Object.keys(holderStats);
            setAvailableTokens(tokens);
        }
    }, [holderStats]);

    // Save selected tokens to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('selectedTokens', JSON.stringify(selectedTokens));
    }, [selectedTokens]);

    const handleAddToken = (token) => {
        if (!selectedTokens.includes(token)) {
            const newSelectedTokens = [...selectedTokens, token];
            setSelectedTokens(newSelectedTokens);
            // localStorage is updated automatically via the useEffect
        }
        setIsAddingCard(false);
    };

    const handleRemoveToken = (tokenToRemove) => {
        const newSelectedTokens = selectedTokens.filter(token => token !== tokenToRemove);
        setSelectedTokens(newSelectedTokens);
        // localStorage is updated automatically via the useEffect
    };

    return (
        <div className="token-cards-section">
            <div className="token-cards-grid">
                {selectedTokens.map(token => (
                    <div key={token} className="token-card-wrapper">
                        <button
                            className="remove-token-button"
                            onClick={() => handleRemoveToken(token)}
                        >
                            <X size={16} />
                        </button>
                        <HolderStatsCard
                            token={token}
                            historicalData={holderStats}
                        />
                    </div>
                ))}
                <div className="add-card-container">
                    {isAddingCard ? (
                        <TokenSelector
                            onSelect={handleAddToken}
                            onClose={() => setIsAddingCard(false)}
                            tokens={availableTokens.filter(token => !selectedTokens.includes(token))}
                        />
                    ) : (
                        <button
                            className="add-card-button"
                            onClick={() => setIsAddingCard(true)}
                        >
                            <Plus size={24} />
                            <span>Add Token</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TokenCardsSection;