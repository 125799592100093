// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Libre+Baskerville&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* --app-font-family: 'Roboto Mono', Monaco, monospace; */

:root {
  /* Move these variables to a separate theme file if you want to switch themes */
  --primary-color: #002475;
  --background-color: #f0f0f0;
  --text-color: #333;
  --border-color: #002475;
  --card-background-color: white;
  --logo-font-family: 'Libre Baskerville', serif;
  --app-font-family: 'Roboto Mono', monospace;
}


body,
input,
button,
select,
textarea {
  font-family: var(--app-font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  background-color: white;
}

code {
  font-family: var(--app-font-family) !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA,yDAAyD;;AAEzD;EACE,+EAA+E;EAC/E,wBAAwB;EACxB,2BAA2B;EAC3B,kBAAkB;EAClB,uBAAuB;EACvB,8BAA8B;EAC9B,8CAA8C;EAC9C,2CAA2C;AAC7C;;;AAGA;;;;;EAKE,8CAA8C;EAC9C,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Libre+Baskerville&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');\n\n/* --app-font-family: 'Roboto Mono', Monaco, monospace; */\n\n:root {\n  /* Move these variables to a separate theme file if you want to switch themes */\n  --primary-color: #002475;\n  --background-color: #f0f0f0;\n  --text-color: #333;\n  --border-color: #002475;\n  --card-background-color: white;\n  --logo-font-family: 'Libre Baskerville', serif;\n  --app-font-family: 'Roboto Mono', monospace;\n}\n\n\nbody,\ninput,\nbutton,\nselect,\ntextarea {\n  font-family: var(--app-font-family) !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n#root {\n  min-height: 100vh;\n  background-color: white;\n}\n\ncode {\n  font-family: var(--app-font-family) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
