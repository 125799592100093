import React, { useState, useEffect } from 'react';
import { ArrowUpCircle, ArrowDownCircle, Search, ArrowUpDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import "./../styles/holder_trends.css";

const calculateHolderChanges = (data) => {
    if (!data || !Array.length) return null;

    const sortedData = data.sort((a, b) => b.lastUpdate - a.lastUpdate);
    const current = sortedData[0];
    const oneDayAgo = sortedData.find(entry =>
        entry.lastUpdate <= current.lastUpdate - 24 * 60 * 60
    ) || sortedData[sortedData.length - 1];

    const change = current.holdersCount - oneDayAgo.holdersCount;
    const percentChange = ((change / oneDayAgo.holdersCount) * 100).toFixed(2);

    return {
        current: current.holdersCount,
        change,
        percentChange: Number(percentChange),
    };
};

const TokenRow = ({ token, data }) => {
    const stats = calculateHolderChanges(data);
    const isPositive = stats?.change > 0;
    const isZero = stats?.change === 0;

    return (
        <div className="token-row">
            <div className="token-name">{token}</div>
            <div className="token-holders">{stats?.current.toLocaleString()}</div>
            <div className={`token-change ${isPositive ? 'positive' : isZero ? 'zero' : 'negative'}`}>
                {isZero ? '0' : (isPositive ? '+' : '') + stats?.change}
            </div>
            <div className={`token-percent ${isPositive ? 'positive' : isZero ? 'zero' : 'negative'}`}>
                {isZero ? '0%' : `${isPositive ? '+' : ''}${stats?.percentChange}%`}
            </div>
        </div>
    );
};
const SortableHeader = ({ column, currentSort, onSort }) => {
    const isActive = currentSort.column === column;

    return (
        <div
            className={`table-header-cell ${isActive ? 'active' : ''}`}
            onClick={() => onSort(column)}
        >
            <div className="header-content">
                <span>{column}</span>
                <ArrowUpDown
                    className={`sort-icon ${isActive ? 'visible' : ''}`}
                    size={16}
                />
            </div>
        </div>
    );
};

const HolderTrends = () => {
    const [holderStats, setHolderStats] = useState(null);
    const [sortedTokens, setSortedTokens] = useState({ gainers: [], losers: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalTokens, setTotalTokens] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [sort, setSort] = useState({
        column: '24h %',
        direction: 'desc'
    });

    const handleSort = (column) => {
        setSort(prevSort => ({
            column,
            direction: prevSort.column === column && prevSort.direction === 'desc' ? 'asc' : 'desc'
        }));
    };

    const sortData = (data) => {
        return [...data].sort((a, b) => {
            const statsA = calculateHolderChanges(holderStats[a.token]);
            const statsB = calculateHolderChanges(holderStats[b.token]);

            let compareA, compareB;

            switch (sort.column) {
                case 'Token':
                    compareA = a.token;
                    compareB = b.token;
                    break;
                case 'Holders':
                    compareA = statsA.current;
                    compareB = statsB.current;
                    break;
                case '24h':
                    compareA = statsA.change;
                    compareB = statsB.change;
                    break;
                case '24h %':
                    compareA = statsA.percentChange;
                    compareB = statsB.percentChange;
                    break;
                default:
                    return 0;
            }

            if (sort.direction === 'asc') {
                return compareA > compareB ? 1 : -1;
            } else {
                return compareA < compareB ? 1 : -1;
            }
        });
    };

    const filterTokens = (tokens) => {
        if (!searchTerm) return tokens;

        return tokens.filter(({ token }) =>
            token.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api-data.asxn.xyz/api/proxy/holdersStats');
                if (!response.ok) throw new Error('Failed to fetch data');
                const data = await response.json();
                setHolderStats(data);

                const tokenStats = Object.entries(data)
                    .map(([token, holderData]) => {
                        const stats = calculateHolderChanges(holderData);
                        return stats ? {
                            token,
                            ...stats
                        } : null;
                    })
                    .filter(stat => stat !== null);

                const gainers = tokenStats.filter(t => t.percentChange >= 0);
                const losers = tokenStats.filter(t => t.percentChange < 0);

                setTotalTokens(tokenStats.length);
                setSortedTokens({ gainers, losers });
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return <div className="loading-spinner" />;
    }

    if (error) {
        return <div className="error-message">Error loading data: {error}</div>;
    }

    const filteredGainers = filterTokens(sortData(sortedTokens.gainers));
    const filteredLosers = filterTokens(sortData(sortedTokens.losers));

    return (
        <div className="dashboard">
            <header className="dashboard-header">
                <Link to="/" className="logo-link">
                    <h1 className="logo asxn-logo">ASXN Data</h1>
                </Link>
            </header>

            <div className="holder-trends">
                <h1>Holder Trends</h1>

                <div className="controls-container">
                    <div className="stats-summary">
                        Total Tokens: {totalTokens} (↑{sortedTokens.gainers.length} ↓{sortedTokens.losers.length})
                    </div>
                    <div className="search-container">
                        {/* <Search size={20} className="search-icon" /> */}
                        <input
                            type="text"
                            placeholder="Search tokens..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                </div>

                <div className="trends-container">
                    <div>
                        <h2 className="section-title">
                            Gainers ({filteredGainers.length})
                        </h2>
                        <div className="trends-card">
                            <div className="table-header">
                                <SortableHeader column="Token" currentSort={sort} onSort={handleSort} />
                                <SortableHeader column="Holders" currentSort={sort} onSort={handleSort} />
                                <SortableHeader column="24h" currentSort={sort} onSort={handleSort} />
                                <SortableHeader column="24h %" currentSort={sort} onSort={handleSort} />
                            </div>
                            {filteredGainers.length > 0 ? (
                                filteredGainers.map(({ token }) => (
                                    <TokenRow
                                        key={token}
                                        token={token}
                                        data={holderStats[token]}
                                    />
                                ))
                            ) : (
                                <div className="no-results">No tokens found matching "{searchTerm}"</div>
                            )}
                        </div>
                    </div>

                    <div>
                        <h2 className="section-title">
                            Losers ({filteredLosers.length})
                        </h2>
                        <div className="trends-card">
                            <div className="table-header">
                                <SortableHeader column="Token" currentSort={sort} onSort={handleSort} />
                                <SortableHeader column="Holders" currentSort={sort} onSort={handleSort} />
                                <SortableHeader column="24h" currentSort={sort} onSort={handleSort} />
                                <SortableHeader column="24h %" currentSort={sort} onSort={handleSort} />
                            </div>
                            {filteredLosers.length > 0 ? (
                                filteredLosers.map(({ token }) => (
                                    <TokenRow
                                        key={token}
                                        token={token}
                                        data={holderStats[token]}
                                    />
                                ))
                            ) : (
                                <div className="no-results">No tokens found matching "{searchTerm}"</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />

            <div className="data-source">Source: Hyperliquid & Hypurrscan</div>
        </div>
    );
};

export default HolderTrends;