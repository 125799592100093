import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown, Copy, Check } from 'lucide-react';
import "./../../styles/monad_liquid_staking/MonadLSTTable.css"

const MonadLSTTable = ({ data }) => {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({
        key: 'name',
        direction: 'asc'
    });
    const [copiedAddress, setCopiedAddress] = useState(null);

    useEffect(() => {
        const sortedData = [...data.latest_provider_summary].sort((a, b) =>
            a.name.localeCompare(b.name)
        );
        setTableData(sortedData);
        setLoading(false);
    }, [data]);

    const handleCopy = async (address) => {
        try {
            await navigator.clipboard.writeText(address);
            setCopiedAddress(address);
            setTimeout(() => setCopiedAddress(null), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const formatAddress = (address) => {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(4)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(4)}K`;
        return value.toFixed(4);
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key) {
            direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ key, direction });
    };

    const sortData = (data, sortConfig) => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            let aValue, bValue;
            let multiplier = sortConfig.direction === 'asc' ? 1 : -1;

            switch (sortConfig.key) {
                case 'total_staked':
                    aValue = parseFloat(a.total_staked);
                    bValue = parseFloat(b.total_staked);
                    break;
                case 'name':
                    return a.name.localeCompare(b.name) * multiplier;
                case 'token_symbol':
                    return a.token_symbol.localeCompare(b.token_symbol) * multiplier;
                case 'address':
                    return a.address.localeCompare(b.address) * multiplier;
                default:
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
            }

            if (aValue < bValue) return -1 * multiplier;
            if (aValue > bValue) return 1 * multiplier;
            return a.name.localeCompare(b.name);
        });
    };

    const CopyButton = ({ address }) => (
        <button
            onClick={() => handleCopy(address)}
            className={`monad_lst_copy_button ${copiedAddress === address ? 'copied' : ''}`}
        >
            {copiedAddress === address ? <Check size={16} /> : <Copy size={16} />}
        </button>
    );

    const SortIcon = ({ columnKey }) => (
        <span className={`monad_lst_sort_icon ${sortConfig.key === columnKey ? sortConfig.direction : ''}`}>
            <div className="monad_lst_sort_arrows">
                <ChevronUp className="up" size={14} />
                <ChevronDown className="down" size={14} />
            </div>
        </span>
    );

    const columns = [
        { key: 'name', topLine: 'Provider', bottomLine: 'Name' },
        { key: 'token_symbol', topLine: 'Symbol', bottomLine: '' },
        { key: 'total_staked', topLine: 'Total', bottomLine: 'Staked' },
        { key: 'address', topLine: 'Contract', bottomLine: 'Address' },
        { key: 'apr', topLine: 'APR', bottomLine: '' }
    ];

    if (loading) return <div className="monad_lst_loader_card">Loading data...</div>;

    const sortedData = sortData(tableData, sortConfig);

    return (
        <div className="monad_lst_dashboard_container">
            <div className="monad_lst_table_wrapper">
                <table className="monad_lst_table">
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    key={column.key}
                                    onClick={() => requestSort(column.key)}
                                    className="monad_lst_header_cell"
                                >
                                    <div className="monad_lst_header_content">
                                        <div className="monad_lst_header_text">
                                            <span>{column.topLine}</span>
                                            {column.bottomLine && (
                                                <span className="bottom-line">{column.bottomLine}</span>
                                            )}
                                        </div>
                                        <SortIcon columnKey={column.key} />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item) => (
                            <tr key={item.address}>
                                <td>{item.name}</td>
                                <td>
                                    <div className="monad_lst_symbol_cell">
                                        {item.token_symbol}
                                    </div>
                                </td>
                                <td>
                                    <span>{formatValue(item.total_staked)}</span>
                                </td>
                                <td>
                                    <div className="monad_lst_address_cell">
                                        <span>{formatAddress(item.address)}</span>
                                        <CopyButton address={item.address} />
                                    </div>
                                </td>
                                <td>
                                    <span className="monad_lst_coming_soon">Coming Soon</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MonadLSTTable;