import React, { useState, useEffect } from 'react';
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { formatChartValue } from './Dashboard';
import '../styles/HypeBurn.css';

import api_url from "./../config/api_url.json";

const base_api_url = api_url.backend_api;

const COLORS = ['#4147B8', '#5C64F5', '#002475'];

const TimePeriodSelector = ({ value, onChange, className }) => (
  <div className={className}>
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="time-period-selector"
    >
      <option value="24h">24h</option>
      <option value="7d">7d</option>
      <option value="allTime">All Time</option>
    </select>
  </div>
);

const HypeBurnDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [burnPeriod, setBurnPeriod] = useState('24h');
  const [supplyPeriod, setSupplyPeriod] = useState('24h');
  const [volumePeriod, setVolumePeriod] = useState('24h');

  // First, create separate states for each card
  const [totalBurnPeriod, setTotalBurnPeriod] = useState('24h');
  const [annualizedBurnPeriod, setAnnualizedBurnPeriod] = useState('24h');
  const [percentSupplyPeriod, setPercentSupplyPeriod] = useState('24h');
  const [annualizedPercentPeriod, setAnnualizedPercentPeriod] = useState('24h');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_api_url}/api/hype-burn/metrics`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const getMetricValue = (metricType, period) => {
    if (!data) return 0;
    const metrics = data.metrics;

    switch (metricType) {
      case 'totalBurn':
        return period === '24h' ? metrics.burn24h :
          period === '7d' ? metrics.burn7d :
            metrics.burnAllTime;

      case 'annualizedBurn':
        return period === '24h' ? metrics.annualizedBurn24h :
          period === '7d' ? metrics.annualizedBurn7d :
            metrics.annualizedBurnAllTime;

      case 'percentSupply':
        return period === '24h' ? metrics.percentBurn24h :
          period === '7d' ? metrics.percentBurn7d :
            metrics.percentBurnAllTime;

      case 'annualizedPercent':
        return period === '24h' ? metrics.annualBurnPercent24h :
          period === '7d' ? metrics.annualBurnPercent7d :
            metrics.annualBurnPercentAllTime;

      default:
        return 0;
    }
  };

  const formatLargeNumber = (value) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }
    return value.toFixed(1);
  };

  const formatValue = (value) => {
    if (typeof value !== 'number') return 'N/A';
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
    return value.toFixed(1);
  };

  const getBurnMetrics = (period) => {
    if (!data) return { burn: 0, annualizedBurn: 0, percentage: 0 };
    const metrics = data.metrics;
    switch (period) {
      case '24h': return {
        burn: metrics.burn24h,
        annualizedBurn: metrics.annualizedBurn24h,
        percentage: metrics.percentBurn24h,
        annualPercentage: metrics.annualBurnPercent24h
      };
      case '7d': return {
        burn: metrics.burn7d,
        annualizedBurn: metrics.annualizedBurn7d,
        percentage: metrics.percentBurn7d,
        annualPercentage: metrics.annualBurnPercent7d
      };
      case 'allTime': return {
        burn: metrics.burnAllTime,
        annualizedBurn: metrics.annualizedBurnAllTime,
        percentage: metrics.percentBurnAllTime,
        annualPercentage: metrics.annualBurnPercentAllTime
      };
      default: return {
        burn: metrics.burn24h,
        annualizedBurn: metrics.annualizedBurn24h,
        percentage: metrics.percentBurn24h,
        annualPercentage: metrics.annualBurnPercent24h
      };
    }
  };

  const formatChartDate = (date) => {
    try {
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    } catch (error) {
      return date;
    }
  };

  // Add these helper functions
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };

  // Function to determine which ticks to show
  const getTicksToShow = (data) => {
    if (data.length <= 5) return data.length - 1; // Show all dates for small datasets
    return 4; // Show only 4 ticks for larger datasets
  };

  if (loading) return <div className="dashboard"><div className="loading">Loading data...</div></div>;
  if (error) return <div className="dashboard"><div className="error">Error: {error}</div></div>;
  if (!data) return null;

  const burnMetrics = getBurnMetrics(burnPeriod);

  const dailyBurnData = Object.entries(data.daily_burned).map(([date, value]) => ({
    date,
    burnedSupply: value
  }));

  const cumulativeBurnData = Object.entries(data.cumulative_daily_burned).map(([date, value]) => ({
    date,
    burnedSupply: value
  }));

  const volumeData = Object.entries(data.hype_vol.daily_hype_vol).map(([date, value]) => ({
    date,
    dailyVolume: value
  }));

  return (
    <div className="dashboard">
      <h2 className="dashboard-title">HYPE Token Burn Analytics</h2>

      <div className="charts-container">
        {/* Stats Section - First Row */}
        <div className="stats-section">
          <div className="stats-row">
            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-label">HYPE Burned</div>
                <TimePeriodSelector
                  value={totalBurnPeriod}
                  onChange={setTotalBurnPeriod}
                  className="time-selector"
                />
              </div>
              <div className="stat-value">
                {formatValue(getMetricValue('totalBurn', totalBurnPeriod))}
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-label">Annualised HYPE Burn</div>
                <TimePeriodSelector
                  value={annualizedBurnPeriod}
                  onChange={setAnnualizedBurnPeriod}
                  className="time-selector"
                />
              </div>
              <div className="stat-value">
                {formatValue(getMetricValue('annualizedBurn', annualizedBurnPeriod))}
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-label">% of Total Supply Burned</div>
                <TimePeriodSelector
                  value={percentSupplyPeriod}
                  onChange={setPercentSupplyPeriod}
                  className="time-selector"
                />
              </div>
              <div className="stat-value">
                {getMetricValue('percentSupply', percentSupplyPeriod).toFixed(4)}%
              </div>
            </div>
          </div>

          <div className="stats-row">
            <div className="stat-card">
              <div className="stat-header">
                <div className="stat-label">Annualized Burn Percentage</div>
                <TimePeriodSelector
                  value={annualizedPercentPeriod}
                  onChange={setAnnualizedPercentPeriod}
                  className="time-selector"
                />
              </div>
              <div className="stat-value">
                {getMetricValue('annualizedPercent', annualizedPercentPeriod).toFixed(2)}%
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-label">Daily Burn Amount</div>
              <div className="stat-value">{formatValue(data.metrics.dailyBurnAmount)}</div>
            </div>

            {/* <div className="stat-card">
              <div className="stat-label">Burn Rate (30d avg)</div>
              <div className="stat-value">{formatValue(data.metrics.burnRate30dAvg)}</div>
            </div> */}
            <div className="stat-card">
              <div className="stat-label">Current Supply</div>
              <div className="stat-value">{formatValue(data.metrics.todayTotalSupply)}</div>
            </div>
          </div>
        </div>

        {/* Supply Distribution */}
        <div className="charts-row">
          <div className="chart-column">
            <h3 className="chart-title">HYPE Supply Distribution</h3>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={[
                    {
                      name: 'Circulating Supply',
                      value: data.HYPESupplyDistribution.circulatingSupply.value
                    },
                    {
                      name: 'Non-Circulating Supply',
                      value: data.HYPESupplyDistribution.nonCirculatingSupply.value
                    },
                    {
                      name: 'Burned Supply',
                      value: data.HYPESupplyDistribution.totalBurn.value
                    }
                  ]}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  outerRadius={150}
                  fill="#002475"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(1)}%`}
                >
                  {COLORS.map((color, index) => (
                    <Cell key={`cell-${index}`} fill={color} />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value) => formatLargeNumber(value)}
                  content={({ active, payload }) => {
                    if (active && payload?.[0]) {
                      return (
                        <div className="custom-tooltip">
                          <p><strong>{payload[0].name}</strong></p>
                          <p>{formatLargeNumber(payload[0].value)}</p>
                          {/* <p>{(payload[0].percent).toFixed(1)}%</p> */}
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Burn Charts */}
        <div className="charts-row">
          <div className="chart-column">
            <h3 className="chart-title">Daily HYPE Burned</h3>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={dailyBurnData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                  tick={{ fontSize: 12 }}
                  tickMargin={20}
                  minTickGap={5}
                />
                <YAxis
                  tickFormatter={formatValue}
                  domain={[0, 'auto']}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="custom-tooltip">
                          <p><strong>{formatDate(label)}</strong></p>
                          <p>Burned: {formatValue(payload[0].value)}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Bar dataKey="burnedSupply" fill="#002475" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="chart-column">
            <h3 className="chart-title">Cumulative HYPE Burned</h3>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={cumulativeBurnData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                  tick={{ fontSize: 12 }}
                  tickMargin={20}
                  minTickGap={5}
                />
                <YAxis
                  tickFormatter={formatValue}
                  domain={[0, 'auto']}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="custom-tooltip">
                          <p><strong>{formatDate(label)}</strong></p>
                          <p>Total Burned: {formatValue(payload[0].value)}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="burnedSupply"
                  stroke="#002475"
                  fill="#002475"
                  fillOpacity={0.1}
                />
              </AreaChart>

            </ResponsiveContainer>
          </div>
        </div>

        {/* Volume Section */}
        <div className="charts-row">
          <div className="chart-column">
            <div className="stats-row">
              <div className="stat-card">
                <div className="stat-label">24h HYPE/USDC Volume</div>
                <div className="stat-value">${formatValue(data.hype_vol['24h_vol'])}</div>
              </div>
              <div className="stat-card">
                <div className="stat-label">7d Average HYPE/USDC Volume</div>
                <div className="stat-value">${formatValue(data.hype_vol['7d_avg_vol'])}</div>
              </div>
              <div className="stat-card">
                <div className="stat-label">30d Average HYPE/USDC Volume</div>
                <div className="stat-value">${formatValue(data.hype_vol['30d_avg_vol'])}</div>
              </div>
            </div>

            <h3 className="chart-title">Daily HYPE/USDC Volume</h3>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={volumeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={0}
                  tick={{ fontSize: 12 }}
                  tickMargin={20}
                  minTickGap={5}
                />
                <YAxis
                  tickFormatter={(value) => `$${formatValue(value)}`}
                  domain={[0, 'auto']}
                />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="custom-tooltip">
                          <p><strong>{formatDate(label)}</strong></p>
                          <p>Volume: ${formatValue(payload[0].value)}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Bar dataKey="dailyVolume" fill="#002475" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="data-source">Source: Hyperliquid</div>
    </div>
  );
};

export default HypeBurnDashboard;