import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import BTCETFDashboard from './components/BTCETFDashboard';
import ETHETFDashboard from './components/ETHETFDashboard';
import HLSpotEcosystemDashboard from './components/HLSpotEcosystemDashboard';
import HolderTrends from './components/holderTrends';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard/hl-spot-ecosystem" element={<HLSpotEcosystemDashboard />} />
          <Route path="/dashboard/spot-holder-trend" element={<HolderTrends />} />
          <Route path="/dashboard/btc-etf-flows" element={<BTCETFDashboard />} />
          <Route path="/dashboard/eth-etf-flows" element={<ETHETFDashboard />} />
          <Route path="/dashboard/:id" element={<Dashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
