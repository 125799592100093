import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const StakeDistributionPieChart = ({ stakingData }) => {
    const formatNumber = (value) => {
        if (!value && value !== 0) return '0';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}k`;
        return value.toFixed(1);
    };

    const distributionData = useMemo(() => {
        if (!stakingData?.holders) return [];

        const amounts = Object.values(stakingData.holders).map(v => v || 0.1);

        const ranges = [0.1];
        let currentValue = 1;
        while (currentValue <= Math.max(...amounts)) {
            ranges.push(currentValue);
            currentValue *= 4;
        }

        const distribution = ranges.slice(0, -1).map((rangeStart, index) => {
            const rangeEnd = ranges[index + 1];
            const count = Object.values(stakingData.holders).filter(amount =>
                amount >= rangeStart && amount < rangeEnd
            ).length;

            let name;
            if (rangeStart < 1) {
                name = '< 1';
            } else {
                name = `${formatNumber(rangeStart)}-${formatNumber(rangeEnd)}`;
            }

            return {
                name,
                value: count,
                rangeStart,
                rangeEnd
            };
        });

        return distribution;
    }, [stakingData]);

    const COLORS = [
        '#2E8B57',  // Sea Green
        '#FF6B6B',  // Coral Red
        '#4ECDC4',  // Turquoise
        '#FFB347',  // Pastel Orange
        '#9FE2BF',  // Seafoam Green
        '#845EC2',  // Purple
        '#FF9671',  // Peach
        '#FFC75F',  // Mellow Yellow
        '#F9F871',  // Light Yellow
        '#008B8B',  // Dark Cyan
        '#FF8066',  // Salmon
        '#64C4ED',  // Sky Blue
        '#88D8B0',  // Mint
        '#C06C84'   // Mauve
    ];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const totalWallets = distributionData.reduce((sum, item) => sum + item.value, 0);
            const sharePercentage = ((data.value / totalWallets) * 100).toFixed(1);

            return (
                <div className="stHype_stake_distribution_tooltip">
                    <p className="stHype_stake_distribution_tooltip_range">
                        {data.name} tokens
                    </p>
                    <p className="stHype_stake_distribution_tooltip_value">
                        Value: {formatNumber(data.value)} wallets
                    </p>
                    <p className="stHype_stake_distribution_tooltip_value">
                        Share: {sharePercentage}%
                    </p>
                </div>
            );
        }
        return null;
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = (innerRadius + outerRadius) / 2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        // Only show label if percentage is greater than 5%
        if (percent < 0.05) return null;

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="12"
                fontFamily="var(--app-font-family)"
            >
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };

    const CustomLegend = ({ payload }) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                fontSize: '12px',
                fontFamily: 'var(--app-font-family)'
            }}>
                {payload.map((entry, index) => (
                    <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: entry.color,
                            flexShrink: 0
                        }} />
                        <span>{entry.value}</span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="stHype_stake_distribution_container">
            <div className="stHype_stake_distribution_header">
                <h2>Distribution of Holders</h2>
            </div>
            <div className="stHype_stake_distribution_content">
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie
                            data={distributionData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={150} // Reduced from 200
                            innerRadius={60}  // Reduced from 80
                            labelLine={false}
                            label={renderCustomizedLabel}
                        >
                            {distributionData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend
                            content={<CustomLegend />}
                            layout="vertical"
                            align="right"
                            verticalAlign="middle"
                            wrapperStyle={{
                                paddingLeft: '20px'
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StakeDistributionPieChart;