import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const MonadLSTStakeDistribution = ({ data }) => {
    const COLORS = [
        '#2E8B57',  // Sea Green
        '#FF6B6B',  // Coral Red
        '#4ECDC4',  // Turquoise
        '#FFB347'   // Pastel Orange
    ];

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(4)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(4)}K`;
        return value.toFixed(4);
    };

    const totalStake = data.latest_provider_summary
        .reduce((sum, provider) => sum + provider.total_staked, 0);

    const pieData = data.latest_provider_summary
        .map(provider => ({
            name: provider.name,
            value: provider.total_staked,
            symbol: provider.token_symbol,
            percentage: (provider.total_staked / totalStake) * 100,
            displayValue: formatValue(provider.total_staked)
        }))
        .sort((a, b) => b.value - a.value);

    const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, value }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius * 1.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        // Only show labels for sections > 1%
        if (percent < 0.01) return null;

        const isRightSide = x > cx;
        const textAnchor = isRightSide ? 'start' : 'end';
        const labelX = isRightSide ? x + 5 : x - 5;

        const formattedValue = formatValue(value);
        const percentageFormatted = (percent * 100).toFixed(2);

        return (
            <g>
                <line
                    x1={cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN)}
                    y1={cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN)}
                    x2={x}
                    y2={y}
                    stroke="#002475"
                    strokeWidth={1}
                    opacity={0.5}
                />
                <text
                    x={labelX}
                    y={y - 8}
                    fill="#002475"
                    textAnchor={textAnchor}
                    fontSize={12}
                    fontFamily="var(--app-font-family)"
                >
                    {name}
                </text>
                <text
                    x={labelX}
                    y={y + 8}
                    fill="#002475"
                    textAnchor={textAnchor}
                    fontSize={12}
                    fontFamily="var(--app-font-family)"
                >
                    {/* {`${percentageFormatted}% (${formattedValue} MON)`} */}
                    {`${percentageFormatted}%`}

                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div style={{
                    backgroundColor: 'white',
                    padding: '12px',
                    border: '1px solid var(--border-color)',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <p style={{
                        color: 'var(--primary-color)',
                        fontWeight: '600',
                        margin: '0 0 8px 0'
                    }}>{data.name}</p>
                    <p style={{ margin: '4px 0' }}>
                        Symbol: {data.symbol}
                    </p>
                    <p style={{ margin: '4px 0' }}>
                        Stake: {data.displayValue} MON
                    </p>
                    <p style={{ margin: '4px 0' }}>
                        Share: {data.percentage.toFixed(2)}%
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomLegend = ({ payload }) => {
        const LegendItem = ({ entry, index }) => (
            <div key={`legend-${index}`} style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '15px',
                marginBottom: '5px',
                minWidth: '200px',
                maxWidth: '250px'
            }}>
                <div style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: entry.color,
                    marginRight: '6px',
                    borderRadius: '2px'
                }} />
                <span style={{
                    fontSize: '10px',
                    color: '#002475',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {entry.payload.name} ({entry.payload.percentage.toFixed(2)}%)
                </span>
            </div>
        );

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '10px'
            }}>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                    {payload.map((entry, index) => (
                        <LegendItem entry={entry} index={index} key={index} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div style={{
            backgroundColor: 'var(--card-background-color)',
            border: '1px solid var(--border-color)',
            borderRadius: '8px',
            padding: '20px'
        }}>
            <h3 style={{
                color: 'var(--primary-color)',
                fontSize: '20px',
                margin: '0 0 15px 0',
                textAlign: 'center',
                fontFamily: 'var(--app-font-family)',
                fontWeight: '500'
            }}>
                Monad LST Stake Distribution
            </h3>
            <div style={{ width: '100%', height: '400px', position: 'relative' }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={pieData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={CustomLabel}
                            outerRadius={75}
                            innerRadius={40}
                            dataKey="value"
                            paddingAngle={1}
                        >
                            {pieData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke="#FFFFFF"
                                    strokeWidth={1}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={CustomTooltip} />
                        <Legend
                            content={CustomLegend}
                            verticalAlign="bottom"
                            height={120}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MonadLSTStakeDistribution;