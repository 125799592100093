import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown, Star, Info } from 'lucide-react';
import './../../styles/hype_funding/funding_rate_table.css';

// import api_url from "./../../../config/api_url.json";
import api_url from "../../config/api_url.json";

const base_api_url = api_url.backend_api;

const HypeFundingRateTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [favorites, setFavorites] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({
        key: 'NotionalOpenInterest',
        direction: 'desc'
    });
    const [timeframe, setTimeframe] = useState('Hourly'); // Hourly, 8 Hours, Day, Week, Year
    const [showColorLegend, setShowColorLegend] = useState(false);

    // Get annualized rates based on timeframe
    const getAnnualizedRate = (rate, timeframe) => {
        if (!rate || rate === "") return "";
        const value = parseFloat(rate);

        switch (timeframe) {
            case 'Hourly':
                return value / 24 / 365;
            case '8 Hours':
                return value / 3 / 365;
            case 'Day':
                return value / 365;
            case 'Week':
                return value / 52;
            case 'Year':
                return value;
            default:
                return value / 24 / 365; // Default to hourly
        }
    };

    // Format the rates for display
    const formatRate = (rate, timeframe) => {
        if (!rate || rate === "") return "-";
        const annualizedRate = getAnnualizedRate(rate, timeframe);
        return `${annualizedRate.toFixed(4)}%`;
    };

    // Format the notional open interest (formatted like in the black image)
    const formatNotional = (value) => {
        if (!value) return "-";
        const numValue = parseFloat(value);
        return `$${numValue.toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
    };

    // Calculate arbitrage values based on the current timeframe
    const calculateArbitrage = (hl, exchange, timeframe) => {
        if (!hl || !exchange || hl === "" || exchange === "") return "-";

        const hlValue = parseFloat(hl);
        const exchangeValue = parseFloat(exchange);

        // Get the rates based on the current timeframe
        const annualizedHL = getAnnualizedRate(hlValue, timeframe);
        const annualizedExchange = getAnnualizedRate(exchangeValue, timeframe);

        const diff = annualizedExchange - annualizedHL;
        return `${diff.toFixed(4)}%`;
    };

    // Filter and Sort the data
    const sortedData = React.useMemo(() => {
        if (!data || data.length === 0) return [];

        // First filter by search term
        let filteredData = [...data];
        if (searchTerm.trim() !== '') {
            const searchLower = searchTerm.toLowerCase();
            filteredData = filteredData.filter(item =>
                item.coin.toLowerCase().includes(searchLower)
            );
        }

        // Then sort the filtered data
        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                // Handle null, undefined or empty string values
                if (!a[sortConfig.key] && b[sortConfig.key]) return 1;
                if (a[sortConfig.key] && !b[sortConfig.key]) return -1;
                if (!a[sortConfig.key] && !b[sortConfig.key]) return 0;

                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // For numeric columns, convert strings to numbers
                if (sortConfig.key === 'NotionalOpenInterest' ||
                    sortConfig.key === 'HL' ||
                    sortConfig.key === 'Binance' ||
                    sortConfig.key === 'Bybit') {
                    aValue = aValue === "" ? 0 : parseFloat(aValue);
                    bValue = bValue === "" ? 0 : parseFloat(bValue);
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        // Move favorites to the top
        if (favorites.length > 0) {
            filteredData.sort((a, b) => {
                const aFav = favorites.includes(a.coin);
                const bFav = favorites.includes(b.coin);
                if (aFav && !bFav) return -1;
                if (!aFav && bFav) return 1;
                return 0;
            });
        }

        return filteredData;
    }, [data, sortConfig, favorites, searchTerm]);

    // Toggle favorite status
    const toggleFavorite = (coin) => {
        if (favorites.includes(coin)) {
            setFavorites(favorites.filter(fav => fav !== coin));
        } else {
            setFavorites([...favorites, coin]);
        }
    };

    // Request sort
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key) {
            direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ key, direction });
    };

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${base_api_url}/api/data/hl-funding-rates`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const jsonData = await response.json();
                setData(jsonData);
                setError(null);
            } catch (err) {
                console.error('Error fetching funding rate data:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // Refresh data every 5 minutes
        const intervalId = setInterval(fetchData, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Get cell color class based on value
    const getCellColor = (value) => {
        if (!value || value === "" || value === "-") return "";

        const numValue = parseFloat(value);
        if (numValue > 0.01) return "hl_funding_rate_positive"; // Green: rate > 0.01%
        if (numValue < 0) return "hl_funding_rate_negative"; // Red: rate < 0%
        return "hl_funding_rate_neutral"; // Gray: 0% ≤ rate ≤ 0.01%
    };

    // SortIcon component
    const SortIcon = ({ columnKey }) => (
        <span className={`hl_funding_rate_sort_icon ${sortConfig.key === columnKey ? sortConfig.direction : ''}`}>
            <div className="hl_funding_rate_sort_arrows">
                <ChevronUp className="up" size={14} />
                <ChevronDown className="down" size={14} />
            </div>
        </span>
    );

    // Define table columns
    const columns = [
        { key: 'star', label: '', sortable: false },
        { key: 'coin', label: 'Coin', sortable: true },
        { key: 'NotionalOpenInterest', label: 'Hyperliquid OI', sortable: true },
        { key: 'HL', label: 'Hyperliquid', sortable: true },
        { key: 'Binance', label: 'Binance', sortable: true },
        { key: 'Bybit', label: 'Bybit', sortable: true },
        { key: 'binance_arb', label: 'Binance-HL Arb', sortable: true },
        { key: 'bybit_arb', label: 'Bybit-HL Arb', sortable: true }
    ];

    // Time period options
    const timePeriods = ['Hourly', '8 Hours', 'Day', 'Week', 'Year'];

    // Color legend component
    const ColorLegend = () => (
        <div className={`hl_funding_rate_color_legend ${showColorLegend ? 'show' : ''}`}>
            <div className="hl_funding_rate_legend_content">
                <div className="hl_funding_rate_legend_close" onClick={() => setShowColorLegend(false)}>✕</div>
                <div className="hl_funding_rate_legend_item">
                    <span className="hl_funding_rate_legend_color hl_funding_rate_negative"></span>
                    <span className="hl_funding_rate_legend_text">-∞ &lt; {timeframe} rate &lt; 0%</span>
                </div>
                <div className="hl_funding_rate_legend_item">
                    <span className="hl_funding_rate_legend_color hl_funding_rate_neutral"></span>
                    <span className="hl_funding_rate_legend_text">0% ≤ {timeframe} rate ≤ 0.01%</span>
                </div>
                <div className="hl_funding_rate_legend_item">
                    <span className="hl_funding_rate_legend_color hl_funding_rate_positive"></span>
                    <span className="hl_funding_rate_legend_text">0.01% &lt; {timeframe} rate &lt; ∞</span>
                </div>
            </div>
        </div>
    );

    if (loading) return <div className="hl_funding_rate_loader">Loading funding rate data...</div>;
    if (error) return <div className="hl_funding_rate_error">Error: {error}</div>;

    return (
        <div className="hl_funding_rate_dashBoard_head">
            <div className="hl_funding_rate_container">
                <div className="hl_funding_rate_search_row">
                    <div className="hl_funding_rate_search_container">
                        <input
                            type="text"
                            placeholder="Search by coin"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="hl_funding_rate_search_input"
                        />
                    </div>

                    <div className="hl_funding_rate_count_and_info">
                        <span className="hl_funding_rate_count">
                            Total Coins: {sortedData.length}
                        </span>
                        <button
                            className="hl_funding_rate_legend_button"
                            onClick={() => setShowColorLegend(!showColorLegend)}
                        >
                            <Info size={14} />
                        </button>
                    </div>

                    <div className="hl_funding_rate_timeframe_selector">
                        {timePeriods.map(period => (
                            <button
                                key={period}
                                className={`hl_funding_rate_timeframe_button ${timeframe === period ? 'active' : ''}`}
                                onClick={() => setTimeframe(period)}
                            >
                                {period}
                            </button>
                        ))}
                    </div>
                </div>

                {showColorLegend && <ColorLegend />}

                <div className="hl_funding_rate_table_wrapper">
                    <table className="hl_funding_rate_table">
                        <thead>
                            <tr>
                                {columns.map(column => (
                                    <th
                                        key={column.key}
                                        onClick={column.sortable ? () => requestSort(column.key) : undefined}
                                        className={`hl_funding_rate_header_cell ${!column.sortable ? 'not-sortable' : ''}`}
                                    >
                                        <div className="hl_funding_rate_header_content">
                                            <span>{column.label}</span>
                                            {column.sortable && <SortIcon columnKey={column.key} />}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => {
                                const binanceArb = calculateArbitrage(item.HL, item.Binance, timeframe);
                                const bybitArb = calculateArbitrage(item.HL, item.Bybit, timeframe);

                                return (
                                    <tr key={`${item.coin}-${index}`}>
                                        <td>
                                            <button
                                                className={`hl_funding_rate_star_button ${favorites.includes(item.coin) ? 'favorite' : ''}`}
                                                onClick={() => toggleFavorite(item.coin)}
                                            >
                                                <Star size={16} />
                                            </button>
                                        </td>
                                        <td>{item.coin}</td>
                                        <td>{formatNotional(item.NotionalOpenInterest)}</td>
                                        <td className={getCellColor(item.HL)}>{formatRate(item.HL, timeframe)}</td>
                                        <td className={getCellColor(item.Binance)}>{formatRate(item.Binance, timeframe)}</td>
                                        <td className={getCellColor(item.Bybit)}>{formatRate(item.Bybit, timeframe)}</td>
                                        <td className={getCellColor(binanceArb)}>{binanceArb}</td>
                                        <td className={getCellColor(bybitArb)}>{bybitArb}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="data-source">Source: Hyperliquid</div>
            <br />
        </div>
    );
};

export default HypeFundingRateTable;