import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../styles/ecosystem_dashboard.css';

import api_url from "./../config/api_url.json";

const base_api_url = api_url.backend_api;

// ProjectCard Component
const ProjectCard = ({
  project,
  activeFilters,
  onTypeClick,
  onNativeClick,
  onMonadMadnessClick,
  onAcceleratorClick,
  onJumpstartClick
}) => {
  return (
    <div className="monad_ecosystem_project-card">
      <div className="monad_ecosystem_banner-container">
        <div
          className={`monad_ecosystem_project-type clickable ${activeFilters.type === project.type ? 'active' : ''}`}
          onClick={() => onTypeClick(project.type)}
        >
          {project.type}
        </div>
        <img src={project.banner_url} alt="" className="monad_ecosystem_banner-image" />
      </div>

      <div className="monad_ecosystem_logo-and-links">
        <div className="monad_ecosystem_logo-container">
          <img src={project.logo_url} alt="" className="monad_ecosystem_logo-image" />
        </div>
        <div className="monad_ecosystem_social-links">
          {project.twitter && (
            <a href={project.twitter} className="monad_ecosystem_social-link" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          )}
          {project.website && (
            <a href={project.website} className="monad_ecosystem_social-link" target="_blank" rel="noopener noreferrer">
              Website
            </a>
          )}
          {project.social && (
            <a href={project.social} className="monad_ecosystem_social-link" target="_blank" rel="noopener noreferrer">
              Social
            </a>
          )}
        </div>
      </div>

      <div className="monad_ecosystem_project-info">
        <h3 className="monad_ecosystem_project-name">{project.name}</h3>
        <p className="monad_ecosystem_project-description">{project.description}</p>

        <div className="monad_ecosystem_project-tags">
          <div className="monad_ecosystem_tags-grid">
            {project.native?.toLowerCase() === 'yes' && (
              <div
                className={`monad_ecosystem_native-tag clickable ${activeFilters.native ? 'active' : ''}`}
                onClick={() => onNativeClick(!activeFilters.native)}
              >
                Monad First
              </div>
            )}
            {project.monad_madness?.toLowerCase() === 'yes' && (
              <div
                className={`monad_ecosystem_monad-madness-tag clickable ${activeFilters.monadMadness ? 'active' : ''}`}
                onClick={() => onMonadMadnessClick(!activeFilters.monadMadness)}
              >
                Monad Madness
              </div>
            )}
            {project.accelerator?.toLowerCase() === 'yes' && (
              <div
                className={`monad_ecosystem_accelerator-tag clickable ${activeFilters.accelerator ? 'active' : ''}`}
                onClick={() => onAcceleratorClick(!activeFilters.accelerator)}
              >
                Accelerator
              </div>
            )}
            {project.jumpstart?.toLowerCase() === 'yes' && (
              <div
                className={`monad_ecosystem_jumpstart-tag clickable ${activeFilters.jumpstart ? 'active' : ''}`}
                onClick={() => onJumpstartClick(!activeFilters.jumpstart)}
              >
                Jumpstart
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Main MonadEcosystem Component
const MonadEcosystem = () => {
  const { filterType } = useParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [activeFilters, setActiveFilters] = useState({
    type: filterType || null,
    native: false,
    monadMadness: false,
    accelerator: false,
    jumpstart: false
  });

  // Sync URL with type filter
  useEffect(() => {
    if (filterType) {
      setActiveFilters(prev => ({ ...prev, type: filterType }));
    }
  }, [filterType]);

  // Handle browser back button
  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname;
      const searchParams = new URLSearchParams(window.location.search);
      const match = currentPath.match(/\/dashboard\/monad-ecosystem\/(.*)/);
      const newType = match ? match[1] : null;

      setActiveFilters({
        type: newType,
        native: searchParams.get('native') === 'true',
        monadMadness: searchParams.get('monadMadness') === 'true',
        accelerator: searchParams.get('accelerator') === 'true',
        jumpstart: searchParams.get('jumpstart') === 'true'
      });
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${base_api_url}/api/data/monad-ecosystem`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setProjects(data);
      } catch (err) {
        console.error('Error details:', err);
        setError(`Failed to fetch projects: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Get unique types for dropdown and sort them alphabetically
  const types = ['all', ...new Set(projects.map(project => project.type))].sort((a, b) => {
    if (a === 'all') return -1;  // Keep 'all' at the beginning
    if (b === 'all') return 1;
    return a.localeCompare(b);
  });

  // Filter projects based on all active filters
  const filteredProjects = projects.filter(project => {
    if (activeFilters.type && project.type !== activeFilters.type) return false;
    if (activeFilters.native && project.native?.toLowerCase() !== 'yes') return false;
    if (activeFilters.monadMadness && project.monad_madness?.toLowerCase() !== 'yes') return false;
    if (activeFilters.accelerator && project.accelerator?.toLowerCase() !== 'yes') return false;
    if (activeFilters.jumpstart && project.jumpstart?.toLowerCase() !== 'yes') return false;
    return true;
  }).sort((a, b) => {
    if (sortBy === 'name') return a.name.localeCompare(b.name);
    if (sortBy === 'type') return a.type.localeCompare(b.type);
    return 0;
  });

  const handleTypeFilter = (type) => {
    if (activeFilters.type === type) {
      // If clicking the same type, clear it
      setActiveFilters(prev => ({ ...prev, type: null }));
      navigate('/dashboard/monad-ecosystem');
    } else {
      // Set new type
      setActiveFilters(prev => ({ ...prev, type }));
      navigate(`/dashboard/monad-ecosystem/${type}`);
    }
  };

  const handleNativeFilter = (isActive) => {
    const newState = { ...activeFilters, native: isActive };
    setActiveFilters(newState);
    updateURL(newState);
  };

  const handleMonadMadnessFilter = (isActive) => {
    const newState = { ...activeFilters, monadMadness: isActive };
    setActiveFilters(newState);
    updateURL(newState);
  };

  const handleAcceleratorFilter = (isActive) => {
    const newState = { ...activeFilters, accelerator: isActive };
    setActiveFilters(newState);
    updateURL(newState);
  };

  const handleJumpstartFilter = (isActive) => {
    const newState = { ...activeFilters, jumpstart: isActive };
    setActiveFilters(newState);
    updateURL(newState);
  };

  // New function to update URL with all active filters
  const updateURL = (filters) => {
    const params = new URLSearchParams();
    if (filters.type) params.append('type', filters.type);
    if (filters.native) params.append('native', 'true');
    if (filters.monadMadness) params.append('monadMadness', 'true');
    if (filters.accelerator) params.append('accelerator', 'true');
    if (filters.jumpstart) params.append('jumpstart', 'true');

    const newPath = `/dashboard/monad-ecosystem${filters.type ? `/${filters.type}` : ''}`;
    const queryString = params.toString();
    navigate(`${newPath}${queryString ? `?${queryString}` : ''}`);
  };

  // Update clear all filters to use the new URL handling
  const clearAllFilters = () => {
    const newState = {
      type: null,
      native: false,
      monadMadness: false,
      accelerator: false,
      jumpstart: false
    };
    setActiveFilters(newState);
    navigate('/dashboard/monad-ecosystem');
  };

  if (loading) return <div>Loading monad ecosystem data...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="monad_ecosystem_ecosystem-dashboard">
      <Link
        to="/dashboard/monad-ecosystem"
        style={{ textDecoration: 'none', color: 'inherit' }}
        onClick={(e) => {
          e.preventDefault();
          clearAllFilters();
          navigate('/dashboard/monad-ecosystem');
        }}
      >
        <h1 className="monad_ecosystem_dashboard-title">
          Monad Ecosystem
        </h1>
      </Link>

      <div className="monad_ecosystem_filters-container">
        <select
          id="sort"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="monad_ecosystem_filter-select"
        >
          <option value="" disabled>Sort</option>
          <option value="name">Alphabetical</option>
          <option value="type">Category</option>
        </select>

        <select
          id="type"
          value={activeFilters.type || ''}
          onChange={(e) => handleTypeFilter(e.target.value || null)}
          className="monad_ecosystem_filter-select"
        >
          <option value="">Filter: All</option>
          {types.filter(type => type !== 'all').map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <button
          className={`monad_ecosystem_filter-button ${activeFilters.native ? 'active' : ''}`}
          onClick={() => handleNativeFilter(!activeFilters.native)}
        >
          Monad First
        </button>

        <button
          className={`monad_ecosystem_filter-button ${activeFilters.monadMadness ? 'active' : ''}`}
          onClick={() => handleMonadMadnessFilter(!activeFilters.monadMadness)}
        >
          Monad Madness
        </button>

        <button
          className={`monad_ecosystem_filter-button ${activeFilters.accelerator ? 'active' : ''}`}
          onClick={() => handleAcceleratorFilter(!activeFilters.accelerator)}
        >
          Accelerator
        </button>

        <button
          className={`monad_ecosystem_filter-button ${activeFilters.jumpstart ? 'active' : ''}`}
          onClick={() => handleJumpstartFilter(!activeFilters.jumpstart)}
        >
          Jumpstart
        </button>

        <button
          onClick={clearAllFilters}
          className="monad_ecosystem_clear-filters-button"
        >
          Clear All
        </button>
      </div>

      {filteredProjects.length > 0 ? (
        <div className="monad_ecosystem_projects-grid">
          {filteredProjects.map((project, index) => (
            <ProjectCard
              key={index}
              project={project}
              activeFilters={activeFilters}
              onTypeClick={handleTypeFilter}
              onNativeClick={(isActive) => handleNativeFilter(isActive)}
              onMonadMadnessClick={(isActive) => handleMonadMadnessFilter(isActive)}
              onAcceleratorClick={(isActive) => handleAcceleratorFilter(isActive)}
              onJumpstartClick={(isActive) => handleJumpstartFilter(isActive)}
            />
          ))}
        </div>
      ) : (
        <div className="monad_ecosystem_no-results-message">
          No projects match the selected filters
        </div>
      )}

      <div className="monad_ecosystem_disclaimer">
        This content is for informational purposes only and should not be considered financial advice. Any links provided are sourced from project pages. Always verify links before clicking. We do not endorse, guarantee, or take responsibility for any third-party content, investment decisions, or outcomes.
      </div>
    </div>
  );
};

export default MonadEcosystem;

