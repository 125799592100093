import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend } from 'recharts';
import api_url from "../config/api_url.json";
import '../styles/hyperliquid_risk_metrics.css';

const base_api_url = api_url.backend_api  // This will be https://api-data.asxn.xyz

const ITEMS_PER_PAGE = 10;

// Add vault addresses as constants
const VAULT_ADDRESSES = {
  PARENT: "0xdfc24b077bc1425ad1dea75bcb6f8158e10df303",
  HLP_A: "0x010461c14e146ac35fe42271bdc1134ee31c703a",
  HLP_LIQUIDATOR: "0x2e3d94f0562703b25c83308a05046ddaf9a8dd14",
  HLP_B: "0x31ca8395cf837de08b24da3f660e77761dfb974b"
};

// Move these helper functions to the top, before any components
const processVaultHistoricalData = async (vaultAddress) => {
  try {
    const url = "https://api.hyperliquid.xyz/info";
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: "vaultDetails",
        vaultAddress: vaultAddress
      })
    });

    const data = await response.json();
    console.log(`Raw vault details for ${vaultAddress}:`, data);

    if (!data || !data.portfolio || !data.portfolio[3] || !data.portfolio[3][1]) {
      console.error(`Invalid data structure for vault ${vaultAddress}:`, data);
      return {
        accountValue: [],
        pnl: []
      };
    }

    const allTimeData = data.portfolio[3][1];

    if (!allTimeData.accountValueHistory || !allTimeData.pnlHistory) {
      console.error(`Missing history data for vault ${vaultAddress}:`, allTimeData);
      return {
        accountValue: [],
        pnl: []
      };
    }

    return {
      accountValue: allTimeData.accountValueHistory.map(([timestamp, value]) => ({
        timestamp: new Date(timestamp),
        value: parseFloat(value)
      })),
      pnl: allTimeData.pnlHistory.map(([timestamp, value]) => ({
        timestamp: new Date(timestamp),
        value: parseFloat(value)
      }))
    };
  } catch (error) {
    console.error(`Error processing vault ${vaultAddress}:`, error);
    return {
      accountValue: [],
      pnl: []
    };
  }
};

const calculateWeeklyReturns = (accountValues, pnlHistory) => {
  const weeklyReturns = [];

  for (let i = 1; i < pnlHistory.length; i++) {
    const deltaPnl = pnlHistory[i].value - pnlHistory[i - 1].value;
    const avgAccountValue = (accountValues[i].value + accountValues[i - 1].value) / 2;
    const weeklyReturn = (deltaPnl / avgAccountValue) * 100;

    weeklyReturns.push({
      timestamp: pnlHistory[i].timestamp,
      value: weeklyReturn
    });
  }

  return weeklyReturns;
};

// Then define the VaultCharts component
const VaultCharts = () => {
  const [chartData, setChartData] = useState({
    accountValue: [],
    pnl: [],
    weeklyReturns: []
  });

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const parentData = await processVaultHistoricalData(VAULT_ADDRESSES.PARENT);
        const hlpaData = await processVaultHistoricalData(VAULT_ADDRESSES.HLP_A);
        const hlpbData = await processVaultHistoricalData(VAULT_ADDRESSES.HLP_B);
        const liquidatorData = await processVaultHistoricalData(VAULT_ADDRESSES.HLP_LIQUIDATOR);

        // Filter out the last data point if it's from the current week
        const filterLastIncompleteWeek = (data) => {
          if (data.length === 0) return data;
          const lastTimestamp = new Date(data[data.length - 1].timestamp);
          const now = new Date();
          // If the last data point is from the current week, remove it
          if (now - lastTimestamp < 7 * 24 * 60 * 60 * 1000) {
            return data.slice(0, -1);
          }
          return data;
        };

        // Process account value data
        const accountValueData = parentData.accountValue.map((item, index) => {
          const timestamp = item.timestamp.getTime();
          return {
            timestamp,
            Parent: item.value,
            HLPA: hlpaData.accountValue[index]?.value || 0,
            HLPB: hlpbData.accountValue[index]?.value || 0,
            Liquidator: liquidatorData.accountValue[index]?.value || 0
          };
        });

        // Add the latest account values as the final data point
        const latestTimestamp = new Date().getTime();
        const latestAccountValues = {
          timestamp: latestTimestamp,
          Parent: getLatestAccountValue(parentData.accountValue),
          HLPA: getLatestAccountValue(hlpaData.accountValue),
          HLPB: getLatestAccountValue(hlpbData.accountValue),
          Liquidator: getLatestAccountValue(liquidatorData.accountValue)
        };

        // Filter out incomplete week and add latest values
        const filteredAccountValueData = filterLastIncompleteWeek(accountValueData);
        filteredAccountValueData.push(latestAccountValues);

        // Process PnL data but remove the last data point
        const pnlData = parentData.pnl.slice(0, -1).map((item, index) => ({
          timestamp: item.timestamp.getTime(),
          Parent: item.value,
          HLPA: hlpaData.pnl[index]?.value || 0,
          HLPB: hlpbData.pnl[index]?.value || 0,
          Liquidator: liquidatorData.pnl[index]?.value || 0
        }));

        // Process weekly returns
        const parentReturns = calculateWeeklyReturns(parentData.accountValue, parentData.pnl);
        const hlpaReturns = calculateWeeklyReturns(hlpaData.accountValue, hlpaData.pnl);
        const hlpbReturns = calculateWeeklyReturns(hlpbData.accountValue, hlpbData.pnl);

        const weeklyReturnsData = parentReturns.map((item, index) => ({
          timestamp: item.timestamp.getTime(),
          Parent: item.value,
          HLPA: hlpaReturns[index]?.value || 0,
          HLPB: hlpbReturns[index]?.value || 0
        }));

        setChartData({
          accountValue: filteredAccountValueData,
          pnl: pnlData,
          weeklyReturns: weeklyReturnsData
        });
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, []);

  // Helper function to get latest account value
  const getLatestAccountValue = (accountValueHistory) => {
    if (!accountValueHistory || accountValueHistory.length === 0) return null;
    return accountValueHistory[accountValueHistory.length - 1].value;
  };

  const formatTooltipValue = (value) => {
    return `$${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
  };

  const formatDateTick = (timestamp) => {
    return new Date(timestamp).toLocaleDateString();
  };

  // Add this function to filter last 12 months of data
  const filterLast12Months = (data) => {
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
    return data.filter(item => new Date(item.timestamp) > twelveMonthsAgo);
  };

  return (
    <div className="charts-grid hlp-vaults">
      <div className="chart-container">
        <h3 className="chart-title">Account Value</h3>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData.accountValue}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <XAxis
              dataKey="timestamp"
              tickFormatter={formatDateTick}
              type="number"
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              tickFormatter={(value) => `$${(value / 1000000).toFixed(1)}M`}
            />
            <Tooltip
              formatter={formatTooltipValue}
              labelFormatter={formatDateTick}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="Parent"
              name="Total HLP"
              stroke="#8884d8"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="HLPA"
              name="HLP A"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="HLPB"
              name="HLP B"
              stroke="#ffc658"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Liquidator"
              name="HLP Liquidator"
              stroke="#ff7300"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="chart-container">
        <h3 className="chart-title">Cumulative PnL</h3>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData.pnl}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <XAxis
              dataKey="timestamp"
              tickFormatter={formatDateTick}
              type="number"
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              tickFormatter={(value) => `$${(value / 1000000).toFixed(1)}M`}
            />
            <Tooltip
              formatter={formatTooltipValue}
              labelFormatter={formatDateTick}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="Parent"
              name="Total HLP"
              stroke="#8884d8"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="HLPA"
              name="HLP A"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="HLPB"
              name="HLP B"
              stroke="#ffc658"
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Liquidator"
              name="HLP Liquidator"
              stroke="#ff7300"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="chart-container estimated-returns">
        <h3 className="chart-title">Estimated Returns</h3>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={chartData.weeklyReturns}
            margin={{ top: 10, right: 20, left: 10, bottom: 20 }}
            barCategoryGap={0} // Set to 0 to remove gaps between categories
            barGap={0} // Set to 0 to remove gaps between bars
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tickFormatter={formatDateTick}
              type="number"
              domain={['dataMin', 'dataMax']}
              angle={-45}
              textAnchor="end"
              height={60}
              tick={{ fontSize: 12 }}
              ticks={chartData.weeklyReturns.map(item => item.timestamp)}
            />
            <YAxis
              tickFormatter={(value) => `${value.toFixed(2)}%`}
            />
            <Tooltip
              formatter={(value) => `${value.toFixed(2)}%`}
              labelFormatter={formatDateTick}
            />
            <Bar
              dataKey="Parent"
              name="HLP Parent"
              fill="#8884d8"
              barSize={20} // Increase bar size to make them thicker
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const HyperliquidRiskMetrics = () => {
  const [data, setData] = useState({
    metrics: null,
    perpMarkets: null,
    fundingRates: null,
    impactPrices: null,
    dexMetrics: null,
    oiCapped: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageStates, setPageStates] = useState({
    perpsOverview: 1,
    oiCapped: 1,
    cexLiquidity: 1,
    impactPrices: 1,
    fundingRates: 1,
    dexLiquidity: 1,
    hlpDelta: 1,
    hlpOIPercentage: 1
  });
  const [sortConfig, setSortConfig] = useState({
    perpsOverview: { key: 'Open Interest ($)', direction: 'asc' },
    oiCapped: { key: 'Open Interest ($)', direction: 'asc' },
    cexLiquidity: { key: 'Open Interest ($)', direction: 'asc' },
    impactPrices: { key: 'Name', direction: 'asc' },
    fundingRates: { key: 'coin', direction: 'asc' },
    dexLiquidity: { key: 'total_reserves_in_usd', direction: 'desc' },
    hlpDelta: { key: 'notional', direction: 'desc' },
    hlpOIPercentage: { key: 'percentage', direction: 'desc' }
  });
  const [activeDashboard, setActiveDashboard] = useState('overview');
  const [timeframe, setTimeframe] = useState('8h');
  const [hlData, setHlData] = useState(null);
  const [vaultData, setVaultData] = useState({
    parent: { accountValue: null, positions: [], history: [] },
    hlpa: { accountValue: null, positions: [], history: [] },
    hlpb: { accountValue: null, positions: [], history: [] },
    liquidator: { accountValue: null, positions: [], history: [] }
  });
  const [vaultMetrics, setVaultMetrics] = useState({
    maxDrawdown: null,
    sharpeRatio: null,
    sortinoRatio: null,
    riskFreeRate: 0.04 // 4% annual
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [metricsResponse, perpMarketsResponse, dexMetricsResponse] = await Promise.all([
          fetch(`${base_api_url}/api/data/hl-risk-metrics`),
          fetch(`${base_api_url}/api/data/hl-risk-perp-markets`),
          fetch(`${base_api_url}/api/data/hl-dex-metrics`)
        ]);

        // Add Hyperliquid API calls
        const [
          hlInfoResponse,
          hlOICapsResponse,
          hlFundingResponse,
          // Add new API calls for vaults
          parentVaultDetailsResponse,
          parentVaultStateResponse,
          hlpAVaultDetailsResponse,
          hlpAVaultStateResponse,
          hlpBVaultDetailsResponse,
          hlpBVaultStateResponse,
          liquidatorVaultDetailsResponse,
          liquidatorVaultStateResponse
        ] = await Promise.all([
          // Existing API calls
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "type": "metaAndAssetCtxs" })
          }),
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "type": "perpsAtOpenInterestCap" })
          }),
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "type": "predictedFundings" })
          }),
          // New API calls for vaults
          // Parent Vault
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "vaultDetails",
              vaultAddress: VAULT_ADDRESSES.PARENT
            })
          }),
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "clearinghouseState",
              user: VAULT_ADDRESSES.PARENT
            })
          }),
          // HLP A Vault
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "vaultDetails",
              vaultAddress: VAULT_ADDRESSES.HLP_A
            })
          }),
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "clearinghouseState",
              user: VAULT_ADDRESSES.HLP_A
            })
          }),
          // HLP B Vault
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "vaultDetails",
              vaultAddress: VAULT_ADDRESSES.HLP_B
            })
          }),
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "clearinghouseState",
              user: VAULT_ADDRESSES.HLP_B
            })
          }),
          // Liquidator Vault
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "vaultDetails",
              vaultAddress: VAULT_ADDRESSES.HLP_LIQUIDATOR
            })
          }),
          fetch('https://api.hyperliquid.xyz/info', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: "clearinghouseState",
              user: VAULT_ADDRESSES.HLP_LIQUIDATOR
            })
          })
        ]);

        const [
          metricsData,
          perpMarketsData,
          dexMetricsData,
          hlData,
          oiCappedCoins,
          fundingData,
          // Add new response data
          parentVaultDetails,
          parentVaultState,
          hlpAVaultDetails,
          hlpAVaultState,
          hlpBVaultDetails,
          hlpBVaultState,
          liquidatorVaultDetails,
          liquidatorVaultState
        ] = await Promise.all([
          metricsResponse.json(),
          perpMarketsResponse.json(),
          dexMetricsResponse.json(),
          hlInfoResponse.json(),
          hlOICapsResponse.json(),
          hlFundingResponse.json(),
          // Process new responses
          parentVaultDetailsResponse.json(),
          parentVaultStateResponse.json(),
          hlpAVaultDetailsResponse.json(),
          hlpAVaultStateResponse.json(),
          hlpBVaultDetailsResponse.json(),
          hlpBVaultStateResponse.json(),
          liquidatorVaultDetailsResponse.json(),
          liquidatorVaultStateResponse.json()
        ]);

        // Process vault data
        const vaultData = {
          parent: {
            details: parentVaultDetails,
            state: parentVaultState
          },
          hlpa: {
            details: hlpAVaultDetails,
            state: hlpAVaultState
          },
          hlpb: {
            details: hlpBVaultDetails,
            state: hlpBVaultState
          },
          liquidator: {
            details: liquidatorVaultDetails,
            state: liquidatorVaultState
          }
        };

        // Update vault data state
        setVaultData(processVaultData(vaultData));

        // Keep all existing data processing
        const formattedImpactPrices = perpMarketsData.map(item => ({
          Name: item.Name,
          'notional impact size': item['notional impact size'],
          'Impact Price Low': item['Impact Price Low'],
          'Impact Price Low %': item['Impact Price Low %'],
          'Price': item.Price,
          'Impact Price High': item['Impact Price High'],
          'Impact Price High %': item['Impact Price High %']
        }));

        const metricsMap = new Map(metricsData.map(item => [item.Name, item]));
        const perpMarketsMap = new Map(perpMarketsData.map(item => [item.Name, item]));

        // Process Hyperliquid data for perps overview table
        const metadata = hlData[0].universe;
        const marketData = hlData[1];

        const formattedPerpMarketsOverview = marketData.map((item, index) => {
          const metaItem = metadata[index];
          const metricsItem = metricsMap.get(metaItem.name) || {};

          if (metaItem.isDelisted) return null;

          return {
            Name: metaItem.name,
            'Open Interest ($)': item.openInterest && item.markPx ?
              parseFloat(item.openInterest) * parseFloat(item.markPx) :
              null,
            'Market Cap': metricsItem.market_cap,
            'OI/MCap': metricsItem.market_cap && item.openInterest && item.markPx ?
              (parseFloat(item.openInterest) * parseFloat(item.markPx) / parseFloat(metricsItem.market_cap)) * 100 :
              null,
            'maxLeverage': metaItem.maxLeverage,
            'dayNtlVlm': item.dayNtlVlm ? parseFloat(item.dayNtlVlm) : null,
            'Premium': item.premium ? parseFloat(item.premium) : null
          };
        }).filter(item => item !== null);
        // Process funding data
        const formattedFundingData = fundingData
          .filter(item => Array.isArray(item) && item.length === 2 && Array.isArray(item[1]))
          .map(([coin, venues]) => {
            const fundingInfo = {
              coin,
              NotionalOpenInterest: null,
              HL: null,
              Binance: null,
              Bybit: null
            };

            // Safely process venues
            if (Array.isArray(venues)) {
              venues.forEach(venue => {
                if (Array.isArray(venue) && venue.length === 2) {
                  const [venueName, data] = venue;

                  if (data && data.fundingRate) {
                    const rate = parseFloat(data.fundingRate);

                    // Special case for Binance: default to 8 hours if fundingIntervalHours is missing
                    const intervalHours = venueName === 'BinPerp' && !data.fundingIntervalHours ?
                      8 :
                      parseFloat(data.fundingIntervalHours);

                    // Only proceed if we have a valid interval
                    if (!isNaN(intervalHours)) {
                      // Convert to yearly rate: rate * (24/interval) * 365
                      const yearlyRate = rate * (24 / intervalHours) * 365;

                      switch (venueName) {
                        case 'HlPerp':
                          fundingInfo.HL = (yearlyRate * 100).toString();
                          break;
                        case 'BinPerp':
                          fundingInfo.Binance = (yearlyRate * 100).toString();
                          break;
                        case 'BybitPerp':
                          fundingInfo.Bybit = (yearlyRate * 100).toString();
                          break;
                      }
                    }
                  }
                }
              });
            }

            // Add notional open interest from market data if available
            const marketInfo = marketData.find((item, idx) =>
              metadata[idx].name === coin &&
              !metadata[idx].isDelisted
            );

            if (marketInfo && marketInfo.openInterest && marketInfo.markPx) {
              fundingInfo.NotionalOpenInterest =
                parseFloat(marketInfo.openInterest) * parseFloat(marketInfo.markPx);
            }

            return fundingInfo;
          })
          .filter(info => {
            // Keep only entries that have:
            // 1. Valid NotionalOpenInterest (not null, not 0)
            // 2. At least one funding rate
            return info.NotionalOpenInterest &&
              info.NotionalOpenInterest > 0 &&
              (info.HL !== null || info.Binance !== null || info.Bybit !== null);
          });

        // In the fetchData function, after processing the perpMarkets data:
        const formattedOICappedData = oiCappedCoins.map(coin => {
          const marketInfo = marketData.find((item, idx) =>
            metadata[idx].name === coin && !metadata[idx].isDelisted
          );
          const metricsItem = metricsMap.get(coin) || {};

          if (!marketInfo) return null;

          const openInterest = marketInfo.openInterest && marketInfo.markPx ?
            parseFloat(marketInfo.openInterest) * parseFloat(marketInfo.markPx) :
            null;

          return {
            Name: coin,
            'Open Interest ($)': openInterest,
            'Market Cap': metricsItem.market_cap,
            'OI/MCap': metricsItem.market_cap && openInterest ?
              (openInterest / parseFloat(metricsItem.market_cap)) * 100 :
              null
          };
        }).filter(item => item !== null);

        // Update the data state - remove oiCapped from this object
        setData({
          metrics: metricsData,
          perpMarkets: formattedPerpMarketsOverview,
          fundingRates: formattedFundingData,
          impactPrices: formattedImpactPrices,
          dexMetrics: dexMetricsData,
          oiCapped: formattedOICappedData  // Add this line
        });
        setError(null);
      } catch (err) {
        console.error('Detailed error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Add this logging helper
  const logVaultData = (data, source) => {
    console.log(`[${source}] Vault Data:`, {
      parent: {
        accountValue: data?.parent?.accountValue,
        historyLength: data?.parent?.history?.length
      },
      hlpa: {
        accountValue: data?.hlpa?.accountValue,
        historyLength: data?.hlpa?.history?.length
      },
      hlpb: {
        accountValue: data?.hlpb?.accountValue,
        historyLength: data?.hlpb?.history?.length
      },
      liquidator: {
        accountValue: data?.liquidator?.accountValue,
        historyLength: data?.liquidator?.history?.length
      }
    });
  };

  // Update the useEffect for vault data
  useEffect(() => {
    const fetchVaultData = async () => {
      try {
        console.log('Starting vault data fetch...');

        // Create a mapping for vault keys
        const vaultKeyMap = {
          PARENT: 'parent',
          HLP_A: 'hlpa',
          HLP_B: 'hlpb',
          HLP_LIQUIDATOR: 'liquidator'
        };

        const vaultPromises = Object.entries(VAULT_ADDRESSES).map(async ([key, address]) => {
          try {
            console.log(`Fetching data for ${key} at ${address}...`);

            // Fetch both details and state in parallel
            const [detailsResponse, stateResponse] = await Promise.all([
              fetch('https://api.hyperliquid.xyz/info', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  type: "vaultDetails",
                  vaultAddress: address
                })
              }),
              fetch('https://api.hyperliquid.xyz/info', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  type: "clearinghouseState",
                  user: address
                })
              })
            ]);

            const [details, state] = await Promise.all([
              detailsResponse.json(),
              stateResponse.json()
            ]);

            console.log(`Raw data received for ${key}:`, { details, state });

            return {
              key: vaultKeyMap[key],
              details,
              state
            };
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
            return {
              key: vaultKeyMap[key],
              details: null,
              state: null
            };
          }
        });

        const results = await Promise.all(vaultPromises);
        console.log('All vault data fetched:', results);

        // Process the results into vault data
        const processedData = results.reduce((acc, { key, details, state }) => {
          try {
            // Safely access data with null checks
            const accountValue = state?.marginSummary?.accountValue ?? null;
            const positions = state?.assetPositions?.map(pos => ({
              coin: pos.position.coin,
              size: parseFloat(pos.position.szi),
              entryPx: parseFloat(pos.position.entryPx),
              positionValue: parseFloat(pos.position.positionValue || (pos.position.szi * pos.position.entryPx)),
              unrealizedPnl: parseFloat(pos.position.unrealizedPnl || 0),
              returnOnEquity: parseFloat(pos.position.returnOnEquity || 0),
              notional: parseFloat(pos.position.szi) * parseFloat(pos.position.entryPx)
            })) ?? [];

            // Safely process historical data
            const history = details?.portfolio?.[3]?.[1] ?? { accountValueHistory: [], pnlHistory: [] };
            const processedHistory = (history.accountValueHistory ?? []).map((value, index) => ({
              timestamp: new Date(value[0]),
              accountValue: parseFloat(value[1]),
              pnl: parseFloat(history.pnlHistory?.[index]?.[1] ?? 0)
            }));

            acc[key] = {
              accountValue,
              positions,
              history: processedHistory
            };
            return acc;
          } catch (error) {
            console.error(`Error processing data for ${key}:`, error);
            acc[key] = {
              accountValue: null,
              positions: [],
              history: []
            };
            return acc;
          }
        }, {});

        console.log('Processed vault data:', processedData);

        // Only update state if we have valid data
        if (processedData.parent?.history?.length > 0) {
          setVaultData(processedData);
          const metrics = calculateMetrics(processedData.parent.history);
          setVaultMetrics(metrics);
        }

      } catch (error) {
        console.error('Error in fetchVaultData:', error);
      }
    };

    if (activeDashboard === 'hlp') {
      fetchVaultData();
    }
  }, [activeDashboard]);

  const sortData = (data, key, direction, tableId) => {
    return [...data].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      // Special handling for funding rate and arbitrage columns
      if (['HL', 'Binance', 'Bybit', 'binance_arb', 'bybit_arb'].includes(key)) {
        // If either value is "-", move it to the end
        if (aValue === '-' && bValue === '-') return 0;
        if (aValue === '-') return 1;  // Always move 'a' to end if it's "-"
        if (bValue === '-') return -1; // Always move 'b' to end if it's "-"

        // For arbitrage columns, calculate the values
        if (key === 'binance_arb' || key === 'bybit_arb') {
          const getArbValue = (item, venue) => {
            const hlValue = parseFloat(item.HL || 0);
            const venueValue = parseFloat(item[venue] || 0);
            return venueValue - hlValue;
          };

          aValue = key === 'binance_arb' ? 
            getArbValue(a, 'Binance') : 
            getArbValue(a, 'Bybit');
          bValue = key === 'binance_arb' ? 
            getArbValue(b, 'Binance') : 
            getArbValue(b, 'Bybit');
        } else {
          // For regular funding rate columns, parse the values
          aValue = parseFloat(aValue) || 0;
          bValue = parseFloat(bValue) || 0;
        }

        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      if (aValue === 'N/A' || aValue === null || aValue === undefined || aValue === '') {
        return direction === 'asc' ? 1 : -1;
      }
      if (bValue === 'N/A' || bValue === null || bValue === undefined || bValue === '') {
        return direction === 'asc' ? -1 : 1;
      }

      // Convert currency strings to numbers
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // Handle currency values (e.g., "$100,000")
        if (aValue.includes('$') || bValue.includes('$')) {
          aValue = parseFloat(aValue.replace(/[$,]/g, '')) || 0;
          bValue = parseFloat(bValue.replace(/[$,]/g, '')) || 0;
        }
        // Handle percentage values (e.g., "10.5%")
        else if (aValue.includes('%') || bValue.includes('%')) {
          aValue = parseFloat(aValue.replace(/%/g, '')) || 0;
          bValue = parseFloat(bValue.replace(/%/g, '')) || 0;
        }
        // Handle "K", "M", "B" suffixes
        else if (aValue.match(/[KMB]/) || bValue.match(/[KMB]/)) {
          const convertToNumber = (val) => {
            if (typeof val !== 'string') return val;
            const num = parseFloat(val.replace(/[$,]/g, ''));
            if (val.includes('K')) return num * 1000;
            if (val.includes('M')) return num * 1000000;
            if (val.includes('B')) return num * 1000000000;
            return num;
          };
          aValue = convertToNumber(aValue);
          bValue = convertToNumber(bValue);
        }
      }

      // Handle leverage values (e.g., "10x")
      if (key === 'maxLeverage') {
        aValue = parseFloat(aValue.replace('x', '')) || 0;
        bValue = parseFloat(bValue.replace('x', '')) || 0;
      }

      // If both values are numbers after conversion, do numeric comparison
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Fallback to string comparison for non-numeric values
      return direction === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
  };

  const renderTable = (tableData, title, columns, formatters = {}, headerMappings = {}, className = "", tableId) => {
    if (!tableData) return null;

    const handleSort = (key) => {
      setSortConfig(prev => {
        const newDirection =
          prev[tableId].key === key && prev[tableId].direction === 'asc'
            ? 'desc'
            : 'asc';
        return {
          ...prev,
          [tableId]: { key, direction: newDirection }
        };
      });
    };

    const dataArray = Array.isArray(tableData) ? tableData : Object.values(tableData);
    const sortedData = sortData(
      dataArray,
      sortConfig[tableId].key,
      sortConfig[tableId].direction,
      tableId
    );

    const startIndex = (pageStates[tableId] - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const paginatedData = sortedData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(sortedData.length / ITEMS_PER_PAGE);

    return (
      <div className="grid-item">
        <div className="table-header">{title}</div>
        <div className="table-container">
          <table className="risk-metrics-table">
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    onClick={() => handleSort(col)}
                  >
                    {headerMappings[col] ||
                      (col === 'NotionalOpenInterest' ? 'Hyperliquid OI' :
                        col === 'HL' ? 'Hyperliquid' :
                          col === 'binance_arb' ? 'Binance-HL Arb' :
                            col === 'bybit_arb' ? 'Bybit-HL Arb' :
                              col.charAt(0).toUpperCase() + col.slice(1))}
                    {sortConfig[tableId].key === col && (
                      <span>{sortConfig[tableId].direction === 'asc' ? ' ↑' : ' ↓'}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((row, index) => (
                <tr key={index}>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex}>
                      {formatters[col] ? formatters[col](row[col], row) : (row[col] || 'N/A')}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            <button
              onClick={() => setPageStates(prev => ({
                ...prev,
                [tableId]: Math.max(1, prev[tableId] - 1)
              }))}
              disabled={pageStates[tableId] === 1}
              className="pagination-button"
            >
              Previous
            </button>
            <span className="page-info">
              Page {pageStates[tableId]} of {totalPages}
            </span>
            <button
              onClick={() => setPageStates(prev => ({
                ...prev,
                [tableId]: Math.min(totalPages, prev[tableId] + 1)
              }))}
              disabled={pageStates[tableId] === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        )}
      </div>
    );
  };

  // Add these formatting functions
  const formatNotional = (value) => {
    if (!value) return "-";
    const numValue = parseFloat(value.toString().replace(/,/g, ''));
    return `$${Math.round(numValue).toLocaleString('en-US')}`;
  };

  const formatRate = (rate) => {
    if (!rate || rate === "") return "-";
    const value = parseFloat(rate);
    return `${value.toFixed(2)}%`; // Show fewer decimals for yearly rate
  };

  const calculateArbitrage = (_, row) => {
    if (!row || !row.HL || !row.Binance || !row.Bybit) return "-";

    const hlValue = parseFloat(row.HL);
    const binanceValue = parseFloat(row.Binance);
    const bybitValue = parseFloat(row.Bybit);

    // Calculate arbitrage differences (already in yearly percentage terms)
    if (row.binance_arb) {
      return `${(binanceValue - hlValue).toFixed(2)}%`;
    } else if (row.bybit_arb) {
      return `${(bybitValue - hlValue).toFixed(2)}%`;
    }
    return "-";
  };

  const formatDollar = (value) => {
    if (!value || isNaN(value)) return '-';
    const num = parseFloat(value);
    if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(2)}M`;
    } else if (num >= 1000) {
      return `$${(num / 1000).toFixed(2)}K`;
    }
    return `$${num.toFixed(2)}`;
  };

  // Add a formatter for market cap
  const formatMarketCap = (value) => {
    if (!value || isNaN(value)) return 'N/A';
    const num = parseFloat(value);
    if (num >= 1000000000) {
      return `$${(num / 1000000000).toFixed(2)}B`;
    }
    if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(2)}M`;
    }
    if (num >= 1000) {
      return `$${(num / 1000).toFixed(2)}K`;
    }
    return `$${num.toFixed(2)}`;
  };

  // Add these helper functions after your existing formatters
  const prepareChartData = (data, key, sortDirection = 'asc', limit = 10) => {
    if (!data || !Array.isArray(data)) return [];

    if (key === 'Impact Price Low %' || key === 'Impact Price High %') {
      return [...data]
        .filter(item => item[key] !== null && item[key] !== undefined && !isNaN(parseFloat(item[key])))
        .map(item => ({
          ...item,
          [key]: Math.abs(parseFloat(item[key]) * 100)  // Convert to absolute percentage
        }))
        .sort((a, b) => b[key] - a[key])  // Sort by descending value
        .slice(0, limit);
    }

    // Original logic for other charts
    return [...data]
      .sort((a, b) => {
        const aVal = parseFloat(a[key]) || 0;
        const bVal = parseFloat(b[key]) || 0;
        return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
      })
      .slice(0, limit);
  };

  // Add chart rendering components
  const ChartContainer = ({ children, title }) => (
    <div className="chart-container">
      <h3 className="chart-title">{title}</h3>
      <div className="chart-wrapper">
        <ResponsiveContainer width="100%" height={450}>
          {children}
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderBarChart = (data, xKey, yKey, color) => {
    const maxValue = Math.max(...data.map(item => Math.abs(parseFloat(item[yKey]))));
    const yAxisMax = maxValue * 1.2;

    // Format number to K/M notation
    const formatYAxis = (value) => {
      if (Math.abs(value) >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`;
      }
      if (Math.abs(value) >= 1000) {
        return `${(value / 1000).toFixed(1)}K`;
      }
      return value.toFixed(1);
    };

    // New tooltip formatter function
    const formatTooltip = (value, name, yKey) => {
      if (yKey === 'percentage') {
        return `${parseFloat(value).toFixed(2)}%`;
      } else if (yKey === 'hlpNotional') {
        if (Math.abs(value) >= 1000000) {
          return `$${(value / 1000000).toFixed(2)}M`;
        }
        if (Math.abs(value) >= 1000) {
          return `$${(value / 1000).toFixed(2)}K`;
        }
        return `$${parseFloat(value).toFixed(2)}`;
      }
      return parseFloat(value).toFixed(2);
    };

    return (
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 10, bottom: 5 }}
      >
        <CartesianGrid
          stroke="#E5E5E5"
          strokeDasharray="3 3"
        />
        <XAxis
          dataKey={xKey}
          axisLine={false}
          tickLine={false}
          tick={{
            fill: '#666666',
            fontSize: 8,
            angle: -45,
            textAnchor: 'end',
            dy: 8
          }}
          height={80}
          interval={0}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#666666', fontSize: 11 }}
          domain={[0, yAxisMax]}
          tickFormatter={formatYAxis}
        />
        <Tooltip
          contentStyle={{
            background: 'white',
            border: '1px solid black',
            borderRadius: '0px',
            fontSize: '11px',
            padding: '8px'
          }}
          formatter={(value) => formatTooltip(value, null, yKey)}
          labelFormatter={(label) => label}
        />
        <Bar
          dataKey={yKey}
          fill={color}
          data={data}
        />
      </BarChart>
    );
  };

  const renderDashboardNav = () => (
    <div className="dashboard-nav">
      <button
        className={`dashboard-nav-button ${activeDashboard === 'overview' ? 'active' : ''}`}
        onClick={() => setActiveDashboard('overview')}
      >
        Overview
      </button>
      <button
        className={`dashboard-nav-button ${activeDashboard === 'liquidity' ? 'active' : ''}`}
        onClick={() => setActiveDashboard('liquidity')}
      >
        Liquidity
      </button>
      <button
        className={`dashboard-nav-button ${activeDashboard === 'funding' ? 'active' : ''}`}
        onClick={() => setActiveDashboard('funding')}
      >
        Funding & Impact
      </button>
      <button
        className={`dashboard-nav-button ${activeDashboard === 'hlp' ? 'active' : ''}`}
        onClick={() => setActiveDashboard('hlp')}
      >
        HLP & Vaults Stats
      </button>
    </div>
  );

  const renderOverviewDashboard = () => (
    <>
      {renderTable(
        // Filter out FTT from the table data
        data.perpMarkets?.filter(item => item.Name !== 'FTT'), 
        "Hyperliquid Perpetual Markets Overview",
        [
          'Name',
          'Open Interest ($)',
          'Market Cap',
          'OI/MCap',
          'maxLeverage',
          'dayNtlVlm',
          'Premium'
        ],
        {
          'Name': value => value || 'N/A',
          'Open Interest ($)': value => {
            if (!value) return '-';
            const num = Number(value.toString().replace(/[\$,]/g, ''));
            return `$${num.toLocaleString('en-US')}`;
          },
          'Market Cap': formatMarketCap,
          'OI/MCap': value => value ? `${value.toFixed(4)}%` : 'N/A',
          'maxLeverage': value => value ? `${value}x` : 'N/A',
          'dayNtlVlm': value => {
            if (!value || value === '-') return '-';
            const num = parseFloat(value);
            if (num >= 1000000) {
              return `$${(num / 1000000).toFixed(2)}M`;
            }
            if (num >= 1000) {
              return `$${(num / 1000).toFixed(2)}K`;
            }
            return `$${num.toFixed(2)}`;
          },
          'Premium': value => {
            if (!value || isNaN(value)) return 'N/A';
            return `${(value * 100).toFixed(4)}%`;
          }
        },
        {
          'Open Interest ($)': 'HL OI ($)',
          'Market Cap': 'Market Cap',
          'OI/MCap': 'OI/MCap %',
          'maxLeverage': 'Max Leverage',
          'dayNtlVlm': '24h Volume',
          'Premium': 'Premium/Discount'
        },
        "risk-metrics-table",
        "perpsOverview"
      )}

      <div className="charts-grid overview">
        <ChartContainer title="Lowest Market Cap">
          {renderBarChart(
            prepareChartData(data.perpMarkets?.filter(item => item.Name !== 'FTT'), 'Market Cap', 'asc'),
            'Name',
            'Market Cap',
            '#4caf50'
          )}
        </ChartContainer>

        <ChartContainer title="Highest OI/MCap Ratio">
          {renderBarChart(
            prepareChartData(
              data.perpMarkets?.filter(item => item.Name !== 'FTT'),
              'OI/MCap',
              'desc'
            ),
            'Name',
            'OI/MCap',
            '#2196f3'
          )}
        </ChartContainer>

        <ChartContainer title="Lowest 24h Volume">
          {renderBarChart(
            prepareChartData(data.perpMarkets, 'dayNtlVlm', 'asc'),
            'Name',
            'dayNtlVlm',
            '#ff9800'
          )}
        </ChartContainer>

        <ChartContainer title="Lowest Open Interest">
          {renderBarChart(
            prepareChartData(data.perpMarkets, 'Open Interest ($)', 'asc'),
            'Name',
            'Open Interest ($)',
            '#9c27b0'
          )}
        </ChartContainer>
      </div>

      {renderTable(data.oiCapped, "Assets at Hyperliquid OI Caps", [
        'Name',
        'Open Interest ($)',
        'Market Cap',
        'OI/MCap'
      ], {
        'Name': value => value || 'N/A',
        'Open Interest ($)': value => {
          if (!value) return '-';
          const num = Number(value.toString().replace(/[\$,]/g, ''));
          return `$${num.toLocaleString('en-US')}`;
        },
        'Market Cap': formatMarketCap,
        'OI/MCap': value => value ? `${value.toFixed(4)}%` : 'N/A'
      }, {
        'Open Interest ($)': 'HL OI ($)',
        'OI/MCap': 'OI/MCap %'
      }, "risk-metrics-table", "oiCapped")}
    </>
  );

  const renderLiquidityDashboard = () => {
    // Create a map of open interest values from Hyperliquid data
    const hlOpenInterestMap = new Map();
    if (data.perpMarkets) {
      data.perpMarkets.forEach(item => {
        if (item['Open Interest ($)']) {
          hlOpenInterestMap.set(item.Name, item['Open Interest ($)']);
        }
      });
    }

    // Modify metrics data to use Hyperliquid OI
    const metricsWithHLOI = data.metrics?.map(item => ({
      ...item,
      'Open Interest ($)': hlOpenInterestMap.get(item.Name) || null
    })) || [];

    return (
      <>
        {renderTable(metricsWithHLOI,
          <div className="table-title-container">
            <div>CEX Liquidity (±2% OB Depth)</div>
            <div className="table-subtitle">We take the deepest order book from Binance (spot and perps), Bybit (spot and perps), Kucoin and OKX.</div>
          </div>,
          [
            'Name',
            'Open Interest ($)',
            'cost_to_move_down_usd',
            'cost_to_move_up_usd'
          ], {
          'Name': value => value || 'N/A',
          'Open Interest ($)': value => {
            if (!value) return '-';
            return formatDollar(value);
          },
          'cost_to_move_down_usd': value => value ? formatDollar(value) : '-',
          'cost_to_move_up_usd': value => value ? formatDollar(value) : '-'
        }, {
          'Open Interest ($)': ' HL OI ($)',
          'cost_to_move_down_usd': '-2% Depth',
          'cost_to_move_up_usd': '+2% Depth'
        }, "risk-metrics-table", "cexLiquidity")}

        <div className="charts-grid liquidity-charts">
          <ChartContainer title="Most Illiquid CEX Pairs (+2%)">
            {renderBarChart(
              prepareChartData(
                metricsWithHLOI.filter(item => item.cost_to_move_up_usd && item.cost_to_move_up_usd > 0),
                'cost_to_move_up_usd',
                'asc'
              ),
              'Name',
              'cost_to_move_up_usd',
              '#4caf50'
            )}
          </ChartContainer>

          <ChartContainer title="Most Illiquid CEX Pairs (-2%)">
            {renderBarChart(
              prepareChartData(
                metricsWithHLOI.filter(item => item.cost_to_move_down_usd && item.cost_to_move_down_usd > 0),
                'cost_to_move_down_usd',
                'asc'
              ),
              'Name',
              'cost_to_move_down_usd',
              '#f44336'
            )}
          </ChartContainer>
        </div>

        {renderTable(data.dexMetrics,
          <div className="table-title-container">
            <div>DEX Liquidity</div>
            <div className="table-subtitle">
              <span className="tooltip-text">Note: Not all chains tracked.</span>
              <span className="tooltip-content">
                We are tracking DEX pools on cosmos, evmos, canto, kava, binance-smart-chain, ethereum, moonriver, harmony-shard-0, moonbeam, energi, polygon-pos, optimistic-ethereum, arbitrum-one, arbitrum-nova, sui, fantom, near-protocol, xdai, milkomeda-cardano, avalanche, base, aptos, polygon-zkevm, solana, ronin, manta-pacific, tomochain, sonic, the-open-network, linea, neon-evm, celo, zksync, opbnb, starknet and mantle.
              </span>
            </div>
          </div>,
          [
            'hyperliquid_ticker',
            'total_reserves_in_usd',
            'total_24h_volume',
            'buys_24h',
            'sells_24h',
            'buyers_24h',
            'sellers_24h'
          ], {
          'hyperliquid_ticker': value => value || 'N/A',
          'total_reserves_in_usd': value => formatDollar(parseFloat(value)),
          'total_24h_volume': value => formatDollar(parseFloat(value)),
          'buys_24h': value => parseInt(value)?.toLocaleString() || 'N/A',
          'sells_24h': value => parseInt(value)?.toLocaleString() || 'N/A',
          'buyers_24h': value => parseInt(value)?.toLocaleString() || 'N/A',
          'sellers_24h': value => parseInt(value)?.toLocaleString() || 'N/A'
        }, {
          'hyperliquid_ticker': 'Name',
          'total_reserves_in_usd': 'Reserves',
          'total_24h_volume': 'Volume',
          'buys_24h': 'Buys',
          'sells_24h': 'Sells',
          'buyers_24h': 'Buyers',
          'sellers_24h': 'Sellers'
        }, "risk-metrics-table", "dexLiquidity")}
      </>
    );
  };

  const renderFundingAndImpactDashboard = () => (
    <>
      {renderTable(data.impactPrices,
        <div className="table-title-container">
          <div>Impact Prices</div>
          <div className="table-subtitle">The impact buy/sell price shows what price the asset would reach if the notional impact size was market bought/sold.</div>
        </div>,
        [
          'Name',
          'notional impact size',
          'Impact Price Low',
          'Impact Price Low %',
          'Price',
          'Impact Price High',
          'Impact Price High %'
        ], {
        'Name': value => value || 'N/A',
        'notional impact size': value => value ? formatDollar(value) : '-',
        'Impact Price Low': value => value ? `$${Number(value).toFixed(2)}` : 'N/A',
        'Impact Price Low %': value => value ? `${(value * 100).toFixed(4)}%` : 'N/A',
        'Price': value => value ? `$${Number(value).toFixed(2)}` : 'N/A',
        'Impact Price High': value => value ? `$${Number(value).toFixed(2)}` : 'N/A',
        'Impact Price High %': value => value ? `${(value * 100).toFixed(4)}%` : 'N/A'
      }, {
        'Impact Price Low': 'Impact Price (Sell)',
        'Impact Price High': 'Impact Price (Buy)',
        'Impact Price Low %': 'Impact % (Sell)',
        'Impact Price High %': 'Impact % (Buy)',
        'Price': 'Mid Price'
      }, "risk-metrics-table", "impactPrices")}

      <div className="charts-grid impact-charts">
        <ChartContainer title="Highest Impact % (Sell)">
          {renderBarChart(
            prepareChartData(data.impactPrices, 'Impact Price Low %', 'desc'),
            'Name',
            'Impact Price Low %',
            '#f44336'
          )}
        </ChartContainer>

        <ChartContainer title="Highest Impact % (Buy)">
          {renderBarChart(
            prepareChartData(data.impactPrices, 'Impact Price High %', 'desc'),
            'Name',
            'Impact Price High %',
            '#4caf50'
          )}
        </ChartContainer>
      </div>

      {renderTable(data.fundingRates, "Funding Rate Comparison (Annualized)", [
        'coin',
        'NotionalOpenInterest',
        'HL',
        'Binance',
        'Bybit',
        'binance_arb',
        'bybit_arb'
      ], {
        'coin': value => value || 'N/A',
        'NotionalOpenInterest': formatNotional,
        'HL': formatRate,
        'Binance': formatRate,
        'Bybit': formatRate,
        'binance_arb': (_, row) => calculateArbitrage(_, { ...row, binance_arb: true }),
        'bybit_arb': (_, row) => calculateArbitrage(_, { ...row, bybit_arb: true })
      }, {}, "risk-metrics-table", "fundingRates")}
    </>
  );

  // Add helper functions for calculations
  const processVaultHistory = (data) => {
    const { accountValueHistory, pnlHistory } = data;

    return accountValueHistory.map((value, index) => ({
      timestamp: new Date(value[0]),
      accountValue: parseFloat(value[1]),
      pnl: parseFloat(pnlHistory[index][1])
    }));
  };

  const calculateMetrics = (history) => {
    if (!history || history.length < 8) return {}; // Need at least 8 data points for a week + 1

    // Remove the first data point as done in Python
    history = history.slice(1);

    // Calculate weekly returns
    const returns = history.map((point, i, arr) => {
      if (i === 0) return 0;
      const prevPoint = arr[i - 1];
      const deltaPnl = point.pnl - prevPoint.pnl;
      const avgValue = 0.5 * (
        point.accountValue - deltaPnl + prevPoint.accountValue
      );
      return deltaPnl / avgValue;
    }).slice(1); // Remove first element as it's always 0

    // Get the previous month's returns (last 30 data points)
    const previousWeekReturns = returns.slice(-30);
    // Calculate the cumulative return for the previous week
    const previousWeekReturn = previousWeekReturns.reduce((acc, ret) => (1 + acc) * (1 + ret) - 1, 0);
    // Annualize the previous week's return
    const annualizedLastWeekReturn = previousWeekReturn * 12 * 100; // Convert to percentage and annualize

    // Calculate average weekly return
    const averageWeeklyReturn = (returns.reduce((a, b) => a + b, 0) / returns.length) * 100;

    // Calculate excess returns (now using actual returns instead of excess over risk-free)
    const meanReturn = returns.reduce((a, b) => a + b, 0) / returns.length;

    // Calculate standard deviation for Sharpe/Sortino calculations
    const calculateStd = (arr, mean) => {
      const squaredDiffs = arr.map(x => Math.pow(x - mean, 2));
      const variance = squaredDiffs.reduce((a, b) => a + b, 0) / (arr.length - 1);
      return Math.sqrt(variance);
    };

    const stdExcessReturn = calculateStd(returns, meanReturn);

    // Calculate downside deviation (only negative returns)
    const downsideReturns = returns.filter(r => r < 0);
    const downsideMean = downsideReturns.reduce((a, b) => a + b, 0) / downsideReturns.length;
    const downsideStd = calculateStd(downsideReturns, downsideMean);

    // Annualize ratios
    const annualizedSharpeRatio = (meanReturn / stdExcessReturn) * Math.sqrt(52);
    const annualizedSortinoRatio = (meanReturn / downsideStd) * Math.sqrt(52);

    // Calculate cumulative returns
    const cumulativeReturns = returns.reduce((acc, ret) => {
      const lastReturn = acc[acc.length - 1] || 1;
      acc.push(lastReturn * (1 + ret));
      return acc;
    }, [1]);

    // Calculate max drawdown
    const drawdowns = cumulativeReturns.map((value, i) =>
      value / Math.max(...cumulativeReturns.slice(0, i + 1)) - 1
    );
    const maxDrawdown = Math.min(...drawdowns) * 100;

    const cumulativeReturn = (cumulativeReturns[cumulativeReturns.length - 1]) * 100;

    return {
      maxDrawdown,
      annualizedLastWeekReturn, // Now using full week of data
      sharpeRatio: annualizedSharpeRatio,
      sortinoRatio: annualizedSortinoRatio,
      averageWeeklyReturn,
      cumulativeReturn
    };
  };

  // Add this function after the other helper functions
  const processVaultData = (rawData) => {
    const processedData = {};

    Object.entries(rawData).forEach(([key, vault]) => {
      try {
        if (!vault || !vault.state || !vault.details) {
          console.error(`Missing vault data for ${key}:`, vault);
          processedData[key] = {
            accountValue: 0,
            positions: [],
            history: []
          };
          return;
        }

        // Safely access account value
        const accountValue = vault.state?.marginSummary?.accountValue
          ? parseFloat(vault.state.marginSummary.accountValue)
          : 0;

        // Safely process positions
        const positions = vault.state?.assetPositions
          ? vault.state.assetPositions.map(pos => {
            try {
              return {
                coin: pos.position.coin,
                size: parseFloat(pos.position.szi),
                entryPx: parseFloat(pos.position.entryPx),
                positionValue: parseFloat(pos.position.positionValue || (pos.position.szi * pos.position.entryPx)),
                unrealizedPnl: parseFloat(pos.position.unrealizedPnl || 0),
                returnOnEquity: parseFloat(pos.position.returnOnEquity || 0),
                notional: parseFloat(pos.position.szi) * parseFloat(pos.position.entryPx)
              };
            } catch (error) {
              console.error(`Error processing position for ${key}:`, error, pos);
              return null;
            }
          }).filter(pos => pos !== null)
          : [];

        // Safely process historical data
        const history = vault.details?.portfolio?.[3]?.[1] || { accountValueHistory: [], pnlHistory: [] };
        const processedHistory = history.accountValueHistory
          ? history.accountValueHistory.map((value, index) => {
            try {
              return {
                timestamp: new Date(value[0]),
                accountValue: parseFloat(value[1]),
                pnl: parseFloat(history.pnlHistory?.[index]?.[1] ?? 0)
              };
            } catch (error) {
              console.error(`Error processing history entry for ${key}:`, error, value);
              return null;
            }
          }).filter(entry => entry !== null)
          : [];

        processedData[key] = {
          accountValue,
          positions,
          history: processedHistory
        };
      } catch (error) {
        console.error(`Error processing vault ${key}:`, error);
        processedData[key] = {
          accountValue: 0,
          positions: [],
          history: []
        };
      }
    });

    console.log('Processed vault data:', processedData);
    return processedData;
  };

  // Update the calculateCumulativeDelta function
  const calculateCumulativeDelta = (vaultData) => {
    if (!vaultData) return [];

    // Create a map to store cumulative positions by coin
    const cumulativePositions = new Map();

    // Process positions from each vault
    Object.values(vaultData).forEach(vault => {
      vault.positions.forEach(position => {
        const currentSize = cumulativePositions.get(position.coin)?.size || 0;
        const currentNotional = cumulativePositions.get(position.coin)?.notional || 0;

        // Calculate position value with correct sign
        const positionValue = (position.positionValue || position.notional) *
          (Math.sign(position.size) || 1);

        cumulativePositions.set(position.coin, {
          size: currentSize + position.size,
          notional: currentNotional + positionValue // Use the signed position value
        });
      });
    });

    // Convert map to array of objects
    return Array.from(cumulativePositions.entries()).map(([coin, values]) => ({
      coin,
      size: values.size,
      notional: values.notional // This will now preserve the signs from position values
    }));
  };

  // Update only the calculateHLPPercentageOfOI function
  const calculateHLPPercentageOfOI = (vaultData, marketData) => {
    if (!vaultData || !marketData) return [];

    // Create a map of market data for quick lookup
    const marketOIMap = new Map();
    marketData.forEach(market => {
      if (market.Name && market['Open Interest ($)']) {
        marketOIMap.set(market.Name, market['Open Interest ($)']);
      }
    });

    const cumulativeDelta = calculateCumulativeDelta(vaultData);

    return cumulativeDelta.map(position => {
      const totalOI = marketOIMap.get(position.coin);
      const hlpNotional = Math.abs(position.notional);

      return {
        coin: position.coin,
        hlpNotional,
        totalOI: totalOI || 0, // Use 0 instead of null if no OI data
        percentage: totalOI ? (hlpNotional / totalOI) * 100 : 0
      };
    }).filter(item => item !== null);
  };

  // Convert to a proper React component
  const PositionsTable = ({ positions, title }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'positionValue', direction: 'desc' });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    if (!positions || positions.length === 0) {
      return (
        <div className="positions-table-container">
          <h3>{title}</h3>
          <table className="positions-table">
            <thead>
              <tr>
                <th>Coin</th>
                <th>Size</th>
                <th>Entry Price</th>
                <th>Position Value</th>
                <th>Unrealized PnL</th>
                <th>ROE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="6" className="no-positions">No positions</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    // Sorting function
    const sortedPositions = React.useMemo(() => {
      const sortedData = [...positions];
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] === null) return 1;
        if (b[sortConfig.key] === null) return -1;

        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Special handling for position value sorting
        if (sortConfig.key === 'positionValue') {
          // Use absolute values for position value sorting
          aValue = Math.abs(a.positionValue || a.notional);
          bValue = Math.abs(b.positionValue || b.notional);
        }

        // Convert string numbers to floats for comparison
        if (typeof aValue === 'string' && !isNaN(aValue)) {
          aValue = parseFloat(aValue);
        }
        if (typeof bValue === 'string' && !isNaN(bValue)) {
          bValue = parseFloat(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      return sortedData;
    }, [positions, sortConfig]);

    // Pagination
    const totalPages = Math.ceil(sortedPositions.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedPositions.slice(indexOfFirstItem, indexOfLastItem);

    // Handle sort
    const requestSort = (key) => {
      setSortConfig((prevConfig) => ({
        key,
        direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc',
      }));
    };

    // Get sort indicator
    const getSortIndicator = (key) => {
      if (sortConfig.key === key) {
        return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
      }
      return '';
    };

    return (
      <div className="positions-table-container">
        <h3>{title}</h3>
        <table className="positions-table">
          <thead>
            <tr>
              <th onClick={() => requestSort('coin')}>
                Coin{getSortIndicator('coin')}
              </th>
              <th onClick={() => requestSort('size')}>
                Size{getSortIndicator('size')}
              </th>
              <th onClick={() => requestSort('entryPx')}>
                Entry Price{getSortIndicator('entryPx')}
              </th>
              <th onClick={() => requestSort('positionValue')}>
                Position Value{getSortIndicator('positionValue')}
              </th>
              <th onClick={() => requestSort('unrealizedPnl')}>
                Unrealized PnL{getSortIndicator('unrealizedPnl')}
              </th>
              <th onClick={() => requestSort('returnOnEquity')}>
                ROE{getSortIndicator('returnOnEquity')}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((position, index) => {
              const positionValue = (position.positionValue || position.notional) *
                (Math.sign(position.size) || 1);

              return (
                <tr key={`${position.coin}-${index}`}>
                  <td>{position.coin}</td>
                  <td>{position.size.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</td>
                  <td>${position.entryPx.toFixed(2)}</td>
                  <td>{positionValue < 0 ? `-$${Math.abs(positionValue).toLocaleString()}` : `$${positionValue.toLocaleString()}`}</td>
                  <td>{position.unrealizedPnl ?
                    (position.unrealizedPnl < 0 ? `-$${Math.abs(position.unrealizedPnl).toLocaleString()}` : `$${position.unrealizedPnl.toLocaleString()}`)
                    : '-'}</td>
                  <td>{position.returnOnEquity ? `${(position.returnOnEquity * 100).toFixed(2)}%` : '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {totalPages > 1 && (
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="page-info">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    );
  };

  // Update the renderHLPDashboard function to include the charts
  const renderHLPDashboard = () => {
    console.log('Current vault data:', vaultData);
    console.log('Current metrics:', vaultMetrics);

    if (!vaultData || !vaultData.parent) {
      console.log('No vault data available');
      return <div>Loading vault data...</div>;
    }

    const cumulativeDelta = calculateCumulativeDelta(vaultData);
    const hlpPercentageOfOI = calculateHLPPercentageOfOI(vaultData, data.perpMarkets);

    const formatLargeNumber = (value) => {
      if (!value || isNaN(value)) return '-';
      const num = parseFloat(value);
      if (num >= 1000000) {
        return `$${(num / 1000000).toFixed(2)}M`;
      }
      return `$${num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    };

    // Get the latest account value from the history array
    const getLatestAccountValue = (vaultHistory) => {
      if (!vaultHistory || !Array.isArray(vaultHistory) || vaultHistory.length === 0) {
        return null;
      }
      return vaultHistory[vaultHistory.length - 1].accountValue;
    };

    return (
      <>
        <div style={{ color: '#666666', fontStyle: 'italic', marginBottom: '20px', textAlign: 'center' }}>
          Returns are estimated and may be incorrect. ASXN Dashboards are purely for informational purposes only. They are not intended to and should not be interpreted as investment or financial advice.
        </div>

        <div className="hlp-metrics-grid">
          <div className="hlp-metric-box">
            <h3>Total HLP TVL</h3>
            <p>{getLatestAccountValue(vaultData.parent?.history) ?
              formatLargeNumber(getLatestAccountValue(vaultData.parent?.history)) : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>HLP A TVL</h3>
            <p>{getLatestAccountValue(vaultData.hlpa?.history) ?
              formatLargeNumber(getLatestAccountValue(vaultData.hlpa?.history)) : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>HLP B TVL</h3>
            <p>{getLatestAccountValue(vaultData.hlpb?.history) ?
              formatLargeNumber(getLatestAccountValue(vaultData.hlpb?.history)) : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>Liquidator TVL</h3>
            <p>{getLatestAccountValue(vaultData.liquidator?.history) ?
              formatLargeNumber(getLatestAccountValue(vaultData.liquidator?.history)) : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>Cumulative HLP Return</h3>
            <p>{vaultMetrics?.cumulativeReturn != null ?
              `${vaultMetrics.cumulativeReturn.toFixed(2)}%` : '-'}</p>
          </div>
        </div>

        <div className="hlp-metrics-grid">
          <div className="hlp-metric-box">
            <h3>Max Drawdown</h3>
            <p>{vaultMetrics?.maxDrawdown != null ?
              `${vaultMetrics.maxDrawdown.toFixed(2)}%` : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>Past 90d Returns Annualized</h3>
            <p>{vaultMetrics?.annualizedLastWeekReturn != null ?
              `${vaultMetrics.annualizedLastWeekReturn.toFixed(2)}%` : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>Average Weekly Returns</h3>
            <p>{vaultMetrics?.averageWeeklyReturn != null ?
              `${vaultMetrics.averageWeeklyReturn.toFixed(2)}%` : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>Sharpe Ratio</h3>
            <p>{vaultMetrics?.sharpeRatio != null ?
              vaultMetrics.sharpeRatio.toFixed(2) : '-'}</p>
          </div>
          <div className="hlp-metric-box">
            <h3>Sortino Ratio</h3>
            <p>{vaultMetrics?.sortinoRatio != null ?
              vaultMetrics.sortinoRatio.toFixed(2) : '-'}</p>
          </div>
        </div>

        <div className="positions-grid">
          <PositionsTable positions={vaultData?.parent?.positions} title="HLP Parent Positions" />
          <PositionsTable positions={vaultData?.hlpa?.positions} title="HLP A Positions" />
          <PositionsTable positions={vaultData?.hlpb?.positions} title="HLP B Positions" />
          <PositionsTable positions={vaultData?.liquidator?.positions} title="HLP Liquidator Positions" />
        </div>

        <VaultCharts />

        {/* New separate container for HLP OI charts */}
        <div className="hlp-oi-charts-grid">
          <div className="hlp-oi-chart-container">
            <h3 className="hlp-oi-chart-title">Top 10 Assets by HLP OI</h3>
            <div className="hlp-oi-chart-wrapper">
              <ResponsiveContainer width="100%" height={400}>
                {renderBarChart(
                  prepareChartData(
                    hlpPercentageOfOI
                      .sort((a, b) => b.hlpNotional - a.hlpNotional)
                      .slice(0, 10),
                    'hlpNotional',
                    'desc'
                  ),
                  'coin',
                  'hlpNotional',
                  '#4caf50'
                )}
              </ResponsiveContainer>
            </div>
          </div>

          <div className="hlp-oi-chart-container">
            <h3 className="hlp-oi-chart-title">Top 10 Assets by HLP % of OI</h3>
            <div className="hlp-oi-chart-wrapper">
              <ResponsiveContainer width="100%" height={400}>
                {renderBarChart(
                  prepareChartData(
                    hlpPercentageOfOI
                      .sort((a, b) => b.percentage - a.percentage)
                      .slice(0, 10),
                    'percentage',
                    'desc'
                  ),
                  'coin',
                  'percentage',
                  '#2196f3'
                )}
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="hlp-table-section">
          {renderTable(cumulativeDelta, "HLP Cumulative Delta", [
            'coin',
            'size',
            'notional'
          ], {
            'coin': value => value || 'N/A',
            'size': value => value?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) || 'N/A',
            'notional': value => value ?
              (value < 0 ? `-$${Math.abs(value).toLocaleString()}` : `$${value.toLocaleString()}`)
              : 'N/A'
          }, {
            'coin': 'Asset',
            'size': 'Size',
            'notional': 'Notional Value'
          }, "risk-metrics-table", "hlpDelta")}
        </div>

        <div className="hlp-table-section">
          {renderTable(hlpPercentageOfOI, "HLP as % of OI", [
            'coin',
            'hlpNotional',
            'totalOI',
            'percentage'
          ], {
            'coin': value => value || 'N/A',
            'hlpNotional': value => formatDollar(value),
            'totalOI': value => formatDollar(value),
            'percentage': value => value?.toFixed(2) + '%' || 'N/A'
          }, {
            'coin': 'Asset',
            'hlpNotional': 'HLP OI',
            'totalOI': 'Total OI',
            'percentage': 'HLP % of OI'
          }, "risk-metrics-table", "hlpOIPercentage")}
        </div>
      </>
    );
  };

  const getVaultColor = (vaultKey) => {
    const colors = {
      parent: '#8884d8',
      hlpa: '#82ca9d',
      hlpb: '#ffc658',
      liquidator: '#ff7300'
    };
    return colors[vaultKey] || '#000000';
  };

  const calculateCumulativeReturn = (history) => {
    if (!history || history.length < 2) return 0;
    const firstValue = history[0].accountValue;
    const lastValue = history[history.length - 1].accountValue;
    return ((lastValue - firstValue) / firstValue * 100).toFixed(2);
  };

  if (loading) return <div className="loading">Loading risk metrics data...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="risk-metrics-dashboard">
      <h2 className="dashboard-title">Hyperliquid Risk Metrics</h2>
      {renderDashboardNav()}
      <div className="risk-metrics-grid">
        {activeDashboard === 'overview' && renderOverviewDashboard()}
        {activeDashboard === 'liquidity' && renderLiquidityDashboard()}
        {activeDashboard === 'funding' && renderFundingAndImpactDashboard()}
        {activeDashboard === 'hlp' && renderHLPDashboard()}
      </div>
    </div>
  );
};

export default HyperliquidRiskMetrics; 