// TwapData.js
import React, { useState, useEffect } from 'react';
import { ArrowUpDown, ChevronLeft, ChevronRight } from 'lucide-react';
import TwapProgressionChart from './TwapProgressionChart';
import TwapSummary from './twapSummary';
import './../../../styles/twap_data.css';

import api_url from "./../../../config/api_url.json";


const base_api_url = api_url.backend_api;

const hyperliquid_mainnet_api_url = api_url.hyperliquid_mainnet_api_url;

const hyperliquid_explorer_url = api_url.hyperliquid_explorer_url

const TwapData = ({ token_name }) => {
    const [activeTwaps, setActiveTwaps] = useState([]);
    const [tokenInfo, setTokenInfo] = useState({});
    const [selectedTwap, setSelectedTwap] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'asc'
    });

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageInput, setPageInput] = useState('');

    // const MIN_AMOUNT = 10000;

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 60000);
        return () => clearInterval(interval);
    }, []);

    const getTokenInfo = (assetId, universeData, tokensData) => {
        try {
            const adjustedAssetId = assetId - 10000;
            const universeEntry = universeData.universe.find(entry => entry.index === adjustedAssetId);

            if (!universeEntry) return { name: `Asset${assetId}`, universalName: null };

            const tokenIndex = universeEntry.tokens.find(t => t !== 0);
            if (!tokenIndex) return { name: `Asset${assetId}`, universalName: null };

            const token = tokensData.tokens.find(t => t.index === tokenIndex);

            return {
                name: token ? token.name : `Asset${assetId}`,
                universalName: universeEntry.name
            };
        } catch (error) {
            console.error('Error getting token info:', error);
            return { name: `Asset${assetId}`, universalName: null };
        }
    };

    const fetchData = async () => {
        try {
            // Fetch TWAP data
            const twapResponse = await fetch(`${base_api_url}/api/proxy/twap/${token_name}`);
            const twapData = await twapResponse.json();

            // Fetch token info
            const infoResponse = await fetch(`${hyperliquid_mainnet_api_url}/info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: "spotMeta" }),
            });
            const infoData = await infoResponse.json();

            // Fetch price data
            const priceResponse = await fetch(`${hyperliquid_mainnet_api_url}/info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: "allMids" }),
            });
            const priceData = await priceResponse.json();

            // Filter active TWAPs
            const activeOrders = twapData.filter(order =>
                !order.hasOwnProperty('ended') &&
                // parseFloat(order.action.twap.a) >= 10000
                parseFloat(order.action.twap.a) == 10107
            );

            console.log(activeOrders)

            // Process and format the data
            const processedTwaps = activeOrders.map(order => {
                const assetId = parseInt(order.action.twap.a);
                const tokenInfo = getTokenInfo(assetId, infoData, infoData);
                const amount = parseFloat(order.action.twap.s);

                // Calculate value using price from allMids
                // console.log(tokenInfo)

                let value = amount;
                if (tokenInfo.universalName && priceData[tokenInfo.universalName]) {
                    value = amount * parseFloat(priceData[tokenInfo.universalName]);
                }

                return {
                    side: order.action.twap.b ? 'BUY' : 'SELL',
                    value: value,
                    token: tokenInfo.name,
                    amount: amount,
                    from: order.user,
                    time: new Date(order.time).toLocaleString(),
                    minutes: order.action.twap.m,
                    progress: ((Date.now() - order.time) / (order.action.twap.m * 60000) * 100).toFixed(1),
                    reduceOnly: order.action.twap.r,
                    postOnly: order.action.twap.t
                };
            });

            // console.log("processedTwaps", processedTwaps)
            setActiveTwaps(processedTwaps);
            setTokenInfo(infoData.tokens);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortedAndPaginatedTwaps = () => {
        // First sort the entire dataset
        const sorted = [...activeTwaps].sort((a, b) => {
            if (!sortConfig.key) return 0;

            // Get raw values for comparison
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            // Handle special cases
            switch (sortConfig.key) {
                case 'value':
                case 'amount':
                    // Convert string values to numbers if they're strings
                    aValue = typeof aValue === 'string' ? parseFloat(aValue) : aValue;
                    bValue = typeof bValue === 'string' ? parseFloat(bValue) : bValue;
                    break;

                case 'progress':
                    // // Option 1: Sort by progress percentage
                    // aValue = parseFloat(a.progress);
                    // bValue = parseFloat(b.progress);

                    // Option 2: Sort by remaining time
                    const aRemaining = a.minutes * (1 - parseFloat(a.progress) / 100);
                    const bRemaining = b.minutes * (1 - parseFloat(b.progress) / 100);
                    aValue = aRemaining;
                    bValue = bRemaining;
                    break;

                case 'minutes':
                    // Ensure minutes are compared as numbers
                    aValue = parseInt(aValue);
                    bValue = parseInt(bValue);
                    break;

                case 'time':
                    // Convert time strings to Date objects for comparison
                    aValue = new Date(aValue);
                    bValue = new Date(bValue);
                    break;

                default:
                    // For string comparisons (side, token, from)
                    aValue = aValue.toString().toLowerCase();
                    bValue = bValue.toString().toLowerCase();
                    break;
            }

            // Compare the values
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        // Then paginate the sorted data
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return sorted.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(activeTwaps.length / rowsPerPage);

    const getPageRange = () => {
        const range = [];
        const showAround = 2; // Number of pages to show around current page

        // Always show first page
        range.push(1);

        // Calculate range around current page
        for (let i = Math.max(2, currentPage - showAround);
            i <= Math.min(totalPages - 1, currentPage + showAround); i++) {
            if (range[range.length - 1] !== i - 1) {
                range.push('...');
            }
            range.push(i);
        }

        // Show last page if not already included
        if (totalPages > 1) {
            if (range[range.length - 1] !== totalPages - 1) {
                if (range[range.length - 1] !== '...') {
                    range.push('...');
                }
            }
            range.push(totalPages);
        }

        return range;
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setPageInput(newPage.toString()); // Update input to reflect current page
        setSelectedTwap(null);
        setSelectedRowIndex(null);
    };

    // Initialize pageInput with current page in useEffect
    useEffect(() => {
        setPageInput(currentPage.toString());
    }, [currentPage]);


    const handleRowClick = (twap, index) => {
        if (selectedRowIndex === index) {
            // If clicking the same row, deselect it
            setSelectedTwap(null);
            setSelectedRowIndex(null);
        } else {
            // Select the new row
            setSelectedTwap(twap);
            setSelectedRowIndex(index);
        }
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(2)}M`;
        } else if (num >= 1000) {
            return `${(num / 1000).toFixed(2)}K`;
        }
        return num.toFixed(2);
    };

    const formatDuration = (minutes) => {
        if (minutes < 1) {
            return `${Math.round(minutes * 60)}s`;
        } else if (minutes < 60) {
            return `${Math.round(minutes)}m`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = Math.round(minutes % 60);
            return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
        }
    };

    const calculateTimeRemaining = (totalMinutes, progress) => {
        const remainingMinutes = totalMinutes * (1 - progress / 100);
        if (remainingMinutes < 1 / 60) {
            return 'Almost done';
        }
        return `${formatDuration(remainingMinutes)} remaining`;
    };

    const SortButton = ({ column, isActive, direction }) => (
        <div className={`sort-button ${isActive ? 'active' : ''}`}>
            <ArrowUpDown
                style={{
                    transform: isActive && direction === 'desc' ? 'rotate(180deg)' : 'none'
                }}
            />
            {isActive && (
                <span className="sort-direction-indicator">
                    ({direction === 'asc' ? '↑' : '↓'})
                </span>
            )}
        </div>
    );

    const TableHeader = ({ column, label }) => {
        const isActive = sortConfig.key === column;

        return (
            <th onClick={() => handleSort(column)}>
                <div className={`th-content ${isActive ? 'active' : ''}`}>
                    {label}
                    <SortButton
                        column={column}
                        isActive={isActive}
                        direction={sortConfig.direction}
                    />
                </div>
            </th>
        );
    };

    // Updated page input handler
    const handlePageInput = (e) => {
        const value = e.target.value;
        setPageInput(value);

        // Convert to number and validate
        const pageNumber = parseInt(value, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            handlePageChange(pageNumber);
        }
    };

    return (
        <div className="twap-container">
            <header className="twap-header">
                <h3 className="twap-title">Active TWAPs ({activeTwaps.length})</h3>
                <div className="pagination-controls">
                    <select
                        value={rowsPerPage}
                        onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        className="rows-per-page-select"
                    >
                        <option value={5}>5 per page</option>
                        <option value={10}>10 per page</option>
                        <option value={20}>20 per page</option>
                        <option value={50}>50 per page</option>
                        <option value={activeTwaps.length}>All</option>

                    </select>
                </div>
            </header>
            <TwapSummary activeTwaps={activeTwaps} />
            <div className="twap-table-container">
                <table className="twap-table">
                    <thead>
                        <tr>
                            <TableHeader column="side" label="Side" />
                            <TableHeader column="token" label="Token" />
                            <TableHeader column="amount" label="Amount" />
                            <TableHeader column="value" label="Value (USD)" />
                            <TableHeader column="from" label="From" />
                            <TableHeader column="minutes" label="Duration" />
                            <TableHeader column="progress" label="Progress" />
                        </tr>
                    </thead>
                    <tbody>
                        {getSortedAndPaginatedTwaps().map((twap, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    onClick={() => handleRowClick(twap, index)}
                                    className={`twap-row ${selectedRowIndex === index ? 'selected' : ''}`}
                                >
                                    <td className={`side ${twap.side.toLowerCase()}`}>
                                        {twap.side}
                                    </td>
                                    <td>{twap.token}</td>
                                    <td>{formatNumber(twap.amount)}</td>
                                    <td>${formatNumber(twap.value)}</td>
                                    <td>
                                        <a
                                            href={`${hyperliquid_explorer_url}/address/${twap.from}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="address-link"
                                            onClick={e => e.stopPropagation()}
                                        >
                                            {`${twap.from.slice(0, 6)}...${twap.from.slice(-8)}`}
                                        </a>
                                    </td>
                                    <td>{formatDuration(twap.minutes)}</td>
                                    <td>
                                        <div className="progress-container">
                                            <div className="progress-bar-container">
                                                <div
                                                    className="progress-bar_twap"
                                                    style={{
                                                        width: `${Math.min(100, Math.max(0, twap.progress))}%`
                                                    }}
                                                />
                                            </div>
                                            <div className="progress-info">
                                                <span className="progress-percentage">
                                                    {Math.min(100, Math.max(0, twap.progress))}%
                                                </span>
                                                <span className="progress-time">
                                                    {calculateTimeRemaining(twap.minutes, twap.progress)}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {selectedRowIndex === index && (
                                    <tr className="chart-row">
                                        <td colSpan="7" className="chart-cell">
                                            <TwapProgressionChart twapData={twap} />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                        {activeTwaps.length === 0 && (
                            <tr>
                                <td colSpan="7" className="no-data">
                                    No active TWAPs found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Pagination Controls */}
            <div className="pagination-footer">
                <div className="pagination-info">
                    Showing {Math.min(rowsPerPage, activeTwaps.length)} of {activeTwaps.length} entries
                </div>
                <div className="pagination-controls">
                    <div className="pagination-buttons">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="pagination-button"
                        >
                            <ChevronLeft className="w-4 h-4" />
                        </button>

                        {getPageRange().map((page, index) => (
                            page === '...' ? (
                                <span key={`ellipsis-${index}`} className="pagination-ellipsis">...</span>
                            ) : (
                                <button
                                    key={page}
                                    onClick={() => handlePageChange(page)}
                                    className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                >
                                    {page}
                                </button>
                            )
                        ))}

                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="pagination-button"
                        >
                            <ChevronRight className="w-4 h-4" />
                        </button>
                    </div>
                    <div className="pagination-input">
                        <input
                            type="number"
                            min="1"
                            max={totalPages}
                            value={pageInput}
                            onChange={handlePageInput}
                            placeholder="Page"
                            className="page-number-input"
                            onBlur={() => {
                                // Reset to current page if input is invalid
                                if (!pageInput || parseInt(pageInput) < 1 || parseInt(pageInput) > totalPages) {
                                    setPageInput(currentPage.toString());
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwapData;