import React, { useState, useEffect } from 'react';
import TokenStakingPage from './tokenStakingPage';
import HolderHistoryChart from './uniqueStakeovertimechart';
import StakeDistributionChart from './stakeDistributionChart';
import token from "./../../../config/token_dashboard.json";
import TwapData from './tawpData';
import StakeDistributionPieChart from './stakeDistributionPieChart';
import "./../../../styles/stHype/staking/stHypeStakingComponent.css"
import AirdropMetrics from './airdropMetrics';
import WalletChecker from './walletChecker';
import HypeGenesisAnalysisTopWallet from './hypeGenesisAnalysisTopWallet';
import api_url from "./../../../config/api_url.json";

const base_api_url = api_url.backend_api;

const token_name = token.HypeDashboard_token_name;

const HypeTokenComponent = () => {
    const [stakingData, setStakingData] = useState(null);
    const [historicalData, setHistoricalData] = useState({});
    const [currentHolderData, setCurrentHolderData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hyperAnalysisData, setHyperAnalysisData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                // Fetch historical data
                const historicalResponse = await fetch(`${base_api_url}/api/proxy/holdersStats`);
                if (!historicalResponse.ok) throw new Error('Failed to fetch historical data');
                const historicalData = await historicalResponse.json();
                setHistoricalData(historicalData);


                const now = Math.floor(Date.now() / 1000);
                // Fetch current staking data
                const stakingResponse = await fetch(`${base_api_url}/api/proxy/holdersAtTime/${token_name}/${now}`);
                if (!stakingResponse.ok) throw new Error('Failed to fetch staking data');
                const stakingData = await stakingResponse.json();

                // Convert holders object to sorted array
                const holdersArray = Object.entries(stakingData.holders)
                    .map(([address, amount]) => ({ address, amount }))
                    .sort((a, b) => b.amount - a.amount)
                    .slice(0, 100);

                // fetch hyperAnalysis data
                const hyperAnalysis_Data = await fetch(`${base_api_url}/api/hype-wallet-analysis`);
                if (!hyperAnalysis_Data.ok) throw new Error('Failed to fetch hyper analysis data');
                const hyperAnalysis_Data_ = await hyperAnalysis_Data.json();

                setHyperAnalysisData(hyperAnalysis_Data_);

                setHistoricalData(historicalData);


                setStakingData({
                    ...stakingData,
                    sortedHolders: holdersArray
                });

                setCurrentHolderData(stakingData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // Set up polling every 5 minutes
        const interval = setInterval(fetchData, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return <div className="stHype_staking_loading">Loading...</div>;
    }

    if (error) {
        return <div className="stHype_staking_error">{error}</div>;
    }

    console.log("historicalData:", historicalData)

    console.log("hyperAnalysisData:", hyperAnalysisData)
    return (
        <div className='stHype_staking_dashboard'>
            {/* <WalletChecker /> */}

            {/* <AirdropMetrics airdropData={stakingData} /> */}

            {/* <div className="airdrop-metrics-header">
                <h2>Hyper Metrics</h2>
            </div> */}

            <TwapData
                // token_name={token_name}
                token_name={'*'}

            />


            {/* Top Section with Card and Table */}
            <TokenStakingPage
                stakingData={stakingData}
                historicalData={historicalData}
                currentHolderData={currentHolderData}
                token_name={token_name}
            />

            {/* Historical Holders Chart */}
            <div className="stHype_staking_charts_grid">
                <div className="stHype_staking_chart_left">
                    <StakeDistributionChart
                        stakingData={currentHolderData}
                    />

                </div>
                <div className="stHype_staking_chart_right">
                    <StakeDistributionPieChart stakingData={stakingData} />
                </div>
            </div>

            <div className="stHype_staking_chart_section">
                {/* <HolderHistoryChart
                    historicalData={historicalData}
                    token={token_name}
                /> */}
            </div>
            <br />
            <br />
            <HypeGenesisAnalysisTopWallet data={hyperAnalysisData} />
            <br />
            <br />

            {/* <StakeDistributionPieChart stakingData={stakingData} /> */}
            <div className="data-source">Source: Hyperliquid & Hypurrscan</div>
            <br />
        </div>
    );
};

export default HypeTokenComponent;