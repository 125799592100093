import React, { useState } from 'react';
import { PartyPopper, Search, X, AlertCircle } from 'lucide-react';
import "./../../../styles/stHype/staking/wallet_checker.css"
import api_url from "./../../../config/api_url.json";

const base_api_url = api_url.backend_api;

const WalletChecker = () => {
    const [walletInput, setWalletInput] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        setWalletInput(e.target.value);
        setError('');
    };

    const handleSubmit = async () => {
        const wallets = walletInput
            .split('\n')
            .map(w => w.trim())
            .filter(w => w !== '');

        if (wallets.length > 10) {
            setError('Maximum 10 wallet addresses allowed');
            return;
        }

        if (wallets.length === 0) {
            setError('Please enter at least one wallet address');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${base_api_url}/api/check-wallets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ wallets })
            });

            const data = await response.json();
            setSearchResults(data.results);
        } catch (err) {
            setError('Failed to check wallets. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const clearResults = () => {
        setSearchResults(null);
        setWalletInput('');
        setError('');
    };

    const getEligibleWallets = () => {
        if (!searchResults) return [];
        return Object.entries(searchResults).filter(([_, data]) => data.found);
    };

    const getTotalAmount = () => {
        if (!searchResults) return 0;
        return Object.values(searchResults)
            .reduce((sum, data) => sum + data.amount, 0);
    };

    return (
        <div className="wallet-checker">
            <div className="wallet-checker-header">
                <h2>Check Your Airdrop Eligibility</h2>
                <p>Enter up to 10 wallet addresses (one per line)</p>
            </div>

            {!searchResults && (
                <div className="wallet-checker-input-section">
                    <textarea
                        value={walletInput}
                        onChange={handleInputChange}
                        placeholder="Enter wallet addresses here..."
                        rows={5}
                        className="wallet-input"
                    />
                    {error && (
                        <div className="error-message">
                            <AlertCircle size={16} />
                            {error}
                        </div>
                    )}
                    <button
                        onClick={handleSubmit}
                        disabled={isLoading}
                        className="check-button"
                    >
                        {isLoading ? 'Checking...' : (
                            <>
                                <Search size={16} />
                                Check Wallets
                            </>
                        )}
                    </button>
                </div>
            )}

            {searchResults && (
                <div className="results-section">
                    {getEligibleWallets().length > 0 && (
                        <div className="success-banner">
                            <PartyPopper size={24} />
                            <div className="success-message">
                                <h3>Congratulations!</h3>
                                <p>You received {getTotalAmount().toLocaleString()} HYPE tokens across {getEligibleWallets().length} wallet(s)</p>
                            </div>
                        </div>
                    )}

                    <div className="results-list">
                        {Object.entries(searchResults).map(([wallet, data]) => (
                            <div key={wallet} className={`result-item ${data.found ? 'eligible' : 'not-eligible'}`}>
                                <div className="wallet-address">{wallet}</div>
                                <div className="amount">
                                    {data.found ? (
                                        <span className="eligible-amount">{data.amount.toLocaleString()} HYPE</span>
                                    ) : (
                                        <span className="not-eligible-text">Not Eligible</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <button onClick={clearResults} className="clear-button">
                        <X size={16} />
                        Check Different Wallets
                    </button>
                </div>
            )}
        </div>
    );
};

export default WalletChecker;