import React, { useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

// Main component
const MonadProviderDailyTotalStakedCharts = ({ data }) => {
    const [chartsPerRow, setChartsPerRow] = useState(4);

    // Set provider data from props
    const providerData = data || {};

    const formatData = (providerStakedData) => {
        if (!providerStakedData || !providerStakedData.total_staked_data) return [];

        return providerStakedData.total_staked_data.map(item => ({
            date: new Date(item.date).toISOString().split('T')[0],
            value: item.total_staked
        }));
    };

    // Updated date formatter to show DD/MM/YYYY format
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Shorter date format for axis labels to avoid crowding
    const formatAxisDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        // return `${day}/${month}/${year}`;
        return `${day}/${month}`;
    };

    const formatValue = (value) => {
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(1);
    };

    const getRandomColor = (index) => {
        const colors = [
            '#2E8B57', // Sea Green
            '#FF6B6B', // Coral Red
            '#4ECDC4', // Turquoise
            '#FFB347', // Pastel Orange
            '#9FE2BF', // Seafoam Green
            '#845EC2'  // Purple
        ];
        return colors[index % colors.length];
    };

    if (!data || Object.keys(data).length === 0) {
        return <div className="loading">No provider data available</div>;
    }

    // Filter out providers with no data (all zeros)
    const activeProviders = Object.entries(providerData).filter(([_, data]) => {
        if (!data.total_staked_data) return false;
        return data.total_staked_data.some(item => item.total_staked > -1);
    });

    return (
        <div className="monad-provider-daily-total-staked-charts">
            <div className="chart-controls">
                <div className="chart-title">Monad LST TVL by each provider</div>
                <div className="layout-selector">
                    <label>Charts per row:</label>
                    <select
                        value={chartsPerRow}
                        onChange={(e) => setChartsPerRow(Number(e.target.value))}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                    </select>
                </div>
            </div>

            <div
                className="charts-grid"
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${chartsPerRow}, 1fr)`,
                    gap: '20px'
                }}
            >
                {activeProviders.map(([token_symbol, data], index) => (
                    <div key={token_symbol} className="chart-card">
                        <div className="chart-header">
                            <div className="chart-name">{data.name}</div>
                            <div className="chart-symbol">{token_symbol}</div>
                        </div>
                        <div className="chart-container">
                            <ResponsiveContainer width="90%" height={220}>
                                <AreaChart data={formatData(data)}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={formatAxisDate}
                                        tick={{ fontSize: 10 }}
                                        stroke="rgba(0,0,0,0.3)"
                                    />
                                    <YAxis
                                        tickFormatter={formatValue}
                                        tick={{ fontSize: 10 }}
                                        stroke="rgba(0,0,0,0.3)"
                                    />
                                    <Tooltip
                                        formatter={(value) => [`${formatValue(value)} MON`, 'Total Staked']}
                                        labelFormatter={formatDate}
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="value"
                                        stroke={getRandomColor(index)}
                                        fill={getRandomColor(index)}
                                        fillOpacity={0.7}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="chart-footer">
                            {data.total_staked_data && data.total_staked_data.length > 0 && (
                                <div className="current-staked">
                                    Current: {formatValue(data.total_staked_data[data.total_staked_data.length - 1].total_staked)} MON
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <style jsx>{`
        .monad-provider-daily-total-staked-charts {
          font-family: var(--app-font-family);
          color: var(--text-color);
        }
        
        .chart-controls {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        
        .chart-title {
          font-size: 18px;
          font-weight: bold;
          color: var(--primary-color);
        }
        
        .layout-selector {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        
        .layout-selector select {
          padding: 5px 10px;
          border: 1px solid var(--border-color);
          border-radius: 4px;
          background-color: white;
        }
        
        .chart-card {
          border: 1px solid var(--border-color);
          border-radius: 8px;
          overflow: hidden;
          background-color: var(--card-background-color);
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        
        .chart-header {
          padding: 10px 15px;
          border-bottom: 1px solid var(--border-color);
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        
        .chart-name {
          font-weight: bold;
          font-size: 14px;
        }
        
        .chart-symbol {
          font-size: 12px;
          color: var(--primary-color);
          background-color: rgba(0, 36, 117, 0.1);
          padding: 2px 8px;
          border-radius: 12px;
        }
        
        .chart-container {
          padding: 15px;
          height: 220px;
        }
        
        .chart-footer {
          padding: 10px 15px;
          border-top: 1px solid var(--border-color);
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }
        
        .current-staked {
          font-weight: bold;
        }
        
        .loading {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px;
          font-size: 16px;
          color: var(--primary-color);
        }
      `}</style>
        </div>
    );
};

export default MonadProviderDailyTotalStakedCharts;