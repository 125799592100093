// TwapData.js
import React, { useState, useEffect } from 'react';
import TwapProgressionChart from './TwapProgressionChart';
import './../styles/twap_data.css';

// import api_url from "./../../config/api_and_url.json"


// const base_api = api_url.base_api
// const API_URL = base_api + '/trade_history';

const TwapData = () => {
    const [activeTwaps, setActiveTwaps] = useState([]);
    const [tokenInfo, setTokenInfo] = useState({});
    const [selectedTwap, setSelectedTwap] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    // const MIN_AMOUNT = 10000;

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 60000);
        return () => clearInterval(interval);
    }, []);

    const getTokenInfo = (assetId, universeData, tokensData) => {
        try {
            const adjustedAssetId = assetId - 10000;
            const universeEntry = universeData.universe.find(entry => entry.index === adjustedAssetId);

            if (!universeEntry) return { name: `Asset${assetId}`, universalName: null };

            const tokenIndex = universeEntry.tokens.find(t => t !== 0);
            if (!tokenIndex) return { name: `Asset${assetId}`, universalName: null };

            const token = tokensData.tokens.find(t => t.index === tokenIndex);

            return {
                name: token ? token.name : `Asset${assetId}`,
                universalName: universeEntry.name
            };
        } catch (error) {
            console.error('Error getting token info:', error);
            return { name: `Asset${assetId}`, universalName: null };
        }
    };

    const fetchData = async () => {
        try {
            // Fetch TWAP data
            const twapResponse = await fetch('https://api-data.asxn.xyz/api/proxy/twap/*');
            const twapData = await twapResponse.json();

            // Fetch token info
            const infoResponse = await fetch('https://api.hyperliquid.xyz/info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: "spotMeta" }),
            });
            const infoData = await infoResponse.json();

            // Fetch price data
            const priceResponse = await fetch('https://api.hyperliquid.xyz/info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type: "allMids" }),
            });
            const priceData = await priceResponse.json();

            // Filter active TWAPs
            const activeOrders = twapData.filter(order =>
                !order.hasOwnProperty('ended') &&
                parseFloat(order.action.twap.a) >= 10000
            );

            console.log(activeOrders)

            // Process and format the data
            const processedTwaps = activeOrders.map(order => {
                const assetId = parseInt(order.action.twap.a);
                const tokenInfo = getTokenInfo(assetId, infoData, infoData);
                const amount = parseFloat(order.action.twap.s);

                // Calculate value using price from allMids
                console.log(tokenInfo)

                let value = amount;
                if (tokenInfo.universalName && priceData[tokenInfo.universalName]) {
                    value = amount * parseFloat(priceData[tokenInfo.universalName]);
                }


                return {
                    side: order.action.twap.b ? 'BUY' : 'SELL',
                    value: value,
                    token: tokenInfo.name,
                    amount: amount,
                    from: order.user,
                    time: new Date(order.time).toLocaleString(),
                    minutes: order.action.twap.m,
                    progress: ((Date.now() - order.time) / (order.action.twap.m * 60000) * 100).toFixed(1),
                    reduceOnly: order.action.twap.r,
                    postOnly: order.action.twap.t
                };
            });

            // Sort by value (descending)
            const sortedTwaps = processedTwaps.sort((a, b) => b.value - a.value);

            setActiveTwaps(sortedTwaps);
            setTokenInfo(infoData.tokens);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleRowClick = (twap, index) => {
        if (selectedRowIndex === index) {
            // If clicking the same row, deselect it
            setSelectedTwap(null);
            setSelectedRowIndex(null);
        } else {
            // Select the new row
            setSelectedTwap(twap);
            setSelectedRowIndex(index);
        }
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(2)}M`;
        } else if (num >= 1000) {
            return `${(num / 1000).toFixed(2)}K`;
        }
        return num.toFixed(2);
    };

    const formatDuration = (minutes) => {
        if (minutes < 1) {
            return `${Math.round(minutes * 60)} s`;
        } else if (minutes < 60) {
            return `${Math.round(minutes)} m`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = Math.round(minutes % 60);
            return remainingMinutes > 0 ? `${hours}h ${remainingMinutes} m` : `${hours} h`;
        }
    };

    const calculateTimeRemaining = (totalMinutes, progress) => {
        const remainingMinutes = totalMinutes * (1 - progress / 100);
        if (remainingMinutes < 1 / 60) {
            return 'Almost done';
        }
        return `${formatDuration(remainingMinutes)} remaining`;
    };

    return (
        <div className="twap-container">
            <header className="twap-header">
                <h3 className="twap-title">Active TWAPs</h3>
            </header>
            <div className="twap-table-container">
                <table className="twap-table">
                    <thead>
                        <tr>
                            <th>Side</th>
                            <th>Token</th>
                            <th>Amount</th>
                            <th>Value (USD)</th>
                            <th>From</th>
                            <th>Duration</th>
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeTwaps.map((twap, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    onClick={() => handleRowClick(twap, index)}
                                    className={`twap-row ${selectedRowIndex === index ? 'selected' : ''}`}
                                >
                                    <td className={`side ${twap.side.toLowerCase()}`}>
                                        {twap.side}
                                    </td>
                                    <td>{twap.token}</td>
                                    <td>{formatNumber(twap.amount)}</td>
                                    <td>${formatNumber(twap.value)}</td>
                                    <td>
                                        <a
                                            href={`https://etherscan.io/address/${twap.from}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="address-link"
                                            onClick={e => e.stopPropagation()}
                                        >
                                            {`${twap.from.slice(0, 6)}...${twap.from.slice(-8)}`}
                                        </a>
                                    </td>
                                    <td>{formatDuration(twap.minutes)}</td>
                                    <td>
                                        <div className="progress-container">
                                            <div className="progress-bar-container">
                                                <div
                                                    className="progress-bar"
                                                    style={{
                                                        width: `${Math.min(100, Math.max(0, twap.progress))}%`
                                                    }}
                                                />
                                            </div>
                                            <div className="progress-info">
                                                <span className="progress-percentage">
                                                    {Math.min(100, Math.max(0, twap.progress))}%
                                                </span>
                                                <span className="progress-time">
                                                    {calculateTimeRemaining(twap.minutes, twap.progress)}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {selectedRowIndex === index && (
                                    <tr className="chart-row">
                                        <td colSpan="7" className="chart-cell">
                                            <TwapProgressionChart twapData={twap} />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                        {activeTwaps.length === 0 && (
                            <tr>
                                <td colSpan="7" className="no-data">
                                    No active TWAPs found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TwapData;