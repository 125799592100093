import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Text } from 'recharts';
// Add this line to import the CSS file
import '../styles/Dashboard.css';
import HLSpotEcosystemChart from './HLSpotEcosystemChart';
import HLAuctionsChart from './HLAuctionsChart';
import Papa from 'papaparse';
import HypeBurnDashboard from './HypeBurnDashboard';

export const dashboardConfig = {
  'all-chains-fees': {
    title: 'Chains Fees',
    dataPath: 'all_chains_fees',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Category', header: 'Category' },
      { key: 'Fees Earned (USD)', header: 'Fees Earned (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'derivatives-fees': {
    title: 'Derivatives Fees',
    dataPath: 'derivatives_fees',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Fees Earned (USD)', header: 'Fees Earned (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'derivatives-volumes': {
    title: 'Derivatives Volumes',
    dataPath: 'derivatives_volumes',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Volume (USD)', header: 'Volume (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'dex-fees': {
    title: 'DEX Fees',
    dataPath: 'dex_fees',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Fees Earned (USD)', header: 'Fees Earned (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'dex-volumes': {
    title: 'DEX Volumes',
    dataPath: 'dex_volumes',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Volume (USD)', header: 'Volume (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'lending-fees': {
    title: 'Lending Fees',
    dataPath: 'lending_fees',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Fees Earned (USD)', header: 'Fees Earned (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'all-protocols-fees': {
    title: 'Protocols Fees',
    dataPath: 'all_protocols_fees',
    columns: [
      { key: 'Protocol', header: 'Protocol' },
      { key: 'Category', header: 'Category' },
      { key: 'Fees Earned (USD)', header: 'Fees Earned (USD)', format: 'currency' },
      { key: '% Change', header: '% Change', format: 'percent' }
    ]
  },
  'hl-spot-ecosystem': {
    title: 'Hyperliquid Spot Ecosystem',
    dataPath: 'hl_spot_ecosystem/spot_market.csv',
    singleFile: true,
    columns: [
      { key: 'Name', header: 'Name' },
      { key: 'Price', header: 'Price', format: 'currency' },
      { key: 'Daily Volume', header: 'Daily Volume', format: 'currency' },
      { key: 'Circulating Supply', header: 'Circulating Supply', format: 'number' },
      { key: 'Market Cap', header: 'Market Cap', format: 'currency' },
      { key: 'Market Share', header: 'Market Share', format: 'percent' }
    ]
  },
  'hl-auctions': {
    title: 'Hyperliquid Auctions',
    singleFile: true,
    columns: [
      { key: 'Time', header: 'Time' },
      { key: 'Name', header: 'Name' },
      { key: 'USDC Cost', header: 'USDC Cost', format: 'currency' },
      { key: 'Deployer', header: 'Deployer' }
    ]
  },
  'hype-burn': {
    title: 'HYPE Burn Analytics',
    dataPath: 'hype_burn',
    singleFile: true,
    customComponent: true
  }
};

const formatValue = (value, format) => {
  if (value === undefined || value === null) return '-';

  switch (format) {
    case 'currency':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        notation: 'standard'
      }).format(Math.round(value));

    case 'percent':
      return `${parseFloat(value).toFixed(2)}%`;

    case 'number':
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        notation: 'standard'
      }).format(Math.round(value));

    default:
      return value.toString();
  }
};

const formatMillions = (value) => {
  const millions = value / 1000000;
  return `$${millions.toFixed(1)}M`;
};

export const formatChartValue = (value) => {
  const absValue = Math.abs(value);
  if (absValue >= 1000000000) {
    return `$${(value / 1000000000).toFixed(1)}B`;
  } else if (absValue >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else if (absValue >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  } else {
    return `$${value.toFixed(2)}`;
  }
};

const DataTable = ({ data, columns, requestSort, sortConfig }) => (
  <table className="data-table">
    <thead>
      <tr>
        {columns.map((column) => {
          let className = column.key.toLowerCase().replace(/ /g, '-');
          if (column.format === 'currency') className += ' volume';
          if (column.format === 'percent') className += ' percent-change';
          if (column.key === 'Category') className += ' category';

          return (
            <th
              key={column.key}
              className={className}
              onClick={() => column.format === 'currency' || column.format === 'percent' ? requestSort(column.key) : null}
              style={{ cursor: column.format === 'currency' || column.format === 'percent' ? 'pointer' : 'default' }}
            >
              {column.header}
              {sortConfig.key === column.key && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
            </th>
          );
        })}
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          {columns.map((column) => {
            let className = column.key.toLowerCase().replace(/ /g, '-');
            if (column.format === 'currency') className += ' volume';
            if (column.format === 'percent') className += ' percent-change';
            if (column.key === 'Category') className += ' category';

            const value = item[column.header];

            return (
              <td
                key={column.key}
                className={className}
              >
                {column.format ? formatValue(value, column.format) : value}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

const formatCellValue = (value, format) => {
  switch (format) {
    case 'currency':
      return formatValue(value);
    case 'percent':
      return `${parseFloat(value).toFixed(2)}%`;
    default:
      return value;
  }
};

const DataChart = ({ data, id }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Get Y-axis title based on chart type
  const getYAxisTitle = (id) => {
    if (id.includes('volumes')) {
      return 'Trading Volume (USD)';
    } else if (id.includes('fees')) {
      return 'Fees (USD)';
    } else if (id.includes('etf-flows')) {
      return 'Net Flow (USD)';
    }
    return '';
  };

  // Calculate the Y-axis domain and ticks
  const { yAxisDomain, yAxisTicks } = useMemo(() => {
    if (id === 'btc-etf-flows') {
      return {
        yAxisDomain: [-400, 1000],
        yAxisTicks: [-400, -200, 0, 200, 400, 600, 800, 1000]
      };
    } else {
      const maxValue = Math.max(...data.map(item => item.value));
      const bufferedMax = maxValue * 1.2; // Apply 20% buffer for other charts
      const tickCount = 7; // Number of ticks (including min and max)
      const step = bufferedMax / (tickCount - 1);
      return {
        yAxisDomain: [0, bufferedMax],
        yAxisTicks: Array.from({ length: tickCount }, (_, i) => i * step)
      };
    }
  }, [data, id]);

  const formatChartValue = (value) => {
    const absValue = Math.abs(value);
    if (absValue >= 1000000000) {
      return `$${(value / 1000000000).toFixed(1)}B`;
    } else if (absValue >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (absValue >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    } else {
      return `$${value.toFixed(2)}`;
    }
  };

  const formatTickValue = (value) => {
    if (id === 'btc-etf-flows') {
      return `${Math.round(value)}M`;
    }
    return formatChartValue(value);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let formattedValue;
      if (id === 'btc-etf-flows') {
        formattedValue = `$${Math.round(payload[0].value)}M`;
      } else {
        formattedValue = formatChartValue(payload[0].value);
      }

      return (
        <div className="custom-tooltip" style={{
          backgroundColor: 'white',
          padding: '10px',
          border: '1px solid #ccc',
          fontFamily: 'Roboto Mono, monospace'
        }}>
          <p className="label" style={{ margin: '0 0 5px 0' }}>{`${label}`}</p>
          <p className="intro" style={{
            color: payload[0].value >= 0 ? '#002475' : '#d32f2f',
            fontWeight: 'bold',
            margin: '0'
          }}>
            {`Value: ${formattedValue}`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chart-container">
      <div
        className="chart-watermark"
        style={{
          position: 'absolute',
          top: '40%',
          left: '65%',
          transform: 'translate(-50%, -50%)',
          fontSize: '40px',
          opacity: 0.1,
          pointerEvents: 'none',
          zIndex: 1,
          fontFamily: 'Libre Baskerville, serif',
          color: '#002475',
          userSelect: 'none'
        }}
      >
        ASXN
      </div>
      <ResponsiveContainer width="100%" height={800}>
        <BarChart
          data={data}
          margin={{ top: 40, right: 30, left: 80, bottom: 50 }} // Increased left margin
          style={{ fontFamily: 'Roboto Mono, monospace' }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setHoveredIndex(state.activeTooltipIndex);
            } else {
              setHoveredIndex(null);
            }
          }}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <CartesianGrid
            strokeDasharray="10 10"
            horizontal={true}
            vertical={false}
            stroke="#e0e0e0"
          />
          <XAxis
            dataKey="name"
            tick={{ fontSize: 14, fontFamily: 'Roboto Mono, monospace' }}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
          />
          <YAxis
            tickFormatter={formatTickValue}
            tick={{
              fontSize: id === 'btc-etf-flows' ? 12 : 14,
              fontFamily: 'Roboto Mono, monospace'
            }}
            domain={yAxisDomain}
            ticks={yAxisTicks}
            label={{
              value: getYAxisTitle(id),
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
                fontFamily: 'Roboto Mono, monospace',
                fontSize: 16,
                fill: '#666666'
              },
              offset: -60
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="value">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.value >= 0 ? "#002475" : "#d32f2f"}
                fillOpacity={hoveredIndex === null || hoveredIndex === index ? 1 : 0.3}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const prepareChartData = (data) => {
  return data.map(item => ({
    name: item.Protocol || item.name,
    value: parseFloat(item['Fees Earned (USD)'] || item['Volume (USD)'] || 0)
  }));
};

const getDataSource = (id) => {
  if (id.startsWith('hl-')) {
    return 'Hyperliquid, hypurrscan.io';
  } else if (id.includes('etf')) {
    return 'Farside';
  } else {
    return 'DeFiLlama';
  }
};

const Dashboard = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePeriod, setActivePeriod] = useState('24h');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const config = dashboardConfig[id];

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === '% Change') {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let response;
        const path = config.dataPath || id;
        const url = config.singleFile
          ? `/api/data/${id}`
          : `/api/data/${path}/${activePeriod}`;

        console.log('Fetching data from:', url);
        console.log('Config:', config);

        response = await fetch(url);

        if (!response.ok) {
          console.error('Response not OK:', {
            status: response.status,
            statusText: response.statusText,
            url: response.url
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log('Received data:', {
          length: jsonData.length,
          firstRow: jsonData[0],
          columns: config.columns
        });

        setData(jsonData);
        setError(null);
      } catch (error) {
        console.error(`Failed to load data for ${id}:`, error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (config) {
      fetchData();
    }
  }, [id, activePeriod, config]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderChart = () => {
    switch (id) {
      case 'hl-spot-ecosystem':
        return <HLSpotEcosystemChart data={sortedData} />;
      case 'hl-auctions':
        return <HLAuctionsChart data={sortedData} />;
      case 'hype-burn':
        return <HypeBurnDashboard data={sortedData} />;
      default:
        const chartData = prepareChartData(sortedData);
        console.log('Chart data:', chartData); // Debug log
        return <DataChart data={chartData} id={id} />;
    }
  };

  if (loading) {
    return (
      <div className="dashboard">
        <div className="loading">Loading data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard">
        <div className="error">Error: {error}</div>
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="dashboard">
        <div className="no-data">No data available</div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <h2 className="dashboard-title">{config.title}</h2>
      {id !== 'hl-auctions' && (
        <div className="period-tabs">
          {['24h', '7d', '30d', '1y'].map((period) => (
            <button
              key={period}
              onClick={() => setActivePeriod(period)}
              className={activePeriod === period ? 'active' : ''}
            >
              {period.toUpperCase()}
            </button>
          ))}
        </div>
      )}
      <div className="dashboard-content">
        {renderChart()}
        {id !== 'hl-auctions' && (
          <DataTable
            data={sortedData}
            columns={config.columns}
            requestSort={requestSort}
            sortConfig={sortConfig}
          />
        )}
      </div>
      <div className="data-source">Source: {getDataSource(id)}</div>
    </div>
  );
};

export default Dashboard;
