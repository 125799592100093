import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ReferenceLine } from 'recharts';
import '../styles/price_returns_dashboard.css';

import api_url from "../config/api_url.json";

// const base_api_url = process.env.NODE_ENV === 'development'
//   ? api_url.local_bakend_url  // Note: there's a typo in 'bakend', but keeping it consistent with your config
//   : api_url.backend_api;

const base_api_url = api_url.backend_api;

const PriceReturnsDashboard = () => {
  const [tokenData, setTokenData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTimeframe, setActiveTimeframe] = useState('24_h');
  const [sortConfig, setSortConfig] = useState({ key: 'market_cap', direction: 'desc' });
  const [groupedTokens, setGroupedTokens] = useState({});
  const [tableSortConfig, setTableSortConfig] = useState({
    sector: null,
    key: null,
    direction: 'desc'
  });
  const [summarySort, setSummarySort] = useState({
    key: null,
    direction: 'desc'
  });

  const timeframes = [
    { id: '24_h', label: '24h' },
    { id: '7_d', label: '7d' },
    { id: '30_d', label: '30d' },
    { id: '90_d', label: '90d' },
    { id: '365_d', label: '365d' }
  ];

  // Helper function to parse percentage strings
  const parsePercentage = (value) => {
    if (!value || value === 'N/A') return 0;
    if (typeof value === 'string') {
      const parsed = parseFloat(value.replace('%', ''));
      return isNaN(parsed) ? 0 : parsed;
    }
    return value;
  };

  // Modify the useEffect to organize tokens by sector
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tokenResponse, categoryResponse] = await Promise.all([
          fetch(`${base_api_url}/api/data/token-returns`),
          fetch(`${base_api_url}/api/data/category-returns`)
        ]);

        if (!tokenResponse.ok || !categoryResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const tokenData = await tokenResponse.json();
        const categoryData = await categoryResponse.json();

        // Parse all percentage values in category data
        const parsedCategoryData = categoryData.map(category => ({
          ...category,
          '24_h': parsePercentage(category['24_h']),
          '7_d': parsePercentage(category['7_d']),
          '30_d': parsePercentage(category['30_d']),
          '90_d': parsePercentage(category['90_d']),
          '365_d': parsePercentage(category['365_d'])
        }))
          .sort((a, b) => b[activeTimeframe] - a[activeTimeframe]);

        // Group tokens by sector
        const grouped = tokenData.reduce((acc, token) => {
          if (!acc[token.sector]) {
            acc[token.sector] = [];
          }
          acc[token.sector].push(token);
          return acc;
        }, {});

        setTokenData(tokenData);
        setCategoryData(parsedCategoryData);
        setGroupedTokens(grouped);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTimeframe]);

  const formatValue = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'string' && value.includes('%')) return value;
    if (typeof value === 'number') {
      if (Math.abs(value) >= 1e9) return `$${(value / 1e9).toFixed(2)}B`;
      if (Math.abs(value) >= 1e6) return `$${(value / 1e6).toFixed(2)}M`;
      if (Math.abs(value) >= 1e3) return `$${(value / 1e3).toFixed(2)}K`;
      return `$${value.toFixed(2)}`;
    }
    return value;
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const sortedTokenData = React.useMemo(() => {
    if (!tokenData.length) return [];

    return [...tokenData].sort((a, b) => {
      if (a[sortConfig.key] === null) return 1;
      if (b[sortConfig.key] === null) return -1;

      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (typeof aValue === 'string' && aValue.includes('%')) {
        aValue = parseFloat(aValue);
      }
      if (typeof bValue === 'string' && bValue.includes('%')) {
        bValue = parseFloat(bValue);
      }

      return sortConfig.direction === 'asc'
        ? aValue - bValue
        : bValue - aValue;
    });
  }, [tokenData, sortConfig]);

  const formatTooltipValue = (value) => {
    if (value === null || value === undefined || isNaN(value)) return 'N/A';
    return `${value.toFixed(2)}%`;
  };

  // Add sorting function
  const handleTableSort = (sector, key) => {
    setTableSortConfig(prevConfig => ({
      sector,
      key,
      direction:
        prevConfig.sector === sector && prevConfig.key === key && prevConfig.direction === 'asc'
          ? 'desc'
          : 'asc'
    }));
  };

  // Update the sorting function to properly handle negative numbers
  const sortNumeric = (a, b, direction) => {
    // Convert percentage strings to numbers
    const aNum = typeof a === 'string' ? parseFloat(a.replace('%', '')) : a;
    const bNum = typeof b === 'string' ? parseFloat(b.replace('%', '')) : b;

    // Handle null/undefined values
    if (aNum === null || aNum === undefined) return 1;
    if (bNum === null || bNum === undefined) return -1;
    if (isNaN(aNum)) return 1;
    if (isNaN(bNum)) return -1;

    // Direct numeric comparison (this will properly handle negatives)
    return direction === 'asc' ? aNum - bNum : bNum - aNum;
  };

  // Update getSortedTokens function
  const getSortedTokens = (tokens, sector) => {
    if (tableSortConfig.sector !== sector || !tableSortConfig.key) return tokens;

    return [...tokens].sort((a, b) => {
      let aValue = a[tableSortConfig.key];
      let bValue = b[tableSortConfig.key];

      // Handle price sorting
      if (tableSortConfig.key === 'price') {
        aValue = parseFloat(aValue.replace('$', ''));
        bValue = parseFloat(bValue.replace('$', ''));
        return tableSortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Handle percentage sorting
      return sortNumeric(aValue, bValue, tableSortConfig.direction);
    });
  };

  // Update getSortedCategoryData function
  const getSortedCategoryData = () => {
    if (!summarySort.key) {
      // Return the data sorted by the active timeframe's returns (descending)
      return [...categoryData].sort((a, b) => b[activeTimeframe] - a[activeTimeframe]);
    }

    return [...categoryData].sort((a, b) => {
      if (summarySort.key === 'sector') {
        return summarySort.direction === 'asc'
          ? a.sector.localeCompare(b.sector)
          : b.sector.localeCompare(a.sector);
      }
      return sortNumeric(a[summarySort.key], b[summarySort.key], summarySort.direction);
    });
  };

  // Add a helper function to check if a value has a percentage sign
  const hasPercentageSign = (value) => {
    if (!value) return false;
    return typeof value === 'string' && value.includes('%');
  };

  // Update the renderSummaryTable function
  const renderSummaryTable = () => {
    const sortedData = getSortedCategoryData();

    return (
      <div className="summary-table-container">
        <table className="summary-table">
          <thead>
            <tr>
              <th
                onClick={() => handleSummarySort('sector')}
                className="sortable"
              >
                Sector
                {summarySort.key === 'sector' && (
                  <span className="sort-indicator">
                    {summarySort.direction === 'desc' ? ' ↑' : ' ↓'}
                  </span>
                )}
              </th>
              {timeframes.map(({ id, label }) => (
                <th
                  key={id}
                  className={`sortable ${id === activeTimeframe ? 'active' : ''}`}
                  onClick={() => handleSummarySort(id)}
                >
                  {label}
                  {summarySort.key === id && (
                    <span className="sort-indicator">
                      {summarySort.direction === 'desc' ? ' ↑' : ' ↓'}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((category, index) => (
              <tr key={index}>
                <td>{category.sector}</td>
                {timeframes.map(({ id }) => {
                  const value = category[id];
                  // Safely format the value
                  const displayValue = value ?
                    (hasPercentageSign(value) ? value : `${value}%`) :
                    'N/A';
                  const numericValue = parseFloat(value);

                  return (
                    <td
                      key={id}
                      className={`
                        ${id === activeTimeframe ? 'active' : ''}
                        ${!isNaN(numericValue) ? (numericValue >= 0 ? 'positive' : 'negative') : ''}
                      `}
                    >
                      {displayValue}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Update the renderSectorTable function
  const renderSectorTable = (sector, tokens, categoryReturn) => {
    const sortedTokens = getSortedTokens(tokens, sector);

    return (
      <div key={sector} className="sector-table">
        <h3>{sector}</h3>
        <table className="returns-table">
          <thead>
            <tr>
              <th>Asset</th>
              <th onClick={() => handleTableSort(sector, 'price')} className="sortable">
                Price
                {tableSortConfig.sector === sector && tableSortConfig.key === 'price' && (
                  <span className="sort-indicator">
                    {tableSortConfig.direction === 'desc' ? ' ↑' : ' ↓'}
                  </span>
                )}
              </th>
              <th onClick={() => handleTableSort(sector, activeTimeframe)} className="sortable">
                Return
                {tableSortConfig.sector === sector && tableSortConfig.key === activeTimeframe && (
                  <span className="sort-indicator">
                    {tableSortConfig.direction === 'desc' ? ' ↑' : ' ↓'}
                  </span>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedTokens.map((token, index) => {
              const returnValue = token[activeTimeframe];
              // Safely format the value
              const displayValue = returnValue ?
                (hasPercentageSign(returnValue) ? returnValue : `${returnValue}%`) :
                'N/A';
              const numericValue = parseFloat(returnValue);

              return (
                <tr key={index}>
                  <td>{token.token}</td>
                  <td>{token.price}</td>
                  <td className={!isNaN(numericValue) ? (numericValue >= 0 ? 'positive' : 'negative') : ''}>
                    {displayValue}
                  </td>
                </tr>
              );
            })}
            <tr className="category-total">
              <td>Average</td>
              <td></td>
              <td className={categoryReturn >= 0 ? 'positive' : 'negative'}>
                {typeof categoryReturn === 'number' ? `${categoryReturn.toFixed(2)}%` : 'N/A'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const handleSummarySort = (key) => {
    setSummarySort(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  if (loading) return <div className="loading">Loading price returns data...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="price-returns-dashboard">
      <h2 className="dashboard-title">Sector Returns</h2>

      <div className="price-returns-timeframe-selector">
        {timeframes.map(({ id, label }) => (
          <button
            key={id}
            className={`price-returns-timeframe-button ${activeTimeframe === id ? 'price-returns-active' : ''}`}
            onClick={() => setActiveTimeframe(id)}
          >
            {label}
          </button>
        ))}
      </div>

      <div className="dashboard-layout">
        <div className="chart-section">
          <h3>Returns</h3>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={categoryData}
              layout="vertical"
              margin={{ top: 0, right: 30, left: 40, bottom: 15 }}
            >
              <CartesianGrid
                strokeDasharray="1 1"
                horizontal={true}
                vertical={true}
                stroke="#000000"
                strokeOpacity={0.9}
                strokeWidth={0.5}
              />
              <XAxis
                type="number"
                label={{
                  value: 'Returns (%)',
                  position: 'bottom',
                  offset: 0,
                  style: { fontSize: 14 }
                }}
                axisLine={{ stroke: 'black' }}
                tick={{ fontSize: 14 }}
                tickLine={{ stroke: 'black' }}
              />
              <YAxis
                dataKey="sector"
                type="category"
                width={100}
                tick={{
                  fontSize: 14,
                  width: 110,
                  textOverflow: 'ellipsis'
                }}
                axisLine={{ stroke: 'black' }}
                tickLine={{ stroke: 'black' }}
              />
              <ReferenceLine
                x={0}
                stroke="black"
                strokeWidth={1}
              />
              <Tooltip
                formatter={(value) => `${value.toFixed(2)}%`}
                labelFormatter={(label) => `${label}`}
              />
              <Bar
                dataKey={activeTimeframe}
                fill={(entry) => entry[activeTimeframe] >= 0 ? '#28a745' : '#dc3545'}
                radius={[0, 4, 4, 0]}
              >
                {categoryData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry[activeTimeframe] >= 0 ? '#4caf50' : '#dc3545'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Summary Section */}
        <div className="summary-section">
          <div className="summary-table-container">
            {renderSummaryTable()}
          </div>
        </div>
      </div>

      {/* Sector Tables - now sorted alphabetically */}
      <div className="sector-tables">
        {[...categoryData]
          .sort((a, b) => a.sector.localeCompare(b.sector))
          .map(category => (
            renderSectorTable(
              category.sector,
              groupedTokens[category.sector] || [],
              category[activeTimeframe]
            )
          ))}
      </div>
    </div>
  );
};

export default PriceReturnsDashboard;