import React, { useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'var(--app-font-family)',
        color: 'var(--text-color)',
    },
    title: {
        fontSize: '24px',
        textAlign: "center",
        fontWeight: 'normal',
        marginBottom: '10px',
        color: 'var(--text-color)'
    },
    subtitle: {
        fontSize: '14px',
        textAlign: 'center',
        color: 'var(--text-color)',
        opacity: 0.7,
        marginBottom: '20px',
        textAlign: 'center'
    },
    mainContent: {
        display: 'flex',
        gap: '20px',
        height: '500px'
    },
    chartContainer: {
        flex: 1,
        border: '1px solid var(--border-color)',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: 'white'
    },
    validatorListContainer: {
        width: '220px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid var(--border-color)',
        borderRadius: '8px',
        backgroundColor: 'white'
    },
    searchContainer: {
        padding: '12px',
        borderBottom: '1px solid var(--border-color)'
    },
    searchInput: {
        padding: '8px',
        border: '1px solid var(--border-color)',
        borderRadius: '4px',
        fontSize: '14px'
    },
    validatorList: {
        overflowY: 'auto',
        flex: 1,
        padding: '12px'
    },
    validatorItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        cursor: 'pointer',
        borderRadius: '4px',
        marginBottom: '4px',
        transition: 'background-color 0.2s'
    },
    selectedValidator: {
        backgroundColor: 'var(--primary-color)',
        color: 'white'
    },
    colorBox: {
        width: '12px',
        height: '12px',
        marginRight: '8px',
        borderRadius: '2px'
    },
    selectAllButton: {
        width: '100%',
        padding: '8px',
        marginBottom: '8px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '4px',
        fontSize: '14px',
        textAlign: 'left'
    },
    tooltipContainer: {
        backgroundColor: 'white',
        border: '1px solid var(--border-color)',
        borderRadius: '4px',
        padding: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    tooltipTitle: {
        margin: '0 0 8px 0',
        fontWeight: 'bold',
        color: 'var(--primary-color)',
        borderBottom: '1px solid var(--border-color)',
        paddingBottom: '4px'
    },
    tooltipItem: {
        margin: '4px 0',
        fontSize: '12px'
    }
};

const ValidatorStakesChart = ({ data }) => {
    const [selectedValidators, setSelectedValidators] = useState(new Set());
    const [showAllValidators, setShowAllValidators] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const COLORS = [
        '#2E8B57',  // Sea Green
        '#FF6B6B',  // Coral Red
        '#4ECDC4',  // Turquoise
        '#FFB347',  // Pastel Orange
        '#9FE2BF',  // Seafoam Green
        '#845EC2',  // Purple
        '#FF9671',  // Peach
        '#FFC75F',  // Mellow Yellow
        '#F9F871',  // Light Yellow
        '#008B8B',  // Dark Cyan
        '#FF8066',  // Salmon
        '#64C4ED',  // Sky Blue
        '#88D8B0',  // Mint
        '#C06C84'   // Mauve
    ];

    const formatValue = (value) => {
        if (typeof value !== 'number') return 'N/A';
        if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(1);
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    const processTimeSeriesData = () => {
        const allDates = new Set();
        data.validatorTimeseries.forEach(validator => {
            validator.dailyStakes.forEach(stake => {
                allDates.add(stake.date);
            });
        });

        const dateArray = Array.from(allDates).sort();
        return dateArray.map(date => {
            const dataPoint = { date };
            data.validatorTimeseries.forEach(validator => {
                const stakeData = validator.dailyStakes.find(ds => ds.date === date);
                if (stakeData) {
                    dataPoint[validator.name] = stakeData.cumulativeStake;
                }
            });
            return dataPoint;
        });
    };

    const toggleValidator = (validatorName) => {
        setSelectedValidators(prev => {
            const newSelected = new Set(prev);
            if (prev.has(validatorName)) {
                newSelected.delete(validatorName);
            } else {
                newSelected.add(validatorName);
            }
            return newSelected;
        });
        setShowAllValidators(false);
    };

    const toggleAllValidators = () => {
        setSelectedValidators(new Set());
        setShowAllValidators(true);
    };

    const timeseriesData = processTimeSeriesData();
    const validatorNames = data.validatorTimeseries.map(v => v.name);
    // Separate ASXN LABS from other validators and filter
    const filteredValidators = validatorNames
        .filter(name => name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            // Always put ASXN LABS at the top
            if (a === 'ASXN') return -1;
            if (b === 'ASXN') return 1;
            return a.localeCompare(b);
        });

    // Sort validator names by their most recent values
    const sortedValidatorNames = validatorNames.sort((a, b) => {
        const lastIndex = timeseriesData.length - 1;
        const aValue = timeseriesData[lastIndex]?.[a] || 0;
        const bValue = timeseriesData[lastIndex]?.[b] || 0;
        return bValue - aValue;  // Changed to descending order (larger values at bottom)
    });

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Validator Stakes Over Time</h2>
            <div style={styles.subtitle}>
                Click on validator names to show/hide individual data
            </div>
            <div style={styles.mainContent}>
                <div style={styles.chartContainer}>
                    <ResponsiveContainer>
                        <AreaChart data={timeseriesData}>
                            <CartesianGrid
                                strokeDasharray="3 3"
                                stroke="rgba(0,0,0,0.1)"
                                horizontal={true}
                                vertical={true}
                            />
                            <XAxis
                                dataKey="date"
                                tickFormatter={formatDate}
                                angle={-45}
                                textAnchor="end"
                                height={80}
                                tick={{ fontSize: 12 }}
                                stroke="rgba(0,0,0,0.3)"
                            />
                            <YAxis
                                tickFormatter={formatValue}
                                tick={{ fontSize: 12 }}
                                stroke="rgba(0,0,0,0.3)"
                            />
                            <Tooltip
                                content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                        return (
                                            <div style={styles.tooltipContainer}>
                                                <p style={styles.tooltipTitle}>{formatDate(label)}</p>
                                                {payload.map((entry, index) => (
                                                    <p key={index} style={{
                                                        ...styles.tooltipItem,
                                                        color: entry.color
                                                    }}>
                                                        {entry.name}: {formatValue(entry.value)} HYPE
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            {sortedValidatorNames.map((name, index) => (
                                (showAllValidators || selectedValidators.has(name)) && (
                                    <Area
                                        key={name}
                                        type="monotone"
                                        dataKey={name}
                                        stackId="1"
                                        stroke={COLORS[index % COLORS.length]}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                )
                            ))}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div style={styles.validatorListContainer}>
                    <div style={styles.searchContainer}>
                        <input
                            type="text"
                            placeholder="Enter validator name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={styles.searchInput}
                        />
                    </div>
                    <div style={styles.validatorList}>
                        <button
                            style={{
                                ...styles.selectAllButton,
                                ...(showAllValidators ? styles.selectedValidator : {})
                            }}
                            onClick={toggleAllValidators}
                        >
                            Select All
                        </button>
                        {filteredValidators.map((name, index) => (
                            <div
                                key={name}
                                onClick={() => toggleValidator(name)}
                                style={{
                                    ...styles.validatorItem,
                                    ...(selectedValidators.has(name) ? styles.selectedValidator : {})
                                }}
                            >
                                <span
                                    style={{
                                        ...styles.colorBox,
                                        backgroundColor: COLORS[index % COLORS.length]
                                    }}
                                />
                                <span>{name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ValidatorStakesChart;