import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

const Home = () => {
  const dashboardGroups = [
    {
      title: 'Market Data',
      dashboards: [
        { id: 'btc-etf-flows', name: 'BTC ETF Flows' },
        { id: 'eth-etf-flows', name: 'ETH ETF Flows' },
      ]
    },
    {
      title: 'DEXes',
      dashboards: [
        { id: 'dex-volumes', name: 'Volumes' },
        { id: 'dex-fees', name: 'Fees' },
      ]
    },
    {
      title: 'Derivatives',
      dashboards: [
        { id: 'derivatives-volumes', name: 'Volumes' },
        { id: 'derivatives-fees', name: 'Fees' },
      ]
    },
    {
      title: 'Chains and Rollups',
      dashboards: [
        { id: 'all-chains-fees', name: 'Chains and Rollups: Fees' },
      ]
    },
    {
      title: 'All Protocols',
      dashboards: [
        { id: 'all-protocols-fees', name: 'Fees' },
      ]
    },
    {
      title: 'Lending',
      dashboards: [
        { id: 'lending-fees', name: 'Fees' },
      ]
    },
    {
      title: 'Hyperliquid',
      dashboards: [
        {
          id: 'trade_on_Hyperliquid',
          name: 'Trade on Hyperliquid',
          externalLink: 'https://app.hyperliquid.xyz/join/ASXNLAB',
        },
        { id: 'hype', name: 'Hype' },
        { id: 'hype-staking', name: 'Hype Staking' },
        { id: 'hype-burn', name: 'HYPE Burn Analytics' },
        { id: 'hl-spot-ecosystem', name: 'Spot Ecosystem Overview' },
        { id: 'spot-holder-trend', name: 'Spot Token Holder Trends' },
        { id: 'hl-auctions', name: 'Auctions Data' },
        { id: 'hyperliquid-ecosystem', name: 'Ecosystem' },
        {
          id: 'hyperscreener',
          name: 'Hyperscreener',
          externalLink: 'https://hyperliquid.asxn.xyz/'
        },
        {
          id: 'hl-validators',
          name: 'Hyperliquid Validators',
          externalLink: 'https://hyperliquid.asxn.xyz/staking'
        }
      ]
    },
    {
      title: 'Monad',
      dashboards: [
        { id: 'monad-ecosystem', name: 'Ecosystem' },
      ]
    },
    // {
    //   title: 'stHype',
    //   dashboards: [
    //     { id: 'stHype-overview', name: 'stHype Overview' },
    //     { id: 'stHype-staking', name: 'stHype Staking' },
    //   ]
    // }
  ];

  return (
    <div className="home">
      <main className="main-content">
        <Link to="/" className="main-title-link">
          <h2 className="main-title">Dashboards</h2>
        </Link>
        <div className="dashboard-groups">
          {dashboardGroups.map((group, index) => (
            <div key={index} className="dashboard-group">
              <h3 className="group-title">{group.title}</h3>
              <div className="dashboard-links">
                {group.dashboards.map((dashboard) => (
                  dashboard.externalLink ? (
                    <a
                      key={dashboard.id}
                      href={dashboard.externalLink}
                      className="dashboard-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dashboard.name}
                    </a>
                  ) : (
                    <Link
                      key={dashboard.id}
                      to={`/dashboard/${dashboard.id}`}
                      className="dashboard-link"
                    >
                      {dashboard.name}
                    </Link>
                  )
                ))}
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Home;
