import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';

const TwapProgressionChart = ({ twapData }) => {
    const [progressData, setProgressData] = useState([]);
    const [currentPoint, setCurrentPoint] = useState(null);

    useEffect(() => {
        if (!twapData) return;

        const generateChartData = () => {
            // Parse the start time
            const [datePart, timePart] = twapData.time.split(', ');
            const [day, month, year] = datePart.split('/');
            const [hours, minutes, seconds] = timePart.split(':');
            const startTime = new Date(year, month - 1, day, hours, minutes, seconds).getTime();

            const totalMinutes = twapData.minutes;
            const endTime = startTime + (totalMinutes * 60 * 1000);
            const now = Date.now();
            const totalAmount = parseFloat(twapData.amount);
            const currentProgress = parseFloat(twapData.progress) / 100;

            // Calculate fill rate
            const fillRate = totalAmount / (totalMinutes * 60 * 1000);

            const dataPoints = [];
            let currentTime = startTime;

            while (currentTime <= endTime) {
                const elapsedMs = currentTime - startTime;
                const targetAmount = Math.min(fillRate * elapsedMs, totalAmount);

                // Calculate filled amount based on current progress
                const filledAmount = currentTime <= now ?
                    Math.min(fillRate * elapsedMs, totalAmount * currentProgress) :
                    null;

                dataPoints.push({
                    time: new Date(currentTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    timestamp: currentTime,
                    target: targetAmount,
                    filled: filledAmount
                });

                // 5-minute intervals
                currentTime += 5 * 60 * 1000;
            }

            // Set current point
            const currentExpected = Math.min(
                fillRate * (now - startTime),
                totalAmount
            );

            setCurrentPoint({
                time: new Date(now).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                }),
                target: currentExpected,
                filled: currentExpected * currentProgress
            });

            return dataPoints;
        };

        setProgressData(generateChartData());

        const interval = setInterval(() => {
            setProgressData(generateChartData());
        }, 60000);

        return () => clearInterval(interval);
    }, [twapData]);

    const formatValue = (value) => {
        if (value === null || value === undefined) return '';
        if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
        return value.toFixed(1);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (!active || !payload || !payload.length) return null;

        return (
            <div className="bg-white p-3 border border-gray-200 rounded-md shadow-sm">
                <p className="text-sm font-medium">{label}</p>
                {payload.map((entry) => (
                    entry.value !== null && (
                        <p
                            key={entry.dataKey}
                            className="text-sm"
                            style={{ color: entry.color }}
                        >
                            {entry.dataKey === 'filled' ? 'Filled: ' : 'Target: '}
                            {formatValue(entry.value)}
                        </p>
                    )
                ))}
            </div>
        );
    };

    return (
        <div className="w-full bg-white rounded-lg p-4 shadow-sm">
            <h3 className="text-center mb-4 font-medium"
                style={{
                    color: 'var(--primary-color)',
                    fontFamily: 'var(--app-font-family)'
                }}>
                TWAP Progression
            </h3>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                    data={progressData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                    <defs>
                        <linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--primary-color)" stopOpacity={0.3} />
                            <stop offset="95%" stopColor="var(--primary-color)" stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                    <XAxis
                        dataKey="time"
                        tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                        interval="preserveStart"
                    />
                    <YAxis
                        tickFormatter={formatValue}
                        tick={{ fill: 'var(--text-color)', fontSize: 12 }}
                        domain={[0, dataMax => Math.ceil(dataMax * 1.1)]}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="filled"
                        stroke="var(--primary-color)"
                        fill="url(#fillGradient)"
                        isAnimationActive={false}
                        strokeWidth={2}
                    />
                    <Area
                        type="monotone"
                        dataKey="target"
                        stroke="var(--primary-color)"
                        strokeDasharray="5 5"
                        fill="none"
                        isAnimationActive={false}
                    />
                    {currentPoint && (
                        <ReferenceLine
                            x={currentPoint.time}
                            stroke="var(--primary-color)"
                            label={{
                                position: 'top',
                                value: `${currentPoint.time}\nTarget: ${formatValue(currentPoint.target)}\nFilled: ${formatValue(currentPoint.filled)}`,
                                fill: 'var(--primary-color)',
                                fontSize: 12
                            }}
                        />
                    )}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TwapProgressionChart;